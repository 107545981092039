import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription, of, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout } from '../../../../core/auth';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { each, find, some } from 'lodash';

import { environment } from '../../../../../environments/environment';
import { Motor_OR_Upload_Mdl, selectMotorMasterActionLoading, selectAllInsurerMotor, selectAllInsurance, Motor_OR_Master } from '../../../../core/motor';
import { MotorMasterActionToggleLoading } from '../../../../core/motor/_actions/motor-master.actions';
import { MotorORUploadMdl } from '../../../../core/motor/_models/motor-upload.model';
import { MotorUpdateService } from '../../../../core/motor/_services/motor-update.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
	selector: 'kt-motor-OR-upload-edit',
	templateUrl: './motor-OR-upload-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class MotorORUploadEditComponent implements OnInit, OnDestroy {
	Is_New: any = 1;
	Edit_Row_No: any = 0;
	@ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
	Achivement: any = [];
	fileInputLabel: string;
	displayedColumns = ['actions', 'OR_Month', 'OR_Year', 'CreatedDate', 'File_Location'];
	dataSource: MatTableDataSource<Motor_OR_Upload_Mdl>;
	_rewards_Data: Motor_OR_Upload_Mdl[] = [];
	Removed_achivement_Data: Motor_OR_Upload_Mdl[] = [];
	MotorORUploadMdl: MotorORUploadMdl;
	public insurerCtrl: FormControl = new FormControl();
	public testControl: FormControl = new FormControl();
	public insurerFilterCtrl: FormControl = new FormControl();
	public filteredInsurers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	private _onDestroy = new Subject<void>();
	hasExists = false;
	User_FG: FormGroup;
	showActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: false });
	_NewRewards: number;
	Insurer_Id: number;
	AllInsurerMotor: Motor_OR_Master[] = [];
	User_Id: string;
	hasFormErrors = false;
	viewLoading = false;
	private componentSubscriptions: Subscription;
	IsReadOnly: boolean;

	OR_Month = [
		{ id: 1, value: 'January' },
		{ id: 2, value: 'February' },
		{ id: 3, value: 'March' },
		{ id: 4, value: 'April' },
		{ id: 5, value: 'May' },
		{ id: 6, value: 'June' },
		{ id: 7, value: 'July' },
		{ id: 8, value: 'August' },
		{ id: 9, value: 'September' },
		{ id: 10, value: 'October' },
		{ id: 11, value: 'November' },
		{ id: 12, value: 'December' }
	];

	Edit_Reward: boolean = false;
	DataSourceIndex: any;

	constructor(public dialogRef: MatDialogRef<MotorORUploadEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private http: HttpClient,
		private fb: FormBuilder,
		private User_FB: FormBuilder,
		private translate: TranslateService,
		private SrvMaster: MotorUpdateService,
		public sanitizer: DomSanitizer,
		private router: Router,
		private Srv_Master: MotorUpdateService,
		private store: Store<AppState>,
		public dialog: MatDialog,
		private typesUtilsService: TypesUtilsService,
		private spinner: NgxSpinnerService) {
		this.MotorORUploadMdl = new MotorORUploadMdl();
		this.Insurer_Id = data.Insurer_Id;
		this.IsReadOnly = data.IsReadOnly;
		this.store.pipe(select(selectAllInsurerMotor)).subscribe(res => this.AllInsurerMotor = res);
		this.insurerCtrl.setValue(this.AllInsurerMotor);
		this.testControl.setValue(0);

		this.filteredInsurers.next(this.AllInsurerMotor.slice(0, 500));
		this.insurerFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterInsurers();
			});
	}

	ngOnInit() {
		
		this.Create_Form_Validation();
		this.dataSource = new MatTableDataSource();
		// this.store.pipe(select(selectMotorMasterActionLoading)).subscribe(res => this.viewLoading = res);
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		if (this.Insurer_Id > 0) {
			this.spinner.show();
			this.Srv_Master.Get_Edit_Data(this.Insurer_Id, this.User_Id).subscribe(result => {
				if (result.length > 0) {
					this._rewards_Data = result;
					this._rewards_Data.forEach(element => {
						// element.File_Location=this.sanitizer.bypassSecurityTrustResourceUrl(element.File_Location);
						element.File_Location = element.File_Location;
					});
					this.insurerCtrl.setValue(this._rewards_Data[0].Insurer_Id);
					this.testControl.setValue(0);
					each(result, (_rewardsData: Motor_OR_Upload_Mdl) => {
						this.dataSource = new MatTableDataSource(this._rewards_Data);
					});
				}
				this.spinner.hide();
			});
		}
		if (this.Insurer_Id == 0) {
			this.New_Reward();
		}
		
	}
	Cancel_Condition() {
		this._NewRewards = undefined;
	}
	New_Reward() {
		this.Is_New = 1;
		this.Edit_Reward = false;
		this.DataSourceIndex = null;
		this.User_FG.controls['OR_Month_Id'].setValue(0);
		this.User_FG.controls['OR_Year'].setValue('');
		this._NewRewards = 1;
	}


	private filterInsurers() {
		if (!this.AllInsurerMotor) {
			return;
		}
		let search = this.insurerFilterCtrl.value;
		if (!search) {
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredInsurers.next(
			this.AllInsurerMotor.filter(insurer => insurer.Insurer.toLowerCase().indexOf(search) > -1).slice(0, 500)
		);
	}
	// applyFilter(filterValue: string) {
	// 	filterValue = filterValue.trim(); // Remove whitespace
	// 	filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
	// }

	Create_Form_Validation() {
		this.User_FG = this.User_FB.group({
			OR_Month_Id: [null, Validators.required],
			// OR_Year: [null, Validators.required],
			OR_Year: [null, Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(4) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])],
			myfile: ['']
		});
	}
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	getTitle(): string {
		// if (this._rewards_Data.CRaw_Version > 0) {
		// 	if (this.IsReadOnly == true) {
		// 		return `View Master '${this._rewards_Data.Insurer}'`;
		// 	}
		// 	else if (this.IsReadOnly == false) {
		// 		return `Edit Master '${this._rewards_Data.Insurer}'`;
		// 	}
		// }
		return 'Upload OR';
	}
	getWaitDesciption() {
		// if (this._rewards_Data.CRaw_Version > 0) {
		// 	return this.translate.instant('COMMON.COMMON.UPDATE_RECORD.WAIT_DESCRIPTION', { name: 'Master' });
		// }
		this.spinner.hide();
		return this.translate.instant('COMMON.COMMON.NEW_RECORD.WAIT_DESCRIPTION', { name: 'Master' });
	}
	prepareMaster(element): Motor_OR_Upload_Mdl {
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		const _Master = new Motor_OR_Upload_Mdl();
		_Master.id = element.id;
		_Master.Insurer_Id = element.Insurer_Id;
		_Master.Insurer = element.Insurer;
		_Master.OR_Month_Id = element.OR_Month_Id;
		_Master.OR_Month = element.OR_Month;
		_Master.OR_Year = element.OR_Year;
		_Master.status = element.status;
		_Master.User_Id = this.User_Id;
		_Master.CRaw_Version = element.CRaw_Version;
		this.spinner.hide();
		return _Master;
	}
	onSubmit() {
		
		this.store.dispatch(this.showActionLoadingDistpatcher);

		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id

		this.MotorORUploadMdl.User_Id = this.User_Id;
		if (this.Insurer_Id == 0) {
			this.MotorORUploadMdl.Insurer_Id = this._rewards_Data[0].Insurer_Id;
		}
		else {
			this.MotorORUploadMdl.Insurer_Id = this.Insurer_Id;
		}
		this.MotorORUploadMdl.Master_Data = this._rewards_Data;
		this.MotorORUploadMdl.Removed_achivement_Data = this.Removed_achivement_Data;
		this.spinner.show();
		this.SrvMaster.Save_Motor_Upload_Data(this.MotorORUploadMdl).subscribe(res => {
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		return this.hideActionLoadingDistpatcher;
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
		this.hasExists = false;
	}

	EditReward(Sel_rewards_Data: Motor_OR_Upload_Mdl, index) {
		
		this.Edit_Row_No = index;
		this.Is_New = 0;
		this.Edit_Reward = true;
		this.DataSourceIndex = index;
		this.User_FG.controls['OR_Month_Id'].setValue(Sel_rewards_Data.OR_Month_Id);
		this.User_FG.controls['OR_Year'].setValue(Sel_rewards_Data.OR_Year);
		this._NewRewards = 1;
		
	}

	unassing(Sel_rewards_Data: Motor_OR_Upload_Mdl, index) {
		
		this.Achivement = [];

		for (let i = 0; i < this._rewards_Data.length; i++) {
			if (index == i) {
				this.Achivement = {
					id: this._rewards_Data[index]["id"],
					CRaw_Version: this._rewards_Data[index]["CRaw_Version"]
				};
				this._rewards_Data.splice(index, 1);
			}
		}
		this.Removed_achivement_Data.push(this.Achivement as any);
		this.dataSource = new MatTableDataSource(this._rewards_Data);
		
	}

	on_Capping_Change(_Capping_Id) {
		if (_Capping_Id.value == 0) {
			this.User_FG.controls['OR_Year'].setValue('');
		}
	}

	onFileSelect(event) {
		
		let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			// console.log(file);

			if (!_.includes(af, file.type)) {
				alert('Only EXCEL Docs Allowed!');
			} else {
				this.fileInputLabel = file.name;
				this.User_FG.get('myfile').setValue(file);
			}
		}
		
	}

	Create_Condition() {
		
		this.hasFormErrors = false;
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id

		const User_Controls = this.User_FG.controls;
		if (this.User_FG.invalid) {
			Object.keys(User_Controls).forEach(controlName =>
				User_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		if (!this.User_FG.get('myfile').value) {
			alert('Please fill valid details!');
			return false;
		}

		const formData = new FormData();
		formData.append('formFile', this.User_FG.get('myfile').value);
		var File_Name = Math.random().toString(36).substring(7) + new Date().getTime();
		this.spinner.show();
		this.Srv_Master.Save_Temp_Excel(formData, File_Name).subscribe(response => {
			console.log(response);
			if (response.docfiles[0] != '') {
				this.fileInputLabel = undefined;
				const controls = this.User_FG.controls;
				const Temp_OR_Month = this.OR_Month.filter(
					(thing, i, arr) => arr.findIndex(t => t.id == controls.OR_Month_Id.value) == i
				);
				this.Insurer_Id = this.insurerCtrl.value;

				this.Achivement = {
					id: 0,
					Insurer_Id: this.insurerCtrl.value,
					OR_Month_Id: Temp_OR_Month[0].id,
					OR_Month: Temp_OR_Month[0].value,
					OR_Year: controls.OR_Year.value,
					File_Location: response.docfiles[0],
					CreatedDate: response.serverTime,
					User_Id: this.User_Id,
					Is_Deleted: true
				};
				var IsExists = 0;
				if (this.Is_New == 0) {
					for (let i = 0; i < this._rewards_Data.length; i++) {
						if (this.Edit_Row_No != i && this._rewards_Data[i]["Insurer_Id"] == this.Achivement["Insurer_Id"] && this._rewards_Data[i]["OR_Month_Id"] == this.Achivement["OR_Month_Id"] && this._rewards_Data[i]["OR_Year"] == this.Achivement["OR_Year"]) {
							IsExists = 1;
							this.hasExists = true;
							this.spinner.hide();
							return;
						}
					}
				}
				else if (this.Is_New == 1) {
					for (let i = 0; i < this._rewards_Data.length; i++) {
						if (this._rewards_Data[i]["Insurer_Id"] == this.Achivement["Insurer_Id"] && this._rewards_Data[i]["OR_Month_Id"] == this.Achivement["OR_Month_Id"] && this._rewards_Data[i]["OR_Year"] == this.Achivement["OR_Year"]) {
							IsExists = 1;
							this.hasExists = true;
							this.spinner.hide();
							return;
						}
					}
				}

				if (IsExists == 1) {
					this.hasExists = true;
					this.spinner.hide();
					return false;
				}
				if (this.hasExists == false && this.Edit_Reward == false) {
					this._rewards_Data.push(this.Achivement as any);

				}
				else if (this.hasExists == false) {
					this._rewards_Data[this.DataSourceIndex]["OR_Month_Id"] = this.Achivement["OR_Month_Id"];
					this._rewards_Data[this.DataSourceIndex]["OR_Month"] = this.Achivement["OR_Month"];
					this._rewards_Data[this.DataSourceIndex]["OR_Year"] = this.Achivement["OR_Year"];
					this._rewards_Data[this.DataSourceIndex]["File_Location"] = this.Achivement["File_Location"];
					this._rewards_Data[this.DataSourceIndex]["CreatedDate"] = this.Achivement["CreatedDate"];
				}
				this.dataSource = new MatTableDataSource(this._rewards_Data);
				this._NewRewards = undefined;
			}
			this.spinner.hide();
		});
		

	}
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.User_FG.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
