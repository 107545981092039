// NGRX
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
// Actions
import { UserPermissionActionTypes, UserPermissionActions } from '../_actions/user_permission.actions';
// Models
import { UserPermissionModel } from '../_models/user_permission.model';

export interface PermissionsState extends EntityState<UserPermissionModel> {
    _isAllPermissionsLoaded: boolean;
}

export const adapter: EntityAdapter<UserPermissionModel> = createEntityAdapter<UserPermissionModel>();

export const initialPermissionsState: PermissionsState = adapter.getInitialState({
    _isAllPermissionsLoaded: false
});

export function UserPermissionsReducer(state = initialPermissionsState, action: UserPermissionActions): PermissionsState {
    switch  (action.type) {
        case UserPermissionActionTypes.AllUserPermissionsRequested:
            return {...state,
                _isAllPermissionsLoaded: false
        };
        case UserPermissionActionTypes.AllUserPermissionsLoaded:
            return adapter.addAll(action.payload.UserPermission, {
                ...state,
                _isAllPermissionsLoaded: true
            });
        default:
            return state;
    }
}

export const getRoleState = createFeatureSelector<PermissionsState>('UserPermission');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
