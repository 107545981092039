import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, QueryParamsModel } from '../../../../core/_base/crud';
import { AuthService } from '../../../../core/auth';
import { Sme_OR_Upload_Mdl, SmeMasterDataSource, SmeMasterPageRequested } from '../../../../core/sme';
import { SmeORUploadEditComponent } from '../sme-OR-upload-edit/sme-OR-upload-edit.component';
import { SmeUpdateService } from '../../../../core/sme/_services/sme-update.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'kt-sme-OR-upload-list',
  templateUrl: './sme-OR-upload-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmeORUploadListComponent implements OnInit, OnDestroy, AfterViewInit {
  employeeInfoTable: Object[] = [];
  employeeInfoTableDataSource = new MatTableDataSource(this.employeeInfoTable);

  // dataSource: SmeMasterDataSource;
  displayedColumns = ['select', 'actions', 'Insurer'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  selection = new SelectionModel<Sme_OR_Upload_Mdl>(true, []);
  ActiveSelection = '';
  InActiveSelection = '';
  MasterResult: Sme_OR_Upload_Mdl[] = [];
  private subscriptions: Subscription[] = [];
  componentSubscriptions: Subscription;

  constructor(
    public dialog: MatDialog,
    private Srv_Master: SmeUpdateService,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private Svc_Auth: AuthService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService
  ) { }

  ngAfterViewInit() {
    this.employeeInfoTableDataSource.sort = this.sort;
    this.employeeInfoTableDataSource.paginator = this.paginator;
  }
  ngOnInit() {
    
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      // tap(() => this.loadMasterList())
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(50),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.filterConfiguration();
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    // this.dataSource = new SmeMasterDataSource(this.store);
    // const entitiesSubscription = this.dataSource.entitySubject.pipe(
    //   skip(1),
    //   distinctUntilChanged()
    // ).subscribe(res => {
    //   this.MasterResult = res;
    // });
    // this.subscriptions.push(entitiesSubscription);
    
    this.loadMasterList();
    
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  loadMasterList() {
    
    // this.selection.clear();
    // const queryParams = new QueryParamsModel(
    //   this.filterConfiguration(),
    //   this.sort.direction,
    //   this.sort.active,
    //   this.paginator.pageIndex,
    //   this.paginator.pageSize
    // );
    this.spinner.show();
    this.Srv_Master.Get_Sme_Upload_Grid_List().subscribe(response => {
      
      this.employeeInfoTable = response;
      this.employeeInfoTableDataSource.data = this.employeeInfoTable;
      this.MasterResult = response;
      this.spinner.hide();
    });
    
    // this.store.dispatch(new SmeMasterPageRequested({ page: queryParams }));
    this.selection.clear();
    
  }

  filterConfiguration(): any {
    this.employeeInfoTableDataSource.filter = this.searchInput.nativeElement.value;

    // const filter: any = {};
    // const searchText: string = this.searchInput.nativeElement.value;

    // if (this.filterStatus && this.filterStatus.length > 0) {
    //   filter.status = +this.filterStatus;
    // }
    // filter.Insurer = searchText;
    // return filter;
  }

  Add_Edit_Master() {
    
    const newMaster = new Sme_OR_Upload_Mdl();
    newMaster.clear();
    this.AddEditMaster(0, false);
    
  }

  AddEditMaster(Insurer_Id: number, IsReadOnly) {
    
    let saveMessageTranslateParam = 'COMMON.COMMON.';
    let notsaveMessageTranslateParam = 'COMMON.COMMON.';
    saveMessageTranslateParam += Insurer_Id > 0 ? 'UPDATE_RECORD.SUCCESS_MESSAGE' : 'NEW_RECORD.SUCCESS_MESSAGE';
    notsaveMessageTranslateParam += Insurer_Id > 0 ? 'UPDATE_RECORD.NOT_SAVED_MESSAGE' : 'NEW_RECORD.NOT_SAVED_MESSAGE';
    const _saveMessage = this.translate.instant(saveMessageTranslateParam.toString(), { name: 'Master' });
    const _NotsaveMessage = this.translate.instant(notsaveMessageTranslateParam.toString(), { name: 'Master' });
    const dialogRef = this.dialog.open(SmeORUploadEditComponent, { data: { Insurer_Id, IsReadOnly: IsReadOnly } });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(res => {
      if (res > 0) {
        this.layoutUtilsService.showActionNotification("success", _saveMessage);
        this.loadMasterList();
      }
      else if (res == -1) {
        this.layoutUtilsService.showActionNotification("warn", "Few records found duplicate hence it was not save");
        this.loadMasterList();
      }
      else if (res == 0) {
        this.layoutUtilsService.showActionNotification("warn", _NotsaveMessage);
        this.loadMasterList();
      }
      else if (res == -9) {
        this.layoutUtilsService.showActionNotification("primary", this.translate.instant('COMMON.COMMON.UPDATE_RECORD.USER_SAVED_MESSAGE', { name: 'Master' }));
        this.loadMasterList();
      }
      else if (res == -10) {
        this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
        this.loadMasterList();
      }
      else
        return;
    });
    
  }

  Update_Status_Single(_Uploaded_Insurer: Sme_OR_Upload_Mdl, _Action_For: string) {
    
    var _title: string;
    var _description: string;
    var _waitDesciption: string;
    var _SuccessMessage: string;
    var _Not_Saved_Message: string;
    var _User_Saved_Message: string;
    var _Btn_Color: string;

    if (_Action_For == 'Active') {
      _Btn_Color = 'success';
      _title = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.TITLE', { name: 'Uploaded Excel' });
      _description = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.DESCRIPTION', { name: 'Uploaded Excels' });
      _waitDesciption = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Uploaded Excel' });
      _SuccessMessage = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Uploaded Excel' });
      _Not_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Uploaded Excel' });
      _User_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Uploaded Excel' });
    }
    else if (_Action_For == 'InActive') {
      _Btn_Color = 'warn';
      _title = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.TITLE', { name: 'Uploaded Excel' });
      _description = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.DESCRIPTION', { name: 'Uploaded Excels' });
      _waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Uploaded Excel' });
      _SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Uploaded Excel' });
      _Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Uploaded Excel' });
      _User_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Uploaded Excel' });
    }
    const dialogRef = this.layoutUtilsService.SmeUpdateActiveInActiveSingleElement(_title, _description, _waitDesciption, _Uploaded_Insurer, 'Uploaded Excel', _Action_For, _Btn_Color);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(res => {
      if (res > 0) {
        this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
        this.loadMasterList();
      }
      else if (res == 0) {
        this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
        this.loadMasterList();
        return;
      }
      else if (res == -9) {
        this.layoutUtilsService.showActionNotification("primary", _User_Saved_Message);
        return;
      }
      else if (res == -10) {
        this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
        return;
      }
      else
        return;
    });
    
  }

  Update_Status_Multy(_Action_For: string) {
    
    var _title: string;
    var _waitDesciption: string;
    var _SuccessMessage: string;
    var _Not_Saved_Message: string;
    var _Not_All_Saved_Message: string;
    var _Btn_Color: string;
    const _statuses = [{ value: 1, text: 'Active' }, { value: 0, text: 'InActive' }];
    const _messages = [];

    this.selection.selected.forEach(elem => {

      _messages.push({
        id: elem.id.toString(),
        text: `${elem.Insurer}`,
        statusTitle: this.getItemStatusString(elem.status),
        statusCssClass: this.getItemCssClassByStatus(elem.status)
      });

    });


    _Btn_Color = 'warn';
    _title = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.TITLE', { name: 'Uploaded Excels' });
    _SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.SUCCESS_MESSAGE', { name: 'Uploaded Excels' });
    _Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_SAVED_MESSAGE', { name: 'Uploaded Excels' });
    _Not_All_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_ALL_SAVED_MESSAGE', { name: 'Uploaded Excels' });
    _waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.WAIT_DESCRIPTION', { name: 'Uploaded Excels' });

    const dialogRef = this.layoutUtilsService.SmeUpdateActiveInActiveMultyElement(_title, _waitDesciption, _statuses, _messages, 'Uploaded Excel', _Action_For, _Btn_Color);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res.Rtn_Result == 0) {
          this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
          this.loadMasterList();
          return;
        }
        else if (res.Rtn_Result == -10) {
          this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
          return;
        }
        else if (res.Rtn_Result == res.Selected_Row) {
          this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
          this.selection.clear();
          this.loadMasterList();
        }
        else if (res.Rtn_Result != res.Selected_Row) {
          this.layoutUtilsService.showActionNotification("primary", _Not_All_Saved_Message.replace('{{number}}', res.Rtn_Result));
          this.loadMasterList();
        }
      }
      else
        return;
    });
    
  }

  isAllSelected(): boolean {
    this.ActiveSelection = '';
    this.InActiveSelection = '';


    const numSelected = this.selection.selected.length;
    const numRows = this.MasterResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.ActiveSelection = '';
    this.InActiveSelection = '';
    if (this.selection.selected.length === this.MasterResult.length) {
      this.selection.clear();
    } else {
      this.MasterResult.forEach(row => this.selection.select(row));
    }
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'InActive';
      case 1:
        return 'Active';
    }
    return '';
  }

  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 0:
        return 'danger';
      case 1:
        return 'success';
    }
    return '';
  }
}
