// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { HealthMasterActions, HealthMasterActionTypes } from '../_actions/health-master.actions';
// Models
import { Health_OR_Master } from '../_models/health-master.model';
import { QueryParamsModel } from '../../_base/crud';

export interface HealthMasterState extends EntityState<Health_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHealthMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface InsurerState extends EntityState<Health_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHealthMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface Policy_TypeState extends EntityState<Health_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHealthMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Health_OR_Master> = createEntityAdapter<Health_OR_Master>();

export const initialHealthMasterState: HealthMasterState = adapter.getInitialState({
    _HealthMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHealthMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialInsurerState: InsurerState = adapter.getInitialState({
    _HealthMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHealthMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialPolicy_TypeState: Policy_TypeState = adapter.getInitialState({
    _HealthMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHealthMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function AllInsurerReducerHealth(state = initialInsurerState, action: HealthMasterActions): HealthMasterState {
    switch  (action.type) {
        case HealthMasterActionTypes.AllInsurerRequested:
            return {...state,
        };
        case HealthMasterActionTypes.AllInsurerLoaded:
            return adapter.addAll(action.payload._HealthMaster, {
                ...state,
            });
        default: return state;
    }
}

export function AllPolicy_TypeReducerHealth(state = initialPolicy_TypeState, action: HealthMasterActions): HealthMasterState {
    switch  (action.type) {
        case HealthMasterActionTypes.AllPolicy_TypeRequested:
            return {...state,
        };
        case HealthMasterActionTypes.AllPolicy_TypeLoaded:
            return adapter.addAll(action.payload._HealthMaster, {
                ...state,
            });
        default: return state;
    }
}

export function _HealthMasterReducer(state = initialHealthMasterState, action: HealthMasterActions): HealthMasterState {
    switch  (action.type) {
        case HealthMasterActionTypes.HealthMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedHealthMasterId: undefined
            };
        }
        case HealthMasterActionTypes.HealthMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        
        case HealthMasterActionTypes.HealthMasterPageLoaded: {
            return adapter.addMany(action.payload._HealthMaster, {
                ...initialHealthMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        default: return state;
    }
}

// export const getHealthMasterState = createFeatureSelector<Health_OR_Master>('_HealthMaster');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
