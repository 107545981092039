import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, skip, delay, take, takeUntil } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of, ReplaySubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Health_OR_Master, selectAllPolicy_Type, selectAllInsurerHealth } from '../../../../core/health';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HealthUpdateService } from '../../../../core/health/_services/health-update.service';
import { HealthReceivedService } from '../../../../core/health/_services/health-received.service';
import { each, remove } from 'lodash';
import { Health_OR_Excel_Heading, Health_Import_OR } from '../../../../core/health/_models/health-import.model';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { environment } from '../../../../../environments/environment';
import { Logout } from '../../../../core/auth';
import * as xlsx from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'health-OR-import',
  templateUrl: './health-OR-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthORImportComponent implements OnInit, OnDestroy, AfterViewInit {
  Is_Excel = false;
  Is_Excel_Sheet = false;
  Is_Filter = false;
  Payout_Month: any;
  Payout_Year: any;
  User_Id: string;
  selection = new SelectionModel(true, []);
  GV_Matched_Exl: Object[] = [];
  GV_Policy_Matched_Data = new MatTableDataSource(this.GV_Matched_Exl);

  Net_Amt_Not_Matched_Exl: Object[] = [];
  GV_Net_Amt_Not_Matched_Data = new MatTableDataSource(this.Net_Amt_Not_Matched_Exl);

  Payout_Already_Received: Object[] = [];
  Payout_Already_Received_Data = new MatTableDataSource(this.Payout_Already_Received);

  Policy_Not_Found: Object[] = [];
  Policy_Not_Found_Data = new MatTableDataSource(this.Policy_Not_Found);

  AllInsurerHealth: Health_OR_Master[] = [];
  Health_Import_OR: Health_Import_OR;
  displayedColumns = ['Heading', 'Is_Required_Field', 'Is_Required_Id', 'Is_Duplicate', 'Excel_Heading'];
  displayedColumns_not_matched: any;
  AllPolicy_Type_Health: Health_OR_Master[] = [];
  GV_Matched_Data_Displayed_Columns = ['select', 'Full_Customer_Name', 'Policy_Number', 'INH_Policy_Type', 'INS_Policy_Type', 'Achivement_For', 'Policy_Type', 'PremiumDiscount', 'Pay_Premium', 'Pay_Agent_Comm_Per', 'Pay_Agent_Comm', 'Pay_TDS', 'Pay_NetAmt', 'Excel_Net_Amt', 'Cust_Status'];
  GV_Amt_Not_Matched_Displayed_Columns = ['Full_Customer_Name', 'Policy_Number', 'INH_Policy_Type', 'INS_Policy_Type', 'Achivement_For', 'Policy_Type', 'PremiumDiscount', 'Pay_Premium', 'Pay_Agent_Comm_Per', 'Pay_Agent_Comm', 'Pay_TDS', 'Pay_NetAmt', 'Excel_Net_Amt', 'Cust_Status'];
  Payout_Already_Displayed_Columns = ['Full_Customer_Name', 'Policy_Number', 'INH_Policy_Type', 'INS_Policy_Type', 'Achivement_For', 'Policy_Type', 'PremiumDiscount', 'Pay_Premium', 'Pay_Agent_Comm_Per', 'Pay_Agent_Comm', 'Pay_TDS', 'Pay_NetAmt', 'Excel_Net_Amt', 'Cust_Status'];
  GV_Already_Received_Displayed_Columns = ['Full_Customer_Name', 'Policy_Number', 'Pay_NetAmt', 'Net_Received_Amount', 'Net_Pending_Amt'];


  User_FG: FormGroup;
  User_FGA: FormGroup;
  private _onDestroy = new Subject<void>();
  public insurerCtrl: FormControl = new FormControl();
  public testControl: FormControl = new FormControl();
  public insurerFilterCtrl: FormControl = new FormControl();
  public filteredInsurers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  employeeInfoTable: Object[] = [];
  Heading_Table = [];
  Exl_Heading_Table = new MatTableDataSource(this.employeeInfoTable);
  @ViewChild('Matched_paginator', { static: false }) Matched_paginator: MatPaginator;
  @ViewChild('Matched_sort', { static: false }) Matched_sort: MatSort;

  @ViewChild('Not_Matched_paginator', { static: false }) Not_Matched_paginator: MatPaginator;
  @ViewChild('Not_Matched_sort', { static: false }) Not_Matched_sort: MatSort;

  @ViewChild('Received_paginator', { static: false }) Received_paginator: MatPaginator;
  @ViewChild('Received_sort', { static: false }) Received_sort: MatSort;

  @ViewChild('Not_Found_paginator', { static: false }) Not_Found_paginator: MatPaginator;
  @ViewChild('Not_Found_sort', { static: false }) Not_Found_sort: MatSort;


  private subscriptions: Subscription[] = [];
  OR_Excel = [];
  OR_Selected_Excel_Heading = [];
  Health_OR_Excel_Heading = [];
  OR_Excel_Sheet = [];
  OR_Month = [
    { id: 1, value: 'January' },
    { id: 2, value: 'February' },
    { id: 3, value: 'March' },
    { id: 4, value: 'April' },
    { id: 5, value: 'May' },
    { id: 6, value: 'June' },
    { id: 7, value: 'July' },
    { id: 8, value: 'August' },
    { id: 9, value: 'September' },
    { id: 10, value: 'October' },
    { id: 11, value: 'November' },
    { id: 12, value: 'December' }
  ];
  Achivement_For = [{ id: 1, value: 'Regular' },
  { id: 2, value: 'Rewards' }];
  Conditions = [
    { id: 1, value: 'Premium' }
  ];
  constructor(
    private User_FB: FormBuilder,
    private Srv_Master: HealthUpdateService,
    private Srv_Received: HealthReceivedService,
    private store: Store<AppState>,
    private typesUtilsService: TypesUtilsService,
    private spinner: NgxSpinnerService
  ) {
    this.store.pipe(select(selectAllPolicy_Type)).subscribe(res => this.AllPolicy_Type_Health = res);
    this.Health_Import_OR = new Health_Import_OR();
    this.store.pipe(select(selectAllInsurerHealth)).subscribe(res => this.AllInsurerHealth = res);
    this.insurerCtrl.setValue(this.AllInsurerHealth);
    this.testControl.setValue(0);
    this.filteredInsurers.next(this.AllInsurerHealth.slice(0, 500));
    this.insurerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterInsurers();
      });
  }
  private filterInsurers() {
    if (!this.AllInsurerHealth) {
      return;
    }
    let search = this.insurerFilterCtrl.value;
    if (!search) {
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredInsurers.next(
      this.AllInsurerHealth.filter(insurer => insurer.Insurer.toLowerCase().indexOf(search) > -1).slice(0, 500)
    );
  }

  ngAfterViewInit() {
    this._setDataSource();
  }
  ngOnInit() {
    this.Create_Form_Validation();
  }
  get distributionArray() {
    if (this.Health_OR_Excel_Heading == undefined)
      return null
    else
      return this.User_FGA.get('distribution') as FormArray
  }
  Excel_Sheet_Heading() {
    
    const controls = this.User_FG.controls;
    this.spinner.show();
    this.Srv_Received.Get_Excel_Sheet_Heading(controls.OR_Excel_Id.value, controls.OR_Excel_Sheet_Id.value).subscribe(result => {
      if (result["table1"].length > 0) {
        this.Is_Filter = true;
        this.employeeInfoTable = result["table1"];
        this.Heading_Table = result["table1"];
        this.Exl_Heading_Table.data = this.employeeInfoTable;

        this.Health_OR_Excel_Heading = result["Table2"];

        this.User_FGA = new FormGroup({
          distribution: new FormArray(this.Heading_Table.map(x => new FormGroup({
            OR_Exl_Heading_Id: new FormControl(x.Heading)
          })
          ))
        });
      }
      else {
        this.Is_Filter = false;
        this.GV_Matched_Exl = [];
        this.Net_Amt_Not_Matched_Exl = [];
        this.Payout_Already_Received = [];
        this.Policy_Not_Found = [];
      }
      this.spinner.hide();
    });
    
  }
  Load_Excel_Sheet() {
    
    const controls = this.User_FG.controls;

    this.Payout_Month = controls.OR_Month_Id.value;
    this.Payout_Year = controls.OR_Year.value;

    this.spinner.show();
    this.Srv_Received.Get_Excel_Sheet(controls.OR_Excel_Id.value).subscribe(result => {
      if (result.length > 0) {
        this.Is_Excel_Sheet = true;
        this.OR_Excel_Sheet = result;
      }
      else {
        this.Is_Excel_Sheet = false;
        this.Is_Filter = false;
        this.GV_Matched_Exl = [];
        this.Net_Amt_Not_Matched_Exl = [];
        this.Payout_Already_Received = [];
        this.Policy_Not_Found = [];
      }
      this.spinner.hide();
    });
    
  }

  Create_Form_Validation() {
    
    this.User_FG = this.User_FB.group({
      OR_Month_Id: [null, Validators.required],
      OR_Excel_Id: [null, Validators.required],
      OR_Excel_Sheet_Id: [null, Validators.required],
      Policy_Type_Id: [null, Validators.required],
      Achivement_For_Id: [null, Validators.required],
      OR_Year: [null, Validators.required],
      From_Prolicy_Date: [null, Validators.required],
      To_Prolicy_Date: [null, Validators.required]
    });
    
  }
  Load_Excel() {
    
    const controls = this.User_FG.controls;
    this.spinner.show();
    this.Srv_Received.Get_Excel_Files(this.insurerCtrl.value, controls.OR_Month_Id.value, controls.OR_Year.value).subscribe(result => {
      if (result.length > 0) {
        this.Is_Excel = true;
        this.OR_Excel = result;
      }
      else {
        this.Is_Excel = false;
        this.Is_Excel_Sheet = false;
        this.Is_Filter = false;
        this.GV_Matched_Exl = [];
        this.Net_Amt_Not_Matched_Exl = [];
        this.Payout_Already_Received = [];
        this.Policy_Not_Found = [];
      }
      this.spinner.hide();
    });
    
  }
  Match_Excel_Data() {
    
    this.OR_Selected_Excel_Heading = [];
    const controls = this.User_FG.controls;

    var Policy_Type_Id: any = '';
    for (let k = 0; k < controls.Policy_Type_Id.value.length; k++) {
      each(this.AllPolicy_Type_Health.filter(el => el.id == controls.Policy_Type_Id.value[k]), (Policy_Type_Ids: any) => {
        Policy_Type_Id = Policy_Type_Id + ', ' + Policy_Type_Ids.id;
      });
    }
    Policy_Type_Id = Policy_Type_Id.replace(", ", "");


    // var Achivement_For_Id: any = '';
    // for (let k = 0; k < controls.Achivement_For_Id.value.length; k++) {
    //   each(this.Achivement_For.filter(el => el.id == controls.Achivement_For_Id.value[k]), (Achivement_For_Ids: any) => {
    //     Achivement_For_Id = Achivement_For_Id + ', ' + Achivement_For_Ids.id;
    //   });
    // }
    // Achivement_For_Id = Achivement_For_Id.replace(", ", "");

    this.Health_Import_OR.Policy_Type_Id = Policy_Type_Id;
    this.Health_Import_OR.Achivement_For_Id = controls.Achivement_For_Id.value;
    this.Health_Import_OR.Insurer_Id = this.insurerCtrl.value;
    this.Health_Import_OR.Excel_Id = controls.OR_Excel_Id.value;
    this.Health_Import_OR.Sheet_Name = controls.OR_Excel_Sheet_Id.value;

    const FGA_controls = this.User_FGA.controls;
    this.Heading_Table.forEach((element, index) => {
      const Condition = {
        Row_Id: index,
        Exl_Heading: this.distributionArray.at(index).value.OR_Exl_Heading_Id
      };
      this.OR_Selected_Excel_Heading.push(Condition);
    });

    this.Health_Import_OR.Health_OR_Excel_Heading = this.OR_Selected_Excel_Heading;
    this.Health_Import_OR.Policy_From = controls.From_Prolicy_Date.value;
    this.Health_Import_OR.Policy_To = controls.To_Prolicy_Date.value;




    // this.Health_Import_OR.Policy_To = controls.To_Prolicy_Date.value;
    // const From_Prolicy_Date = controls.From_Prolicy_Date.value;
    // if (From_Prolicy_Date) {
    // 	this.Health_Import_OR.Policy_From = this.typesUtilsService.dateFormat(From_Prolicy_Date);
    // }x`

    // const To_Prolicy_Date = controls.To_Prolicy_Date.value;
    // if (To_Prolicy_Date) {
    // 	this.Health_Import_OR.Policy_To = this.typesUtilsService.dateFormat(To_Prolicy_Date);
    // }
    this.spinner.show();
    this.Srv_Received.Get_Data(this.Health_Import_OR).subscribe(result => {
      if (result) {
        this.GV_Matched_Exl = result["Table1"];
        this.GV_Policy_Matched_Data.data = this.GV_Matched_Exl;

        this.Net_Amt_Not_Matched_Exl = result["Table2"];
        this.GV_Net_Amt_Not_Matched_Data.data = this.Net_Amt_Not_Matched_Exl;

        this.Payout_Already_Received = result["Table3"];
        this.Payout_Already_Received_Data.data = this.Payout_Already_Received;

        this.Policy_Not_Found = result["Table4"];
        this.Policy_Not_Found_Data.data = this.Policy_Not_Found;

        var Display_Array = []
        this.OR_Selected_Excel_Heading.forEach((item, index) => {
          if (Display_Array.findIndex(i => i.Exl_Heading == item.Exl_Heading) === -1) {
            Display_Array.push(item)
          }

        });

        this.displayedColumns_not_matched = Display_Array.map(x => x.Exl_Heading);
        this._setDataSource();
      }
      this.spinner.hide();
    });
    
  }

  OR_Receive() {
    
    const User_Id = localStorage.getItem(environment.User_Id);
    if (!User_Id)
      this.store.dispatch(new Logout());
    else
      this.User_Id = User_Id

    const _messages = [];
    this.selection.selected.forEach(elem => {
      _messages.push({
        CRow_Version: `${elem.CRow_Version}`,
        Achivement_For: elem.Achivement_For,
        Payout_Month: this.Payout_Month,
        Payout_Year: this.Payout_Year,
        User_Id: this.User_Id
      });
    });
    this.spinner.show();
    this.Srv_Received.Save_OR_Receive(_messages).subscribe(result => {
      if (result) {
        this.Match_Excel_Data();
        this._setDataSource();
      }
      this.spinner.hide();
    });
    
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.GV_Matched_Exl.length;
    return numSelected === numRows;
  }

  /**
   * Toggle all selections
   */
  masterToggle() {
    if (this.selection.selected.length === this.GV_Matched_Exl.length) {
      this.selection.clear();
    } else {
      this.GV_Matched_Exl.forEach(row => this.selection.select(row));
    }
  }

  _setDataSource() {
    setTimeout(() => {

      this.GV_Policy_Matched_Data.sort = this.Matched_sort;
      this.GV_Policy_Matched_Data.paginator = this.Matched_paginator;

      this.GV_Net_Amt_Not_Matched_Data.sort = this.Not_Matched_sort;
      this.GV_Net_Amt_Not_Matched_Data.paginator = this.Not_Matched_paginator;

      this.Payout_Already_Received_Data.sort = this.Received_sort;
      this.Payout_Already_Received_Data.paginator = this.Received_paginator;

      this.Policy_Not_Found_Data.sort = this.Not_Found_sort;
      this.Policy_Not_Found_Data.paginator = this.Not_Found_paginator;

    });
  }

  Policy_Matched_Export() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.GV_Matched_Exl);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Health Policy Matched');
    xlsx.writeFile(wb, 'Health_Policy_Matched.xlsx');
    
  }

  Excel_Net_Amount_Not_Matched_Export() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Net_Amt_Not_Matched_Exl);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Health Net Amount Not Matched');
    xlsx.writeFile(wb, 'Health_Excel_Net_Amount_Not_Matched.xlsx');
    
  }

  Payout_Already_Received_Export() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Payout_Already_Received);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Health Payout Already Received');
    xlsx.writeFile(wb, 'Health_Payout_Already_Received.xlsx');
    
  }

  Policy_Number_Not_Found_Export() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Policy_Not_Found);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Health Policy Number Not Found');
    xlsx.writeFile(wb, 'Health_Policy_Number_Not_Found.xlsx');
    
  }
}
