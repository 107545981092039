import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Sme_OR_Master, SmeMasterStatusModel, SmeORMaster } from '../_models/sme-master.model';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';

@Injectable()
export class SmeMasterService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	
	loadSmeMasterPage(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		return this.http.get<QueryResultsModel[]>(Base_Url + 'SmeMaster/Get_All_SmeMaster').pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res, queryParams, ['status']);
				return of(result);
			})
		);
	}

	getAllInsurer(): Observable<Sme_OR_Master[]> {
		return this.http.get<Sme_OR_Master[]>(Base_Url + 'SmeMaster/Get_All_Insurer')		
	}

	getAllPolicy_Type(): Observable<Sme_OR_Master[]> {
		return this.http.get<Sme_OR_Master[]>(Base_Url + 'SmeMaster/Get_All_Policy_Type')		
	}

	Get_Edit_Data(Insurer_Id:number,User_Id:string): Observable<Sme_OR_Master[]> {
		return this.http.get<Sme_OR_Master[]>(Base_Url + 'SmeMaster/Get_Edit_Sme_Master/' + Insurer_Id + '/' + User_Id)
	}

	Get_History_Data(Insurer_Id:number): Observable<Sme_OR_Master[]> {
		return this.http.get<Sme_OR_Master[]>(Base_Url + 'SmeMaster/Get_History_Sme_Master/' + Insurer_Id)
	}

	createMaster(_Master: Sme_OR_Master) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeMaster/Save_SmeMaster', _Master);
	}

	updateMaster(_Master: SmeORMaster) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeMaster/Update_SmeMaster', _Master);
	}
	
	updateStatusMaster(_MasterStatus: SmeMasterStatusModel[]) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeMaster/Update_Status_SmeMaster', _MasterStatus);
	}
}
