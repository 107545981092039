import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { Subscription, of, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout } from '../../../../core/auth';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { each, find, some } from 'lodash';

import { environment } from '../../../../../environments/environment';
import { selectHealthMasterActionLoading } from '../../../../core/health';
import { HealthMasterActionToggleLoading } from '../../../../core/health/_actions/health-master.actions';
import { HealthMasterService } from '../../../../core/health/_services/health-master.service';
import { Health_MAchivements, Health_OR_Master } from '../../../../core/health/_models/health-master.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-health-master-condition',
	templateUrl: './health-master-condition.component.html'
})
export class HealthMasterConditionComponent implements OnInit, OnDestroy {
	IsReadOnly: boolean;
	Is_New: any = 1;
	Edit_Row_No: any = 0;
	CountOn_Val: any = 0;
	displayedColumns = ['actions', 'Achivement_For', 'Premium'];
	dataSource: MatTableDataSource<Health_MAchivements>;
	_achivement_Data: Health_MAchivements[] = [];
	Removed_achivement_Data: Health_MAchivements[] = [];
	DataSourceIndex: any;
	Edit_Achivement: boolean = false;
	_id: any;
	Is_History: any;
	_OR_Master_Id: any;
	_CRaw_Version: any;
	private _onDestroy = new Subject<void>();
	Achivement_FG: FormGroup;
	_NewRewards: number;
	showActionLoadingDistpatcher = new HealthMasterActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new HealthMasterActionToggleLoading({ isLoading: false });
	RewardsMdl: Health_OR_Master;
	hasFormErrors = false;
	hasExists = false;
	viewLoading = false;
	private componentSubscriptions: Subscription;

	Achivement_For = [
		{ id: 1, value: 'Regular' },
		{ id: 2, value: 'Reward' }
	];
	Achivement: any = [];
	constructor(public dialogRef: MatDialogRef<HealthMasterConditionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private User_FB: FormBuilder,
		private translate: TranslateService,
		private SrvMaster: HealthMasterService,
		private router: Router,
		private store: Store<AppState>,
		private spinner: NgxSpinnerService,
		private typesUtilsService: TypesUtilsService) {
		this.IsReadOnly = data.IsReadOnly;
		this.RewardsMdl = this.data.SelCondition;
		this.Is_History = this.data.Is_History;
	}
	ngOnInit() {
		
		this.Create_Form_Validation();
		this.dataSource = new MatTableDataSource();
		this.store.pipe(select(selectHealthMasterActionLoading)).subscribe(res => this.viewLoading = res);
		each(this.data.Sel_rewards_Data.Health_MAchivements, (_achivementData: Health_OR_Master) => {
			this._achivement_Data.push(_achivementData as any);
			this.dataSource = new MatTableDataSource(this._achivement_Data);
		});
		if (this._achivement_Data.length == 0) {
			this.New_Achivement();
		}
		if (this.Is_History == 0) {
			this.displayedColumns = ['actions', 'Achivement_For', 'Premium'];
		}
		else if (this.Is_History == 0) {
			this.displayedColumns = ['actions', 'Achivement_For', 'Premium'];
		}
		else if (this.Is_History == 0) {
			this.displayedColumns = ['actions', 'Achivement_For'];
		}
		else if (this.Is_History == 1) {
			this.displayedColumns = ['Achivement_For', 'Premium'];
		}
		else if (this.Is_History == 1) {
			this.displayedColumns = ['Achivement_For', 'Premium'];
		}
		else if (this.Is_History == 1) {
			this.displayedColumns = ['Achivement_For'];
		}
		
	}
	Create_Form_Validation() {
		
		this.Achivement_FG = this.User_FB.group({
			Achivement_For_Id: [null, Validators.required],
			Premium: [null, Validators.required]
		});
		
	}
	Add_Achivement() {
		
		this.hasFormErrors = false;

		const User_Controls = this.Achivement_FG.controls;
		if (this.Achivement_FG.invalid) {
			Object.keys(User_Controls).forEach(controlName =>
				User_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const controls = this.Achivement_FG.controls;
		const Temp_Achivement_For = this.Achivement_For.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.Achivement_For_Id.value) == i
		);
		if (controls.Premium.value > 100) {
			this.Achivement_FG.controls['Premium'].setValue(100);
		}
		this.Achivement = {
			id: this._id,
			OR_Master_Id: this._OR_Master_Id,
			CRaw_Version: this._CRaw_Version,
			Achivement_For_Id: Temp_Achivement_For[0].id,
			Achivement_For: Temp_Achivement_For[0].value,
			Premium: controls.Premium.value,
			Is_Deleted: true
		};

		var IsExists = 0;
		if (this.Is_New == 0) {
			for (let i = 0; i < this._achivement_Data.length; i++) {
				if (this.Edit_Row_No != i && this._achivement_Data[i]["Achivement_For_Id"] == this.Achivement["Achivement_For_Id"]) {
					IsExists = 1;
					this.hasExists = true;
					return;
				}
			}
		}
		else if (this.Is_New == 1) {
			for (let i = 0; i < this._achivement_Data.length; i++) {
				if (this._achivement_Data[i]["Achivement_For_Id"] == this.Achivement["Achivement_For_Id"]) {
					IsExists = 1;
					this.hasExists = true;
					return;
				}
			}
		}

		if (IsExists == 1) {
			this.hasExists = true;
			return false;
		}

		if (this.hasExists == false && this.Edit_Achivement == false) {
			this._achivement_Data.push(this.Achivement as any);
		}
		else if (this.hasExists == false) {
			this._achivement_Data[this.DataSourceIndex]["id"] = this.Achivement["id"];
			this._achivement_Data[this.DataSourceIndex]["OR_Master_Id"] = this.Achivement["OR_Master_Id"];
			this._achivement_Data[this.DataSourceIndex]["CRaw_Version"] = this.Achivement["CRaw_Version"];
			this._achivement_Data[this.DataSourceIndex]["Achivement_For_Id"] = this.Achivement["Achivement_For_Id"];
			this._achivement_Data[this.DataSourceIndex]["Achivement_For"] = this.Achivement["Achivement_For"];
			this._achivement_Data[this.DataSourceIndex]["Premium"] = this.Achivement["Premium"];
		}
		this.dataSource = new MatTableDataSource(this._achivement_Data);
		this._NewRewards = undefined;
		
	}


	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	getTitle(): string {
		// if (this.RewardsMdl.CRaw_Version > 0) {
		if (this.IsReadOnly == true && this.Is_History == 0) {
			this.Achivement_FG.controls['Premium'].disable();
			return 'View Achivement';
		}
		// 	else if (this.IsReadOnly == false) {
		// 		return `Edit Master '${this.RewardsMdl.Insurer}'`;
		// 	}
		// }
		else if (this.Is_History == 0) {
			this.Achivement_FG.controls['Premium'].enable();
			return 'Edit Achivement';
		}
		else if (this.Is_History == 1) {
			return 'History Achivement';
		}
		return 'New Achivement';
	}

	prepareMaster(): Health_OR_Master {
		const _Master = new Health_OR_Master();
		// _Master.id = this.RewardsMdl.id;				
		return _Master;
	}
	Cancel_Achivement() {
		this._NewRewards = undefined;
	}
	New_Achivement() {
		
		this.Is_New = 1;
		this.Edit_Achivement = false;
		this.DataSourceIndex = null;
		this._id = 0;
		this._OR_Master_Id = this.data.Sel_rewards_Data.id;
		this._CRaw_Version = 0;
		this.Achivement_FG.controls['Achivement_For_Id'].setValue('');
		this.Achivement_FG.controls['Premium'].setValue('0');
		this._NewRewards = 1;
		this.CountOn_Val = 0;
		
	}
	onSubmit() {
		this.dialogRef.close({ _achivement_Data: this._achivement_Data, Removed_achivement_Data: this.Removed_achivement_Data });
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
		this.hasExists = false;
	}

	EditAchivements(SelCondition: Health_MAchivements, index) {
		
		this.Is_New = 0;
		this.Edit_Achivement = true;
		this.Edit_Row_No = index;
		this.DataSourceIndex = index;
		this._id = SelCondition.id;
		this._OR_Master_Id = SelCondition.OR_Master_Id;
		this._CRaw_Version = SelCondition.CRaw_Version;
		this.Achivement_FG.controls['Achivement_For_Id'].setValue(SelCondition.Achivement_For_Id);
		this.Achivement_FG.controls['Premium'].setValue(SelCondition.Premium);
		this._NewRewards = 1;
		
	}

	unassing(SelCondition: Health_OR_Master, index) {
		
		this.Achivement = [];

		for (let i = 0; i < this._achivement_Data.length; i++) {
			if (index == i) {
				this.Achivement = {
					id: this._achivement_Data[index]["id"],
					OR_Master_Id: this._achivement_Data[index]["OR_Master_Id"],
					CRaw_Version: this._achivement_Data[index]["CRaw_Version"],
					Achivement_For_Id: this._achivement_Data[index]["Achivement_For_Id"],
					Achivement_For: this._achivement_Data[index]["Achivement_For"],
					Premium: this._achivement_Data[index]["Premium"],
				};
				this._achivement_Data.splice(index, 1);
			}
		}
		this.Removed_achivement_Data.push(this.Achivement as any);
		this.dataSource = new MatTableDataSource(this._achivement_Data);
		
	}
}
