import { BaseModel } from '../../_base/crud';
export class MotorReportMdl extends BaseModel {
    Insurer_Id:string;
    Policy_Date_From: string;
    Policy_Date_To:string;
    INS_Policy_Type_Id: string;
    Insurance_Id: string;
    Pending: string;
    Received: string;
    Upcoming: string;
}
