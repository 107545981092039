import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin, Observable, defer } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services

// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    HealthMasterActionTypes,
    HealthMasterPageRequested,
    HealthMasterPageLoaded,
    AllInsurerRequested,
    AllInsurerLoaded,
    AllPolicy_TypeRequested,
    AllPolicy_TypeLoaded,
    HealthMasterPageToggleLoading
} from '../_actions/health-master.actions';
import { of } from 'rxjs';
import { HealthMasterService } from '../_services/health-master.service';
import { Health_OR_Master } from '../_models/health-master.model';

@Injectable()
export class HealthMasterEffects {
    showPageLoadingDistpatcher = new HealthMasterPageToggleLoading({ isLoading: true });

    @Effect()
    loadHealthMasterPage$ = this.actions$.pipe(
        ofType<HealthMasterPageRequested>(HealthMasterActionTypes.HealthMasterPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this._HealthMasterService.loadHealthMasterPage(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new HealthMasterPageLoaded({
                _HealthMaster: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    loadAllInsurer$ = this.actions$
        .pipe(
            ofType<AllInsurerRequested>(HealthMasterActionTypes.AllInsurerRequested),
            mergeMap(() => this._HealthMasterService.getAllInsurer()),
            map((result: Health_OR_Master[]) => {
                return new AllInsurerLoaded({
                    _HealthMaster: result
                });
            })
        );

    @Effect()
    loadAllPolicy_Type$ = this.actions$
        .pipe(
            ofType<AllPolicy_TypeRequested>(HealthMasterActionTypes.AllPolicy_TypeRequested),
            mergeMap(() => this._HealthMasterService.getAllPolicy_Type()),
            map((result: Health_OR_Master[]) => {
                return new AllPolicy_TypeLoaded({
                    _HealthMaster: result
                });
            })
        );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllInsurerRequested());
    });

    @Effect()
    init1$: Observable<Action> = defer(() => {
        return of(new AllPolicy_TypeRequested());
    });
    constructor(private actions$: Actions, private _HealthMasterService: HealthMasterService, private store: Store<AppState>) { }
}
