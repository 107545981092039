import { Component, OnInit, ViewChild, AfterViewInit, Inject, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatOption, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { merge, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Sme_OR_Master, selectAllInsurerSme } from '../../../../core/sme';
import { FormGroup, FormBuilder, Validators } from '../../../../../../node_modules/@angular/forms';
import { each } from 'lodash';
import { SmeReportService } from '../../../../core/sme/_services/sme-report.service';
import { SmeReportMdl } from '../../../../core/sme/_models/sme-report.model';
import * as xlsx from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'kt-sme-summary-dlg',
    templateUrl: './sme-summary-dlg.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SmeSummaryDlgComponent implements OnInit, AfterViewInit {
    Tot_Premium: any = 0;
    Tot_Comm_Amount_Regular: any = 0;
    Tot_TDS_Amount_Regular: any = 0;
    Tot_Net_Amount_Regular: any = 0;
    Tot_Comm_Amount_Rewards: any = 0;
    Tot_TDS_Amount_Rewards: any = 0;
    Tot_Net_Amount_Rewards: any = 0;
    viewLoading = false;
    Sme_SummaryDlg_Report: SmeReportMdl;
    private subscriptions: Subscription[] = [];
    Report_Data: any;
    dataSource = new MatTableDataSource();
    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    @ViewChild('sort', { static: true }) sort: MatSort;
    displayedColumns = ['Full_Customer_Name', 'Insurer', 'Policy_Number', 'Policy_Start_Date', 'Policy_End_Date', 'Policy_Type', 'Pay_Discount', 'Premium', 'Comm_Amount_Regular', 'TDS_Amount_Regular', 'Net_Amount_Regular', 'Is_Regular_Received', 'Comm_Amount_Rewards', 'TDS_Amount_Rewards', 'Net_Amount_Rewards', 'Is_Rewards_Received', 'Cust_Status'];
    constructor(private Srv_Report_SummaryDlg: SmeReportService,
        private spinner: NgxSpinnerService, public dialogRef: MatDialogRef<SmeSummaryDlgComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.Sme_SummaryDlg_Report = new SmeReportMdl;
        this.Sme_SummaryDlg_Report = data;
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit() {
        
        this.viewLoading = true;
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe().subscribe();
        this.subscriptions.push(paginatorSubscriptions);
        this.spinner.show();
        this.Srv_Report_SummaryDlg.SummaryDlgSmeReport(this.Sme_SummaryDlg_Report).subscribe(result => {
            if (result) {
                this.Report_Data = result;
                this.dataSource.data = this.Report_Data;
                this.Report_Data.forEach(key => {
                    this.Tot_Premium += parseFloat(key.Premium);
                    this.Tot_Comm_Amount_Regular += parseFloat(key.Comm_Amount_Regular_Amt);
                    this.Tot_TDS_Amount_Regular += parseFloat(key.TDS_Amount_Regular_Amt);
                    this.Tot_Net_Amount_Regular += parseFloat(key.Net_Amount_Regular);
                    this.Tot_Comm_Amount_Rewards += parseFloat(key.Comm_Amount_Rewards_Amt);
                    this.Tot_TDS_Amount_Rewards += parseFloat(key.TDS_Amount_Rewards_Amt);
                    this.Tot_Net_Amount_Rewards += parseFloat(key.Net_Amount_Rewards);
                });
                this.Tot_Premium = parseFloat(this.Tot_Premium).toFixed(2);
                this.Tot_Comm_Amount_Regular = parseFloat(this.Tot_Comm_Amount_Regular).toFixed(2);
                this.Tot_TDS_Amount_Regular = parseFloat(this.Tot_TDS_Amount_Regular).toFixed(2);
                this.Tot_Net_Amount_Regular = parseFloat(this.Tot_Net_Amount_Regular).toFixed(2);
                this.Tot_Comm_Amount_Rewards = parseFloat(this.Tot_Comm_Amount_Rewards).toFixed(2);
                this.Tot_TDS_Amount_Rewards = parseFloat(this.Tot_TDS_Amount_Rewards).toFixed(2);
                this.Tot_Net_Amount_Rewards = parseFloat(this.Tot_Net_Amount_Rewards).toFixed(2);
            }
            this.spinner.hide();
        });
        this.viewLoading = false;
        
    }

    Export_To_Excel() {
        
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Report_Data);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'SummaryDlg OR');
        xlsx.writeFile(wb, 'Sme_SummaryDlg_Report.xlsx');
        
    }
}
