import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  templateUrl: './OR-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ORUploadComponent implements OnInit {  
  constructor() { }

  ngOnInit() {
  }

}
