// NGRX
import { createSelector } from '@ngrx/store';
// Lodash
import { each, find, some } from 'lodash';
// Selectors
import { selectAllRoles,selectAllRolesAssigned } from './role.selectors';
import { selectAllUserPermissions } from './user_permission.selectors';
import { selectAllDepartments } from '../../master/_selectors/department.selectors';
// Models
import { RoleModel } from '../_models/role.model';
import { UserPermissionModel } from '../_models/user_permission.model';
import { DepartmentModel } from '../../master';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);


export const currentAuthToken = createSelector(
    selectAuthState,
    auth => auth.authToken
);

export const isUserLoaded = createSelector(
    selectAuthState,
    auth => auth.isUserLoaded
);

export const currentUser = createSelector(
    selectAuthState,
    auth => auth.user
);

export const currentUserRoleIds = createSelector(
    currentUser,
    user => {
        if (!user) {
            return [];
        }

        return user.roles;
    }
);

export const currentUserPermissionsIds = createSelector(
    currentUserRoleIds,
    selectAllRolesAssigned,
    (userRoleIds: number[], allRoles: RoleModel[]) => {
        const result = getPermissionsIdsFrom(userRoleIds, allRoles);
        return result;
    }
);

export const checkHasUserPermission = (permissionId: number) => createSelector(
    currentUserPermissionsIds,
    (ids: number[]) => {
        return ids.some(id => id === permissionId);
    }
);

export const currentUserDepartments = createSelector(    
    selectAllDepartments,    
    department => {
        if (!department) {
            return [];
        }

        return department;
    }
);

export const currentUserPermissions = createSelector(
    currentUserPermissionsIds,
    selectAllUserPermissions,
    (permissionIds: number[], allPermissions: UserPermissionModel[]) => {
        const result: UserPermissionModel[] = [];
        each(permissionIds, id => {
            const userPermission = find(allPermissions, elem => elem.id === id);
            if (userPermission) {
                result.push(userPermission);
            }
        });
        return result;
    }
);

function getPermissionsIdsFrom(userRolesIds: number[] = [], allRoles: RoleModel[] = []): number[] {
    const userRoles: RoleModel[] = [];
    each(userRolesIds, (_id: number) => {
       const userRole = find(allRoles, (_role: RoleModel) => _role.id === _id);
       if (userRole) {
           userRoles.push(userRole);
       }
    });

    const result: number[] = [];
    each(userRoles, (_role: RoleModel) => {
        each(_role.RolePermissionData, id => {
            if (!some(result, _id => _id === id)) {
                result.push(id);
            }
        });
    });
    return result;
}
