import { BaseModel } from '../../_base/crud';
import { UserAddress } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { RoleModel } from './role.model';

export class UserModel extends BaseModel {
    OTP: string;
    username: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    pic: string;
    UserFullName: string;
    occupation: string;
    OfficeName: string;
    Error: string;
    phone: string;
    address: UserAddress;
    socialNetworks: SocialNetworks;

    //---------------------
    id: number;
    User_Id: string;
    Is_Read_Only: boolean;
    Is_Not_Removable: boolean;
    Is_Not_Active: boolean;
    User_Code: string;
    First_Name: string;
    Middle_Name: string;
    Last_Name: string;
    FatherFullName: string;
    DOB: string;
    DOJ: string;
    Gender_Id: string;
    Gender: string;
    Marital_Status_Id: string;
    DOA: string;
    PAN_Number: string;
    Aadhar_Number: string;
    Driving_Licence_Number: string;
    Office_Mobile: string;
    Personal_Mobile: string;
    Alternate_Mobile: string;
    Office_Mail: string;
    Personal_Mail: string;
    Alternate_Mail: string;
    status: number;
    CRaw_Version: number;
    //------------------Address------------------------
    Users_Id: number;
    House_Flat_No: string;
    Street_Flat_Name_1: string;
    Street_Flat_Name_2: string;
    Landmark: string;
    Area_Id: string;    
    AreaMdl: UserArea[];
    CityMdl: UserCity[];
    City_Town_Id: string;
    //-------------------------User_Authentication----------------------------
    Password: string;
    Is_Login_With_OTP: string;
    CPassword_Modified_Date: string;
    Device_Id: string;
    In_Time: string;
    Out_Time: string;
    Working_Days: number[];
    Is_On_Role: string;
    AssignedRoles: RoleModel[];

    clear(): void {
        //---------------------        

        this.OTP = '';
        this.username = '';
        this.email = '';
        this.roles = [];
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.pic = './assets/media/users/default.jpg';
        this.occupation = '';
        this.Error = '';
        this.phone = '';
        //----------------

        this.id = undefined;
        this.User_Id = undefined;
        this.Is_Read_Only = false;
        this.Is_Not_Removable = false;
        this.Is_Not_Active = false;
        this.User_Code = '';
        this.First_Name = '';
        this.Middle_Name = '';
        this.Last_Name = '';
        this.FatherFullName = '';
        this.DOB = '';
        this.DOJ = '';
        this.Gender_Id = '';
        this.Gender = '';
        this.Marital_Status_Id = '0';
        this.DOA = '';
        this.PAN_Number = '';
        this.Aadhar_Number = '';
        this.Driving_Licence_Number = '';
        this.Office_Mobile = '';
        this.Personal_Mobile = '';
        this.Alternate_Mobile = '';
        this.Office_Mail = '';
        this.Personal_Mail = '';
        this.Alternate_Mail = '';
        this.status = undefined;
        this.CRaw_Version = undefined;
        //------------------Address------------------------
        this.Users_Id = undefined;
        this.House_Flat_No = '';
        this.Street_Flat_Name_1 = '';
        this.Street_Flat_Name_2 = '';
        this.Landmark = '';
        this.Area_Id = '0';        
        this.City_Town_Id = '0';
        //-------------------------User_Authentication----------------------------
        this.Password = '';
        this.Is_Login_With_OTP = '';
        this.CPassword_Modified_Date = '';
        this.Device_Id = '';
        this.In_Time = '';
        this.Out_Time = '';
        this.Working_Days = [];
        this.Is_On_Role = '';
    }
}

export class UserStatusModel extends BaseModel {
    Status: number;
    CRaw_Version: number;
    User_Id: string;
    clear() {
        this.Status = 0;
        this.CRaw_Version = 0;
        this.User_Id = undefined;
    }
}

export class UserArea extends BaseModel {
    id: number;
    City_Town_Id: number;
    value: string;
    clear() {
        this.id = 0;
        this.City_Town_Id = 0;
        this.value = '';
    }
}

export class UserCity extends BaseModel {
    id: number;    
    value: string;
    clear() {
        this.id = 0;
        this.value = '';
    }
}

