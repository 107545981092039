import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RouterModule, 
		 Routes } from '@angular/router';
import { FormsModule, 
		 ReactiveFormsModule, 
		 FormControl} from '@angular/forms';
import { HttpClientModule, 
		 HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
import { HttpUtilsService, 
		 TypesUtilsService, 
		 InterceptService, 
		 LayoutUtilsService} from '../../../core/_base/crud';
import { ModuleGuard } from '../../../core/auth';

import { ActionNotificationComponent } from '../../partials/content/crud';
import { UserManagementComponent } from './user-management.component';
import { UsersListComponent } from './users/users-list/users-list.component';

import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RoleEditDialogComponent } from './roles/role-edit/role-edit.dialog.component';
import { UserRoleListComponent } from './users/_subs/user-roles/user-roles-list.component';
import { UserPermissionDialogComponent } from './users/_subs/user-permission/user-permission.component';


import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,	
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	DateAdapter,
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS
} from '@angular/material';
import {
	usersReducer,
	UserEffects
} from '../../../core/auth';
import { NgbProgressbarModule, NgbProgressbarConfig, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserEditDialogComponent } from './users/user-edit/user-edit.dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
const routes: Routes = [
	{
		path: '',
		component: UserManagementComponent,
		children: [
			{
				path: '',
				redirectTo: 'roles',
				pathMatch: 'full'
			},
			{
				canActivate: [ModuleGuard],
				data: { moduleName: 'accessToRoleSubMenu' },
				path: 'roles',
				component: RolesListComponent
			},
			{
				canActivate: [ModuleGuard],
				data: { moduleName: 'accessToUserSubMenu' },
				path: 'users',
				component: UsersListComponent
			},
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgbProgressbarModule,
		NgxPermissionsModule.forChild(),
		NgxSpinnerModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('users', usersReducer),
        EffectsModule.forFeature([UserEffects]),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		NgxMatSelectSearchModule,
        MatInputModule,
		MatTableModule,
		NgbTimepickerModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
	],
	providers: [
		ModuleGuard,
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		DatePipe,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	],
	entryComponents: [
		ActionNotificationComponent,
		RoleEditDialogComponent,
		UserEditDialogComponent,
		UserPermissionDialogComponent
	],
	declarations: [
		UserManagementComponent,
		UsersListComponent,
		UserEditDialogComponent,
		RolesListComponent,
		RoleEditDialogComponent,
		UserPermissionDialogComponent,
		UserRoleListComponent
	]
})
export class UserManagementModule {
	constructor(private dateAdapter: DateAdapter<Date>) {
		var date = new FormControl(moment());
	}
}
