import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Sme_OR_Master, SmeMasterStatusModel, SmeORMaster } from '../_models/sme-master.model';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { SmeReportMdl } from '../_models/sme-report.model';

@Injectable()
export class SmeReportService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	
	PendingSmeReport(Report_Filter: SmeReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeReport/Pending', Report_Filter);
	}

	UpcomingSmeReport(Report_Filter: SmeReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeReport/Upcoming', Report_Filter);
	}

	ReceivedSmeReport(Report_Filter: SmeReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeReport/Received', Report_Filter);
	}

	SummarySmeReport(Report_Filter: SmeReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeReport/Summary', Report_Filter);
	}

	SummaryDlgSmeReport(Report_Filter: SmeReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'SmeReport/SummaryDlg', Report_Filter);
	}
}
