import { BaseModel } from '../../_base/crud';
export class Motor_Import_OR extends BaseModel {
    Insurance_Id:string;
    Achivement_For_Id: string;
    OR_On_Id:string;
    Insurer_Id: string;
    Policy_From: string;
    Policy_To: string;
    Excel_Id: string;
    Sheet_Name: string;
    Payout_Month:string;
    Payout_Year:string;
    Motor_OR_Excel_Heading: Motor_OR_Excel_Heading[];
}

export class Motor_OR_Save extends BaseModel {
    CRow_Version:string;
    Achivement_For: string;
}

export class Motor_OR_Excel_Heading extends BaseModel {
    Row_Id: number;
    Exl_Heading: string;
}
