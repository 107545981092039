import { BaseModel } from '../../_base/crud';

export class DepartmentModel  extends BaseModel {	
	id: number;
	Is_Read_Only: boolean;
	Is_Not_Removable: boolean;
	Is_Not_Active: boolean;
	Department_Name: string;
	UserPermissionData: number[];
	status: number;
	User_Id: string;
	Raw_Version: number;

	clear() {
		this.Is_Read_Only=false;
		this.Is_Not_Removable=false;
		this.Is_Not_Active=false;
		this.Department_Name = '';
		this.UserPermissionData = [];
		this.status = 1;
		this.id=undefined;
		this.User_Id=undefined;
		this.Raw_Version=0;
	}
}

export class DepartmentStatusModel  extends BaseModel {	
	Status: number;
	Raw_Version: number;
	User_Id: string;
	clear() {
		this.Status=0;
		this.Raw_Version=0;		
		this.User_Id=undefined;
	}
}
