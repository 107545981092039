export class UserAddress {
    id: number;
    Users_Id: number;
    House_Flat_No: string;
    Street_Flat_Name_1: string;
    Street_Flat_Name_2: string;
    Landmark: string;
    Area_Id: number;
    City_Town_Id: number;
    status: number;
    User_Id: string;
    Raw_Version: number;


    clear() {
        this.id = 0;
        this.Users_Id = 0;
        this.House_Flat_No = '';
        this.Street_Flat_Name_1 = '';
        this.Street_Flat_Name_2 = '';
        this.Landmark = '';
        this.Area_Id = 0;        
        this.City_Town_Id = 0;
        this.status = 1;
        this.User_Id = undefined;
        this.Raw_Version = 0;
    }
}
