export class RolesTable {
    public static roles: any = [
        {
            Is_Read_Only: false,
            Is_Not_Removable: false,
            Is_Not_Active: false,
            status: 1,
            Raw_Version: 132,
            id: 1,
            Role_Name: 'Administrator',
            RolePermissionData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,18,19,20]
        },
        {
            Is_Read_Only: false,
            Is_Not_Removable: true,
            Is_Not_Active: true,
            status: 0,
            Raw_Version: 1323,
            id: 2,
            Role_Name: 'Manager',
            RolePermissionData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        },
        {
            Is_Read_Only: true,
            Is_Not_Removable: true,
            Is_Not_Active: true,
            status: 1,
            Raw_Version: 1324,
            id: 3,
            Role_Name: 'Guest',
            RolePermissionData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        }
    ];
}
