import { BaseModel } from '../../_base/crud';
export class MotorORMaster extends BaseModel {	
	Insurer_Id: number;
	User_Id: string;
	Master_Data: Motor_OR_Master[];
	Removed_Rewards:Motor_OR_Master[];
}

export class Motor_OR_Master extends BaseModel {
	id: number;

	Is_Read_Only: boolean;
	Is_Not_Removable: boolean;
	Is_Not_Active: boolean;

	Insurer_Id: number;
	Insurer: string;

	Effect_Date: string;
	INS_Policy_Type_Id: number[];	
	INS_Policy_Type: string;
	Insurance_Id: number[];
	Insurance: string;
	OR_On_Id: number;
	OR_On: string;
	Capping_Id: number;
	Capping: string;

	Motor_MAchivements: Motor_MAchivements[];
	Removed_MAchivements: Motor_MAchivements[];

	status: number;
	User_Id: string;
	CRaw_Version: number;

	clear() {
		this.id = undefined;
		
		this.Is_Read_Only = false;
		this.Is_Not_Removable = false;
		this.Is_Not_Active = false;

		this.Insurer_Id = undefined;
		this.Insurer = '';
		
		this.Effect_Date = '';
		this.INS_Policy_Type_Id = [];
		this.INS_Policy_Type = '';
		this.Insurance_Id = [];
		this.Insurance = '';
		this.OR_On_Id = 0;
		this.OR_On = '';
		this.Capping_Id = 0;
		this.Capping = '';

		this.Motor_MAchivements = [];
		this.Removed_MAchivements=[];
		this.status = 1;
		
		this.User_Id = undefined;
		this.CRaw_Version = 0;
	}
}

export class Motor_MAchivements extends BaseModel {	
	Achivement_For_Id: number;
	Achivement_For: string;
	Count_On_Id: number;
	Count_On: string;
	YearC_Id: number;
	YearC: string;
	Year: string;
	OD: string;
	TP: string;
	CRaw_Version: number;
	OR_Master_Id:number;
	id:number;
	clear() {
		this.Achivement_For_Id = 0;
		this.Achivement_For = '';
		this.Count_On_Id = 0;
		this.Count_On = '';
		this.YearC_Id = 0;
		this.YearC = '';
		this.Year = '';
		this.OD = '';
		this.TP = '';
		this.CRaw_Version = 0;
		this.OR_Master_Id = undefined;
		this.id = undefined;
	}
}

export class MotorMasterStatusModel extends BaseModel {
	Status: number;
	CRaw_Version: number;
	User_Id: string;
	clear() {
		this.Status = 0;
		this.CRaw_Version = 0;
		this.User_Id = undefined;
	}
}