import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatOption, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { merge, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Health_OR_Master, selectAllPolicy_Type, selectAllInsurerHealth } from '../../../../core/health';
import { FormGroup, FormBuilder, Validators } from '../../../../../../node_modules/@angular/forms';
import { each } from 'lodash';
import { HealthReportService } from '../../../../core/health/_services/health-report.service';
import { HealthReportMdl } from '../../../../core/health/_models/health-report.model';
import * as xlsx from 'xlsx';
import { HealthSummaryDlgComponent } from '../health-summary-dlg/health-summary-dlg.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-health-summary',
  templateUrl: './health-summary.component.html'
})
export class HealthSummaryComponent implements OnInit, AfterViewInit {
  Is_Data = false;
  Tot_No_Of_Policy: any = 0;
  Tot_Sum_Of_Premium: any = 0;
  Tot_Net_Regular_OR_Amount: any = 0;
  Tot_Received_Regular_OR_Amount: any = 0;
  Tot_Pending_Regular_OR_Amount: any = 0;
  Tot_Net_Rewards_OR_Amount: any = 0;
  Tot_Received_Rewards_OR_Amount: any = 0;
  Tot_Pending_Rewards_OR_Amount: any = 0;
  curMonth: any = new Date().getMonth();
  curYear: any = new Date().getFullYear();

  FminDate = new Date(this.curYear - 10, 0, 1);
  FmaxDate = new Date(this.curYear, this.curMonth, 0);

  TminDate = new Date(this.curYear - 10, 0, 1);
  TmaxDate = new Date(this.curYear, this.curMonth, 0);

  Health_Summary_Report: HealthReportMdl;
  private subscriptions: Subscription[] = [];
  Report_Data: any;
  dataSource = new MatTableDataSource();
  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('sort', { static: false }) sort: MatSort;
  Report_FG: FormGroup;
  displayedColumns = ['Insurer', 'Policy_Type', 'Number_Of_Policy', 'Sum_Of_Premium', 'Net_Regular_OR_Amount', 'Received_Regular_OR_Amount', 'Pending_Regular_OR_Amount', 'Net_Rewards_OR_Amount', 'Received_Rewards_OR_Amount', 'Pending_Rewards_OR_Amount'];
  _INS_Policy_Type: any;
  INS_Policy_Type = [
    { id: 1, value: 'New' },
    { id: 2, value: 'Renewal' },
    { id: 3, value: 'Rollover' }
  ];
  _Policy_Type: any;
  All_Health_Policy_Type: Health_OR_Master[] = [];
  All_Health_Insurer: Health_OR_Master[] = [];
  constructor(public dialog: MatDialog, private store: Store<AppState>, private Srv_Report_Summary: HealthReportService, private Report_FB: FormBuilder,
    private spinner: NgxSpinnerService) {
    this.store.pipe(select(selectAllInsurerHealth)).subscribe(res => this.All_Health_Insurer = res);
    this.store.pipe(select(selectAllPolicy_Type)).subscribe(res => this.All_Health_Policy_Type = res);
    this.Health_Summary_Report = new HealthReportMdl;
  }
  setDataSource() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
  ngAfterViewInit() {
    this.setDataSource();
  }
  handleFromDateChange(event) {
    if (event.value != null) {
      var FMonth: any = new Date(event.value).getMonth();
      var FYear: any = new Date(event.value).getFullYear();
      var FDay: any = new Date(event.value).getDate();
      this.TminDate = new Date(FYear, FMonth, FDay);
    }
    else {
      this.Report_FG.controls['Policy_Date_To'].setValue('');
      this.curMonth = new Date().getMonth();
      this.curYear = new Date().getFullYear();

      this.FminDate = new Date(this.curYear - 10, 0, 1);
      this.FmaxDate = new Date(this.curYear, this.curMonth, 0);

      this.TminDate = new Date(this.curYear - 10, 0, 1);
      this.TmaxDate = new Date(this.curYear, this.curMonth, 0);
    }
  }
  handleToDateChange(event) {
    if (event.value != null) {
      var TMonth: any = new Date(event.value).getMonth();
      var TYear: any = new Date(event.value).getFullYear();
      var TDay: any = new Date(event.value).getDate();
      this.FmaxDate = new Date(TYear, TMonth, TDay);
    }
    else {
      this.Report_FG.controls['Policy_Date_From'].setValue('');
      this.curMonth = new Date().getMonth();
      this.curYear = new Date().getFullYear();

      this.FminDate = new Date(this.curYear - 10, 0, 1);
      this.FmaxDate = new Date(this.curYear, this.curMonth, 0);

      this.TminDate = new Date(this.curYear - 10, 0, 1);
      this.TmaxDate = new Date(this.curYear, this.curMonth, 0);
    }
  }
  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.Report_FG.controls.Insurer_Id.value.length == this.All_Health_Insurer.length)
      this.allSelected.select();

  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.Report_FG.controls.Insurer_Id
        .patchValue([...this.All_Health_Insurer.map(item => item.id), 0]);
    } else {
      this.Report_FG.controls.Insurer_Id.patchValue([]);
    }
  }

  ngOnInit() {
    this.Create_Form_Validation()
  }
  Create_Form_Validation() {
    this.Report_FG = this.Report_FB.group({
      Insurer_Id: [null, Validators.required],
      Policy_Date_From: [null, Validators.required],
      Policy_Date_To: [null, Validators.required],
      INS_Policy_Type_Id: [null, Validators.required],
      Policy_Type_Id: [null, Validators.required]
    });
  }
  Export_To_Excel() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Report_Data);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Summary OR');
    xlsx.writeFile(wb, 'Health_Summary_Report.xlsx');
    
  }

  Get_Report() {
    
    const controls = this.Report_FG.controls;
    if (controls.Policy_Date_To.value >= controls.Policy_Date_From.value) {
      var Insurer_Id: any = '';
      for (let k = 0; k < controls.Insurer_Id.value.length; k++) {
        each(this.All_Health_Insurer.filter(el => el.id == controls.Insurer_Id.value[k]), (Insurer_Ids: any) => {
          Insurer_Id = Insurer_Id + ',' + Insurer_Ids.id;
        });
      }
      Insurer_Id = Insurer_Id.replace(",", "");

      var INS_Policy_Type_Id: any = '';
      for (let k = 0; k < controls.INS_Policy_Type_Id.value.length; k++) {
        each(this.INS_Policy_Type.filter(el => el.id == controls.INS_Policy_Type_Id.value[k]), (INS_Policy_Type_Ids: any) => {
          INS_Policy_Type_Id = INS_Policy_Type_Id + ',' + INS_Policy_Type_Ids.id;
        });
      }
      INS_Policy_Type_Id = INS_Policy_Type_Id.replace(",", "");

      var Policy_Type_Id: any = '';
      for (let k = 0; k < controls.Policy_Type_Id.value.length; k++) {
        each(this.All_Health_Policy_Type.filter(el => el.id == controls.Policy_Type_Id.value[k]), (Policy_Type_Ids: any) => {
          Policy_Type_Id = Policy_Type_Id + ',' + Policy_Type_Ids.id;
        });
      }
      Policy_Type_Id = Policy_Type_Id.replace(",", "");

      this.Health_Summary_Report.INS_Policy_Type_Id = INS_Policy_Type_Id;
      this.Health_Summary_Report.Insurer_Id = Insurer_Id;
      this.Health_Summary_Report.Policy_Type_Id = Policy_Type_Id;
      this.Health_Summary_Report.Policy_Date_From = controls.Policy_Date_From.value;
      this.Health_Summary_Report.Policy_Date_To = controls.Policy_Date_To.value;

      this.spinner.show();
      this.Srv_Report_Summary.SummaryHealthReport(this.Health_Summary_Report).subscribe((result: any) => {
        if (result.length > 0) {
          this.Is_Data = true;
          this.Report_Data = result;
          this.dataSource.data = this.Report_Data;
          this.setDataSource();


          var T_No_Of_Policy: any = 0;
          var T_Sum_Of_Premium: any = 0;
          var T_Net_Regular_OR_Amount: any = 0;
          var T_Received_Regular_OR_Amount: any = 0;
          var T_Pending_Regular_OR_Amount: any = 0;
          var T_Net_Rewards_OR_Amount: any = 0;
          var T_Received_Rewards_OR_Amount: any = 0;
          var T_Pending_Rewards_OR_Amount: any = 0;

          this.Report_Data.forEach(key => {
            T_No_Of_Policy += parseFloat(key.Number_Of_Policy);
            T_Sum_Of_Premium += parseFloat(key.Sum_Of_Premium);
            T_Net_Regular_OR_Amount += parseFloat(key.Net_Regular_OR_Amount);
            T_Received_Regular_OR_Amount += parseFloat(key.Received_Regular_OR_Amount);
            T_Pending_Regular_OR_Amount += parseFloat(key.Pending_Regular_OR_Amount);
            T_Net_Rewards_OR_Amount += parseFloat(key.Net_Rewards_OR_Amount);
            T_Received_Rewards_OR_Amount += parseFloat(key.Received_Rewards_OR_Amount);
            T_Pending_Rewards_OR_Amount += parseFloat(key.Pending_Rewards_OR_Amount);
          });

          this.Tot_No_Of_Policy = parseFloat(T_No_Of_Policy).toFixed(2);
          this.Tot_Sum_Of_Premium = parseFloat(T_Sum_Of_Premium).toFixed(2);
          this.Tot_Net_Regular_OR_Amount = parseFloat(T_Net_Regular_OR_Amount).toFixed(2);
          this.Tot_Received_Regular_OR_Amount = parseFloat(T_Received_Regular_OR_Amount).toFixed(2);
          this.Tot_Pending_Regular_OR_Amount = parseFloat(T_Pending_Regular_OR_Amount).toFixed(2);
          this.Tot_Net_Rewards_OR_Amount = parseFloat(T_Net_Rewards_OR_Amount).toFixed(2);
          this.Tot_Received_Rewards_OR_Amount = parseFloat(T_Received_Rewards_OR_Amount).toFixed(2);
          this.Tot_Pending_Rewards_OR_Amount = parseFloat(T_Pending_Rewards_OR_Amount).toFixed(2);
        }
        else {
          this.Is_Data = false;
        }
        this.spinner.hide();
      });
    }
    else {
      return;
    }
    
  }

  Show_In_Details(Insurer_Id, Policy_Type_Id, Number_Of_Policy) {
    
    if (Number_Of_Policy > 0) {
      var Dlg_Health_Summary: any = new HealthReportMdl();
      Dlg_Health_Summary = {
        Insurer_Id: Insurer_Id,
        Policy_Type_Id: Policy_Type_Id,
        INS_Policy_Type_Id: this.Health_Summary_Report.INS_Policy_Type_Id,
        Policy_Date_From: this.Health_Summary_Report.Policy_Date_From,
        Policy_Date_To: this.Health_Summary_Report.Policy_Date_To
      }
      const dialogRef = this.dialog.open(HealthSummaryDlgComponent, { data: Dlg_Health_Summary });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(res => {
      });
    }
    
  }
}
