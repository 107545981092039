import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-received-master',
  templateUrl: './received-master.component.html'
})
export class ReceivedMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
