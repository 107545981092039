import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin, Observable, defer } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services

// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    MotorMasterActionTypes,
    MotorMasterPageRequested,
    MotorMasterPageLoaded,
    AllInsurerRequested,
    AllInsurerLoaded,
    AllInsuranceRequested,
    AllInsuranceLoaded,
    MotorMasterPageToggleLoading
} from '../_actions/motor-master.actions';
import { of } from 'rxjs';
import { MotorMasterService } from '../_services/motor-master.service';
import { Motor_OR_Master } from '../_models/motor-master.model';

@Injectable()
export class MotorMasterEffects {
    showPageLoadingDistpatcher = new MotorMasterPageToggleLoading({ isLoading: true });

    @Effect()
    loadMotorMasterPage$ = this.actions$.pipe(
        ofType<MotorMasterPageRequested>(MotorMasterActionTypes.MotorMasterPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this._MotorMasterService.loadMotorMasterPage(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new MotorMasterPageLoaded({
                _MotorMaster: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    loadAllInsurer$ = this.actions$
        .pipe(
            ofType<AllInsurerRequested>(MotorMasterActionTypes.AllInsurerRequested),
            mergeMap(() => this._MotorMasterService.getAllInsurer()),
            map((result: Motor_OR_Master[]) => {
                return new AllInsurerLoaded({
                    _MotorMaster: result
                });
            })
        );

    @Effect()
    loadAllInsurance$ = this.actions$
        .pipe(
            ofType<AllInsuranceRequested>(MotorMasterActionTypes.AllInsuranceRequested),
            mergeMap(() => this._MotorMasterService.getAllInsurance()),
            map((result: Motor_OR_Master[]) => {
                return new AllInsuranceLoaded({
                    _MotorMaster: result
                });
            })
        );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllInsurerRequested());
    });

    @Effect()
    init1$: Observable<Action> = defer(() => {
        return of(new AllInsuranceRequested());
    });
    constructor(private actions$: Actions, private _MotorMasterService: MotorMasterService, private store: Store<AppState>) { }
}
