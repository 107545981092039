import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-summary-master',
  templateUrl: './summary-master.component.html'
})
export class SummaryMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
