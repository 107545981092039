import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-report-summary',
  templateUrl: './report-summary.component.html'
})
export class ReportSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
