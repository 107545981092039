import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { Subscription, of, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout } from '../../../../core/auth';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { each, find, some } from 'lodash';

import { environment } from '../../../../../environments/environment';
import { selectMotorMasterActionLoading } from '../../../../core/motor';
import { MotorMasterActionToggleLoading } from '../../../../core/motor/_actions/motor-master.actions';
import { MotorMasterService } from '../../../../core/motor/_services/motor-master.service';
import { Motor_MAchivements, Motor_OR_Master } from '../../../../core/motor/_models/motor-master.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-motor-master-condition',
	templateUrl: './motor-master-condition.component.html'
})
export class MotorMasterConditionComponent implements OnInit, OnDestroy {
	IsReadOnly: boolean;
	Is_New: any = 1;
	Edit_Row_No: any = 0;
	CountOn_Val: any = 0;
	displayedColumns = ['actions', 'Achivement_For', 'Count_On', 'YearC', 'Year', 'OD', 'TP'];
	dataSource: MatTableDataSource<Motor_MAchivements>;
	_achivement_Data: Motor_MAchivements[] = [];
	Removed_achivement_Data: Motor_MAchivements[] = [];
	DataSourceIndex: any;
	Edit_Achivement: boolean = false;
	_id: any;
	Is_History: any;
	_OR_Master_Id: any;
	_CRaw_Version: any;
	private _onDestroy = new Subject<void>();
	Achivement_FG: FormGroup;
	_NewRewards: number;
	showActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: false });
	RewardsMdl: Motor_OR_Master;
	hasFormErrors = false;
	hasExists = false;
	viewLoading = false;
	private componentSubscriptions: Subscription;

	Achivement_For = [
		{ id: 1, value: 'Regular' },
		{ id: 2, value: 'Reward' }
	];

	Count_On = [
		{ id: 1, value: 'On Registration' },
		{ id: 2, value: 'On Manufacture' }
	];

	YearC = [
		{ id: 1, value: 'Less Than' },
		{ id: 2, value: 'Equal & Greater Than' }
	];
	Achivement: any = [];
	constructor(public dialogRef: MatDialogRef<MotorMasterConditionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private User_FB: FormBuilder,
		private translate: TranslateService,
		private SrvMaster: MotorMasterService,
		private router: Router,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private spinner: NgxSpinnerService) {
		this.IsReadOnly = data.IsReadOnly;
		this.RewardsMdl = this.data.SelCondition;
		this.Is_History = this.data.Is_History;
	}
	ngOnInit() {
		
		this.Create_Form_Validation();
		this.dataSource = new MatTableDataSource();
		this.store.pipe(select(selectMotorMasterActionLoading)).subscribe(res => this.viewLoading = res);
		each(this.data.Sel_rewards_Data.Motor_MAchivements, (_achivementData: Motor_OR_Master) => {
			this._achivement_Data.push(_achivementData as any);
			this.dataSource = new MatTableDataSource(this._achivement_Data);
		});
		if (this._achivement_Data.length == 0) {
			this.New_Achivement();
		}
		// if(this.data.Sel_rewards_Data.OR_On_Id!=1)
		// {
		// 	this.displayedColumns = ['actions', 'Achivement_For', 'Count_On', 'YearC', 'Year', 'TP'];
		// }
		if (this.Is_History == 0 && this.data.Sel_rewards_Data.OR_On_Id == 1) {
			this.displayedColumns = ['actions', 'Achivement_For', 'Count_On', 'YearC', 'Year', 'OD', 'TP'];
		}
		else if (this.Is_History == 0 && this.data.Sel_rewards_Data.OR_On_Id == 2) {
			this.displayedColumns = ['actions', 'Achivement_For', 'Count_On', 'YearC', 'Year', 'OD'];
		}
		else if (this.Is_History == 0 && this.data.Sel_rewards_Data.OR_On_Id == 3) {
			this.displayedColumns = ['actions', 'Achivement_For', 'Count_On', 'YearC', 'Year', 'TP'];
		}
		else if (this.Is_History == 1 && this.data.Sel_rewards_Data.OR_On_Id == 1) {
			this.displayedColumns = ['Achivement_For', 'Count_On', 'YearC', 'Year', 'OD', 'TP'];
		}
		else if (this.Is_History == 1 && this.data.Sel_rewards_Data.OR_On_Id == 2) {
			this.displayedColumns = ['Achivement_For', 'Count_On', 'YearC', 'Year', 'OD'];
		}
		else if (this.Is_History == 1 && this.data.Sel_rewards_Data.OR_On_Id == 3) {
			this.displayedColumns = ['Achivement_For', 'Count_On', 'YearC', 'Year', 'TP'];
		}
		
	}
	Create_Form_Validation() {
		
		this.Achivement_FG = this.User_FB.group({
			Achivement_For_Id: [null, Validators.required],
			Count_On_Id: [null],
			YearC_Id: { value: null, disabled: true },
			Year: { value: null, disabled: true },
			OD: [null, Validators.required],
			TP: [null, Validators.required]
		});
		
	}
	Add_Achivement() {
		
		this.hasFormErrors = false;

		const User_Controls = this.Achivement_FG.controls;
		if (this.Achivement_FG.invalid) {
			Object.keys(User_Controls).forEach(controlName =>
				User_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const controls = this.Achivement_FG.controls;
		const Temp_Achivement_For = this.Achivement_For.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.Achivement_For_Id.value) == i
		);
		const Temp_Count_On = this.Count_On.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.Count_On_Id.value) == i
		);
		const Temp_YearC = this.YearC.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.YearC_Id.value) == i
		);
		if (controls.OD.value > 100) {
			this.Achivement_FG.controls['OD'].setValue(100);
		}
		if (controls.TP.value > 100) {
			this.Achivement_FG.controls['TP'].setValue(100);
		}
		if (Temp_Count_On.length > 0) {

			this.Achivement = {
				id: this._id,
				OR_Master_Id: this._OR_Master_Id,
				CRaw_Version: this._CRaw_Version,
				Achivement_For_Id: Temp_Achivement_For[0].id,
				Achivement_For: Temp_Achivement_For[0].value,
				Count_On_Id: Temp_Count_On[0].id,
				Count_On: Temp_Count_On[0].value,
				YearC_Id: Temp_YearC[0].id,
				YearC: Temp_YearC[0].value,
				Year: controls.Year.value,
				OD: controls.OD.value,
				TP: controls.TP.value,
				Is_Deleted: true
			};
		}
		else {
			this.Achivement = {
				id: this._id,
				OR_Master_Id: this._OR_Master_Id,
				CRaw_Version: this._CRaw_Version,
				Achivement_For_Id: Temp_Achivement_For[0].id,
				Achivement_For: Temp_Achivement_For[0].value,
				Count_On_Id: 0,
				Count_On: '',
				YearC_Id: 0,
				YearC: '',
				Year: 0,
				OD: controls.OD.value,
				TP: controls.TP.value,
				Is_Deleted: true
			};
		}

		var IsExists = 0;
		if (this.Is_New == 0) {
			for (let i = 0; i < this._achivement_Data.length; i++) {
				if (this.Edit_Row_No != i && this._achivement_Data[i]["Achivement_For_Id"] == this.Achivement["Achivement_For_Id"] && this._achivement_Data[i]["Count_On_Id"] == this.Achivement["Count_On_Id"] && this._achivement_Data[i]["YearC_Id"] == this.Achivement["YearC_Id"]) {
					IsExists = 1;
					this.hasExists = true;
					return;
				}
			}
		}
		else if (this.Is_New == 1) {
			for (let i = 0; i < this._achivement_Data.length; i++) {
				if (this._achivement_Data[i]["Achivement_For_Id"] == this.Achivement["Achivement_For_Id"] && this._achivement_Data[i]["Count_On_Id"] == this.Achivement["Count_On_Id"] && this._achivement_Data[i]["YearC_Id"] == this.Achivement["YearC_Id"]) {
					IsExists = 1;
					this.hasExists = true;
					return;
				}
			}
		}

		if (IsExists == 1) {
			this.hasExists = true;
			return false;
		}

		if (this.hasExists == false && this.Edit_Achivement == false) {
			this._achivement_Data.push(this.Achivement as any);
		}
		else if (this.hasExists == false) {
			this._achivement_Data[this.DataSourceIndex]["id"] = this.Achivement["id"];
			this._achivement_Data[this.DataSourceIndex]["OR_Master_Id"] = this.Achivement["OR_Master_Id"];
			this._achivement_Data[this.DataSourceIndex]["CRaw_Version"] = this.Achivement["CRaw_Version"];
			this._achivement_Data[this.DataSourceIndex]["Achivement_For_Id"] = this.Achivement["Achivement_For_Id"];
			this._achivement_Data[this.DataSourceIndex]["Achivement_For"] = this.Achivement["Achivement_For"];
			this._achivement_Data[this.DataSourceIndex]["Count_On_Id"] = this.Achivement["Count_On_Id"];
			this._achivement_Data[this.DataSourceIndex]["Count_On"] = this.Achivement["Count_On"];
			this._achivement_Data[this.DataSourceIndex]["YearC_Id"] = this.Achivement["YearC_Id"];
			this._achivement_Data[this.DataSourceIndex]["YearC"] = this.Achivement["YearC"];
			this._achivement_Data[this.DataSourceIndex]["Year"] = this.Achivement["Year"];
			this._achivement_Data[this.DataSourceIndex]["OD"] = this.Achivement["OD"];
			this._achivement_Data[this.DataSourceIndex]["TP"] = this.Achivement["TP"];
		}
		this.dataSource = new MatTableDataSource(this._achivement_Data);
		this._NewRewards = undefined;
		
	}

	Count_On_Change(_Count_On) {
		
		if (_Count_On.value == undefined) {
			this.Achivement_FG.controls['YearC_Id'].setValidators(null);
			this.Achivement_FG.controls['Year'].setValidators(null);
			this.Achivement_FG.controls['YearC_Id'].setValue('');
			this.Achivement_FG.controls['Year'].setValue('');
			this.Achivement_FG.controls['YearC_Id'].disable();
			this.Achivement_FG.controls['Year'].disable();
			this.CountOn_Val = 0;
		}
		else {
			this.Achivement_FG.controls['YearC_Id'].setValidators([Validators.required]);
			this.Achivement_FG.controls['Year'].setValidators([Validators.required]);
			this.Achivement_FG.controls['YearC_Id'].setValue('');
			this.Achivement_FG.controls['Year'].setValue('');
			this.Achivement_FG.controls['YearC_Id'].enable();
			this.Achivement_FG.controls['Year'].enable();
			this.CountOn_Val = _Count_On.value;
		}
		
	}

	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	getTitle(): string {
		// if (this.RewardsMdl.CRaw_Version > 0) {
		if (this.IsReadOnly == true && this.Is_History == 0) {
			this.Achivement_FG.controls['Year'].disable();
			this.Achivement_FG.controls['OD'].disable();
			this.Achivement_FG.controls['TP'].disable();
			return 'View Achivement';
		}
		// 	else if (this.IsReadOnly == false) {
		// 		return `Edit Master '${this.RewardsMdl.Insurer}'`;
		// 	}
		// }
		else if (this.Is_History == 0) {
			this.Achivement_FG.controls['Year'].enable();
			this.Achivement_FG.controls['OD'].enable();
			this.Achivement_FG.controls['TP'].enable();
			return 'Edit Achivement';
		}
		else if (this.Is_History == 1) {
			return 'History Achivement';
		}
		return 'New Achivement';
	}

	prepareMaster(): Motor_OR_Master {
		const _Master = new Motor_OR_Master();
		// _Master.id = this.RewardsMdl.id;				
		return _Master;
	}
	Cancel_Achivement() {
		this._NewRewards = undefined;
	}
	New_Achivement() {
		
		this.Is_New = 1;
		this.Achivement_FG.controls['YearC_Id'].setValidators(null);
		this.Achivement_FG.controls['Year'].setValidators(null);
		this.Edit_Achivement = false;
		this.DataSourceIndex = null;
		this._id = 0;
		this._OR_Master_Id = this.data.Sel_rewards_Data.id;
		this._CRaw_Version = 0;
		this.Achivement_FG.controls['Achivement_For_Id'].setValue('');
		this.Achivement_FG.controls['Count_On_Id'].setValue('');
		this.Achivement_FG.controls['YearC_Id'].setValue('');
		this.Achivement_FG.controls['YearC_Id'].disable();
		this.Achivement_FG.controls['Year'].setValue('');
		this.Achivement_FG.controls['Year'].disable();
		this.Achivement_FG.controls['OD'].setValue('0');
		this.Achivement_FG.controls['TP'].setValue('0');
		this._NewRewards = 1;
		this.CountOn_Val = 0;
		
	}
	onSubmit() {
		// this.hasFormErrors = false;

		// const User_Controls = this.Achivement_FG.controls;
		// if (this.Achivement_FG.invalid) {
		// 	Object.keys(User_Controls).forEach(controlName =>
		// 		User_Controls[controlName].markAsTouched()
		// 	);

		// 	this.hasFormErrors = true;
		// 	return;
		// }
		this.dialogRef.close({ _achivement_Data: this._achivement_Data, Removed_achivement_Data: this.Removed_achivement_Data });
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
		this.hasExists = false;
	}

	EditAchivements(SelCondition: Motor_MAchivements, index) {
		
		this.Is_New = 0;
		this.Edit_Achivement = true;
		this.Edit_Row_No = index;
		this.DataSourceIndex = index;
		this._id = SelCondition.id;
		this._OR_Master_Id = SelCondition.OR_Master_Id;
		this._CRaw_Version = SelCondition.CRaw_Version;
		this.Achivement_FG.controls['Achivement_For_Id'].setValue(SelCondition.Achivement_For_Id);
		this.Achivement_FG.controls['Count_On_Id'].setValue(SelCondition.Count_On_Id);
		if (SelCondition.Count_On_Id > 0) {
			this.Achivement_FG.controls['YearC_Id'].setValidators([Validators.required]);
			this.Achivement_FG.controls['Year'].setValidators([Validators.required]);
			this.Achivement_FG.controls['YearC_Id'].setValue(SelCondition.YearC_Id);
			this.Achivement_FG.controls['YearC_Id'].enable();
			this.Achivement_FG.controls['Year'].setValue(SelCondition.Year);
			this.Achivement_FG.controls['Year'].enable();
			this.CountOn_Val = SelCondition.Count_On_Id;
		}
		else {
			this.Achivement_FG.controls['YearC_Id'].setValidators(null);
			this.Achivement_FG.controls['Year'].setValidators(null);
			this.Achivement_FG.controls['YearC_Id'].setValue('');
			this.Achivement_FG.controls['YearC_Id'].disable();
			this.Achivement_FG.controls['Year'].setValue('');
			this.Achivement_FG.controls['Year'].disable();
			this.CountOn_Val = 0;
		}
		this.Achivement_FG.controls['OD'].setValue(SelCondition.OD);
		this.Achivement_FG.controls['TP'].setValue(SelCondition.TP);
		this._NewRewards = 1;
		
	}

	unassing(SelCondition: Motor_OR_Master, index) {
		
		this.Achivement = [];

		for (let i = 0; i < this._achivement_Data.length; i++) {
			if (index == i) {
				this.Achivement = {
					id: this._achivement_Data[index]["id"],
					OR_Master_Id: this._achivement_Data[index]["OR_Master_Id"],
					CRaw_Version: this._achivement_Data[index]["CRaw_Version"],
					Achivement_For_Id: this._achivement_Data[index]["Achivement_For_Id"],
					Achivement_For: this._achivement_Data[index]["Achivement_For"],
					Count_On_Id: this._achivement_Data[index]["Count_On_Id"],
					Count_On: this._achivement_Data[index]["Count_On"],
					YearC_Id: this._achivement_Data[index]["YearC_Id"],
					YearC: this._achivement_Data[index]["YearC"],
					Year: this._achivement_Data[index]["Year"],
					OD: this._achivement_Data[index]["OD"],
					TP: this._achivement_Data[index]["TP"],
				};
				this._achivement_Data.splice(index, 1);
			}
		}
		this.Removed_achivement_Data.push(this.Achivement as any);
		this.dataSource = new MatTableDataSource(this._achivement_Data);
		
	}
}
