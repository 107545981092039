// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'We have successfully sent you the password reset mail on your registered email.'
			},
			RESETPASSWORD: {
				TITLE: 'Reset Password',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'We have successfully reset password.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				USERID: 'User Id',
				OTP: 'OTP',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				
				NOT_FOUND: 'The requested {{name}} is not found',
				RESET_NOT_SUCCESS: 'Please Contact to admin',
				INVALID_LOGIN: 'The login detail is incorrect',
				INVALID_LOGIN_OTP: 'The OTP is incorrect',
				INVALID_RESEND_OTP: 'OTP not sent successfully',
				SUCCESS_RESEND_OTP: 'OTP sent successfully on registered mobile and email',
				MIN_LOGIN_PIN: 'Minimum 6 digit Login PIN required',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				PASSWORD_PATTERN: 'Password should contain At least 1 Uppercase,1 Lowercase, 1 Number and 1 Special	Character',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
				INVALID_EMAIL: 'Email is not valid',
			}
		},
		COMMON:{
			COMMON:{
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ERROR_MESSAGE: 'System error. Please contact to administrator.',

				INACTIVE_SINGLE: {
					TITLE: '{{name}} InActive',
					DESCRIPTION: 'Are you sure to inactive this {{name}}?',
					WAIT_DESCRIPTION: '{{name}} is updating. Please wait...',
					SUCCESS_MESSAGE: '{{name}} has been inactive successfully.',
					NOT_SAVED_MESSAGE: '{{name}} has not update successfully.',
					USER_SAVED_MESSAGE: '{{name}} has been uppdate by another user. Please try again...'
				},
				ACTIVE_SINGLE: {
					TITLE: '{{name}} Active',
					DESCRIPTION: 'Are you sure to Active this {{name}}?',
					WAIT_DESCRIPTION: '{{name}} is updating. Please wait...',
					SUCCESS_MESSAGE: '{{name}} has been active successfully.',
					NOT_SAVED_MESSAGE: '{{name}} has not update successfully.',
					USER_SAVED_MESSAGE: '{{name}} has been uppdate by another user. Please try again...'
				},
				INACTIVE_MULTY: {
					TITLE: 'Selected {{name}}',
					DESCRIPTION: 'Status of selected {{name}}',
					WAIT_DESCRIPTION: '{{name}} is updating. Please wait...',					
					SUCCESS_MESSAGE: 'Selected {{name}} status have been inactive successfully.',
					NOT_SAVED_MESSAGE: 'Selected {{name}} have not update successfully.',
					NOT_ALL_SAVED_MESSAGE: 'Selected {{number}} : {{name}} have been inactive successfully and remaining {{name}} are updated by antother user.'
				},
				ACTIVE_MULTY: {
					TITLE: 'Selected {{name}}',
					DESCRIPTION: 'Are you sure to Active this {{name}}?',
					WAIT_DESCRIPTION: '{{name}} is updating. Please wait...',
					SUCCESS_MESSAGE: 'Selected {{name}} status have been active successfully.',
					NOT_SAVED_MESSAGE: 'Selected {{name}} have not update successfully.',
					NOT_ALL_SAVED_MESSAGE: 'Selected {{number}} : {{name}} have been active successfully and remaining {{name}} are updated by antother user.'
				},
				UPDATE_RECORD: {
					TITLE: 'Status has been update for selected {{name}}',
					WAIT_DESCRIPTION: '{{name}} is updating. Please wait...',					
					SUCCESS_MESSAGE: '{{name}} has been update successfully.',
					NOT_SAVED_MESSAGE: '{{name}} has not update successfully.',
					USER_SAVED_MESSAGE: '{{name}} has been uppdate by another user. Please try again...'
				},
				NEW_RECORD: {
					TITLE: 'Status has been update for selected {{name}}',
					WAIT_DESCRIPTION: '{{name}} is creating. Please wait...',
					SUCCESS_MESSAGE: '{{name}} has been create successfully.',
					NOT_SAVED_MESSAGE: '{{name}} has not create successfully.'
				}
			}
		},


		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer InActive',
					DESCRIPTION: 'Are you sure to permanently InActive this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers InActive',
					DESCRIPTION: 'Are you sure to permanently InActive selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		}
	}
};
