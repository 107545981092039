
// Models and Consts
export { DepartmentModel } from './_models/department.model';
// DataSources
export { DepartmentsDataSource } from './_data-sources/departments.datasource';

// Actions
// Department Actions =>
export {
    DepartmentActionTypes,
    DepartmentActions,
    DepartmentsPageRequested,
    DepartmentsPageLoaded,
    DepartmentsPageToggleLoading
} from './_actions/department.actions';

// Effects
export { DepartmentEffects } from './_effects/department.effects';

// Reducers
export { departmentsReducer } from './_reducers/department.reducers';

// Selectors
// Department selectors =>
export {
    selectDepartmentById,
    selectDepartmentsInStore,
    selectDepartmentsPageLoading,
    selectDepartmentsActionLoading,
    selectDepartmentsShowInitWaitingMessage
} from './_selectors/department.selectors';

