import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { Health_OR_Upload_Mdl, HealthORUploadMdl, Health_OR_Upload_Status_Mdl } from '../_models/health-upload.model';

@Injectable()
export class HealthUpdateService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }	

	Get_Health_Upload_Grid_List(){
		return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthUpload/Get_Health_Upload_Grid_List')		
	}

	Get_Edit_Data(Insurer_Id:number,User_Id:string): Observable<Health_OR_Upload_Mdl[]> {
		return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthUpload/Get_Health_Upload_Edit_List/' + Insurer_Id + '/' + User_Id)
	}

	Save_Health_Upload_Data(_Master: HealthORUploadMdl) {		
		return this.http.post(Base_Url + 'HealthUpload/Save_Health_Upload', _Master);
	}

	Save_Temp_Excel(formData,File_Name) {		
		return this.http.post<any>(Base_Url + 'HealthUpload/Save_Temp_Excel/'+File_Name, formData);
	}

	Delete_Health_Upload_Data(_Master: Health_OR_Upload_Status_Mdl[]) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthUpload/Delete_Health_Upload_Data', _Master);
	}
}
