// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
import { Motor_OR_Master } from '../_models/motor-master.model';
// Models


export enum MotorMasterActionTypes {
    MotorMasterPageRequested = '[MotorMaster List Page] MotorMaster Page Requested',        
    MotorMasterPageLoaded = '[MotorMaster API] MotorMaster Page Loaded',
    MotorMasterPageToggleLoading = '[MotorMaster] MotorMaster Page Toggle Loading',
    MotorMasterActionToggleLoading = '[MotorMaster] MotorMaster Action Toggle Loading',
    AllInsurerRequested = '[Init] All Insurer MotorMaster Requested',
    AllInsurerLoaded = '[Init] All Insurer MotorMaster Loaded',
    AllInsuranceRequested = '[Init] All Insurance MotorMaster Requested',
    AllInsuranceLoaded = '[Init] All Insurance MotorMaster Loaded'
}

export class MotorMasterPageRequested implements Action {
    readonly type = MotorMasterActionTypes.MotorMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class MotorMasterPageLoaded implements Action {
    readonly type = MotorMasterActionTypes.MotorMasterPageLoaded;
    constructor(public payload: { _MotorMaster: Motor_OR_Master[], totalCount: number, page: QueryParamsModel }) { }
}

export class MotorMasterPageToggleLoading implements Action {
    readonly type = MotorMasterActionTypes.MotorMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class MotorMasterActionToggleLoading implements Action {
    readonly type = MotorMasterActionTypes.MotorMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllInsurerRequested implements Action {
    readonly type = MotorMasterActionTypes.AllInsurerRequested;
}

export class AllInsurerLoaded implements Action {
    readonly type = MotorMasterActionTypes.AllInsurerLoaded;
    constructor(public payload: { _MotorMaster: Motor_OR_Master[] }) { }
}

export class AllInsuranceRequested implements Action {
    readonly type = MotorMasterActionTypes.AllInsuranceRequested;
}

export class AllInsuranceLoaded implements Action {
    readonly type = MotorMasterActionTypes.AllInsuranceLoaded;
    constructor(public payload: { _MotorMaster: Motor_OR_Master[] }) { }
}

export type MotorMasterActions = AllInsurerRequested
| AllInsurerLoaded
| AllInsuranceRequested
| AllInsuranceLoaded
| MotorMasterPageRequested
| MotorMasterPageLoaded
| MotorMasterPageToggleLoading
| MotorMasterActionToggleLoading;
