import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Health_OR_Master, HealthMasterStatusModel, HealthORMaster } from '../_models/health-master.model';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { HealthReportMdl } from '../_models/health-report.model';

@Injectable()
export class HealthReportService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	
	PendingHealthReport(Report_Filter: HealthReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthReport/Pending', Report_Filter);
	}

	UpcomingHealthReport(Report_Filter: HealthReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthReport/Upcoming', Report_Filter);
	}

	ReceivedHealthReport(Report_Filter: HealthReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthReport/Received', Report_Filter);
	}

	SummaryHealthReport(Report_Filter: HealthReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthReport/Summary', Report_Filter);
	}

	SummaryDlgHealthReport(Report_Filter: HealthReportMdl) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'HealthReport/SummaryDlg', Report_Filter);
	}
}
