// NGRX
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '../../../environments/environment';

// tslint:disable-next-line:no-empty-interface
export interface AppState { }

export const reducers: ActionReducerMap<AppState> = { router: routerReducer };


export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];



const entityMetadata: StoreModule = {
    Hero: {},
    Villain: {}
  };
   
  // because the plural of "hero" is not "heros"
  const pluralNames = { Hero: 'Heroes' };
   
  export const entityConfig = {
    entityMetadata,
    pluralNames
  };
