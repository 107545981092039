import { BaseModel } from '../../_base/crud';
export class HealthORMaster extends BaseModel {	
	Insurer_Id: number;
	User_Id: string;
	Master_Data: Health_OR_Master[];
	Removed_Rewards:Health_OR_Master[];
}

export class Health_OR_Master extends BaseModel {
	id: number;

	Is_Read_Only: boolean;
	Is_Not_Removable: boolean;
	Is_Not_Active: boolean;

	Insurer_Id: number;
	Insurer: string;

	Effect_Date: string;
	INS_Policy_Type_Id: number[];	
	INS_Policy_Type: string;
	Policy_Type_Id: number[];
	Policy_Type: string;
	Capping_Id: number;
	Capping: string;

	Health_MAchivements: Health_MAchivements[];
	Removed_MAchivements: Health_MAchivements[];

	status: number;
	User_Id: string;
	CRaw_Version: number;

	clear() {
		this.id = undefined;
		
		this.Is_Read_Only = false;
		this.Is_Not_Removable = false;
		this.Is_Not_Active = false;

		this.Insurer_Id = undefined;
		this.Insurer = '';
		
		this.Effect_Date = '';
		this.INS_Policy_Type_Id = [];
		this.INS_Policy_Type = '';
		this.Policy_Type_Id = [];
		this.Policy_Type = '';
		this.Capping_Id = 0;
		this.Capping = '';

		this.Health_MAchivements = [];
		this.Removed_MAchivements=[];
		this.status = 1;
		
		this.User_Id = undefined;
		this.CRaw_Version = 0;
	}
}

export class Health_MAchivements extends BaseModel {	
	Achivement_For_Id: number;
	Achivement_For: string;
	Premium: string;
	CRaw_Version: number;
	OR_Master_Id:number;
	id:number;
	clear() {
		this.Achivement_For_Id = 0;
		this.Achivement_For = '';
		this.Premium = '';
		this.CRaw_Version = 0;
		this.OR_Master_Id = undefined;
		this.id = undefined;
	}
}

export class HealthMasterStatusModel extends BaseModel {
	Status: number;
	CRaw_Version: number;
	User_Id: string;
	clear() {
		this.Status = 0;
		this.CRaw_Version = 0;
		this.User_Id = undefined;
	}
}