import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { DepartmentModel } from '../../../../../core/master';
import { DepartmentActionToggleLoading } from '../../../../../core/master/_actions/department.actions';
import { each } from 'lodash';
import { DepartmentsService } from '../../../../../core/master/_services/departments.service';
import { AuthService, currentUser, Logout } from '../../../../../core/auth';
import { UsersService } from '../../../../../core/auth/_services/user.service';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { MotorMasterService } from '../../../../../core/motor/_services/motor-master.service';
import { MotorUpdateService } from '../../../../../core/motor/_services/motor-update.service';

@Component({
	selector: 'kt-active-inactive-multy-entity-dialog',
	templateUrl: './active-inactive-multy-entity-dialog.component.html'
})
export class ActiveInActiveMultyEntityDialogComponent implements OnInit {
	User_Id : string;
	selectedStatusForUpdate = new FormControl('');
	showActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: false });
	selection = new SelectionModel<DepartmentModel>(true, []);
	componentSubscriptions: Subscription;
	viewLoading = false;
	loadingAfterSubmit = false;
	constructor(
		private store: Store<AppState>,
		private Srv_Role: AuthService,
		private Srv_User: UsersService,
		private Srv_MMaster: MotorMasterService,
		private Srv_Department: DepartmentsService,
		private Srv_MUpdate: MotorUpdateService,
		public dialogRef: MatDialogRef<ActiveInActiveMultyEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	updateStatus() {
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		if (this.data.Page_Name == 'Department') {
			const _department = [];
			if (this.data.Rec_Status == 'InActive') {
				this.data.messages.forEach(elem => {
					_department.push({
						Status: 0,
						Raw_Version: elem.Raw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				this.data.messages.forEach(elem => {
					_department.push({
						Status: 1,
						Raw_Version: elem.Raw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_Department.updateStatusDepartment(_department).subscribe(res => {
				this.dialogRef.close({Rtn_Result : res, Selected_Row : _department.length});
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'Role') {
			const _role = [];
			if (this.data.Rec_Status == 'InActive') {
				this.data.messages.forEach(elem => {
					_role.push({
						Status: 0,
						Raw_Version: elem.Raw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				this.data.messages.forEach(elem => {
					_role.push({
						Status: 1,
						Raw_Version: elem.Raw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_Role.updateStatusRole(_role).subscribe(res => {
				this.dialogRef.close({Rtn_Result : res, Selected_Row : _role.length});
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'User') {
			const _user = [];
			if (this.data.Rec_Status == 'InActive') {
				this.data.messages.forEach(elem => {
					_user.push({
						Status: 0,
						CRaw_Version: elem.CRaw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				this.data.messages.forEach(elem => {
					_user.push({
						Status: 1,
						CRaw_Version: elem.CRaw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_User.updateStatusUser(_user).subscribe(res => {
				this.dialogRef.close({Rtn_Result : res, Selected_Row : _user.length});
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'MotorMaster') {
			const _mMaster = [];
			if (this.data.Rec_Status == 'InActive') {
				this.data.messages.forEach(elem => {
					_mMaster.push({
						Status: 0,
						CRaw_Version: elem.CRaw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				this.data.messages.forEach(elem => {
					_mMaster.push({
						Status: 1,
						CRaw_Version: elem.CRaw_Version.toString(),
						User_Id:this.User_Id
					});
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_MMaster.updateStatusMaster(_mMaster).subscribe(res => {
				this.dialogRef.close({Rtn_Result : res, Selected_Row : _mMaster.length});
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'Uploaded Excel') {
			const _mMaster = [];
			if (this.data.Rec_Status == 'InActive') {
				this.data.messages.forEach(elem => {
					_mMaster.push({
						Status: 0,
						Insurer_Id: elem.id,
						User_Id:this.User_Id
					});
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				this.data.messages.forEach(elem => {
					_mMaster.push({
						Status: 1,
						Insurer_Id: elem.id,
						User_Id:this.User_Id
					});
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_MUpdate.Delete_Motor_Upload_Data(_mMaster).subscribe(res => {
				this.dialogRef.close({Rtn_Result : res, Selected_Row : _mMaster.length});
			});
			this.hideActionLoadingDistpatcher;
		}
		else
			this.dialogRef.close(true);
	}
}
