import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-OR-upload-master',
  templateUrl: './OR-upload-master.component.html'
})
export class ORUploadMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
