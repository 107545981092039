// Angular
import { Component, OnInit, Input } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// Lodash
import { each, find, remove } from 'lodash';
// State
import { AppState } from '../../../../../../core/reducers';
// Auth
import { RoleModel, selectAllRoles, DepartmentModel, selectAllDepartments } from '../../../../../../core/auth';
import { MatDialog } from '@angular/material';
import { UserPermissionDialogComponent } from '../user-permission/user-permission.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-user-roles-list',
	templateUrl: './user-roles-list.component.html'
})
export class UserRoleListComponent implements OnInit {
	// Public properties
	// Incoming data
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);

	@Input() rolesSubject: BehaviorSubject<number[]>;
	@Input() departmentsSubject: BehaviorSubject<number[]>;

	// Roles
	allUserRoles$: Observable<RoleModel[]>;
	allRoles: RoleModel[] = [];
	unassignedRoles: RoleModel[] = [];
	assignedRoles: RoleModel[] = [];
	roleIdForAdding: number;

	// allDepartments$: Observable<DepartmentModel[]>;
	allDepartments: DepartmentModel[] = [];
	unassignedDepartments: DepartmentModel[] = [];
	assignedDepartments: DepartmentModel[] = [];
	departmentIdForAdding: number;

	constructor(private store: Store<AppState>,
		public dialog: MatDialog,
		private spinner: NgxSpinnerService) { }

	ngOnInit() {
		
		this.allUserRoles$ = this.store.pipe(select(selectAllRoles));
		this.allUserRoles$.subscribe((res: RoleModel[]) => {
			each(res.filter(el => el.status == 1), (_role: RoleModel) => {
				const departmemt = {
					id: _role.Department_Id,
					Department_Name: _role.Department_Name
				}
				var isExistsDepartment: boolean = false;
				each(this.allDepartments.filter(el => el.id == departmemt.id), (_department: DepartmentModel) => {
					isExistsDepartment = true;
				});

				if (isExistsDepartment == false) {
					this.allDepartments.push(departmemt as DepartmentModel);
					this.unassignedDepartments.push(departmemt as DepartmentModel);
				}
			});


			each(this.rolesSubject.value, (roleId: number) => {
				const role = find(res.filter(el => el.status == 1), (_role: RoleModel) => {
					return _role.id === roleId;
				});

				if (role) {
					const departmemt = {
						id: role.Department_Id,
						Department_Name: role.Department_Name
					}
					var isExistsDepartment: boolean = false;
					each(this.assignedDepartments.filter(el => el.id == departmemt.id), (_department: DepartmentModel) => {
						isExistsDepartment = true;
					});

					if (isExistsDepartment == false) {
						this.assignedDepartments.push(departmemt as DepartmentModel);
					}
					remove(this.unassignedDepartments, (el) => el.id === role.Department_Id);
				}
			});
		});
		
	}

	on_Department_Change(_DepartmentId) {
		
		this.allRoles = [];
		this.assignedRoles = [];
		this.unassignedRoles = [];

		this.allUserRoles$ = this.store.pipe(select(selectAllRoles));
		this.allUserRoles$.subscribe((res: RoleModel[]) => {
			each(res.filter(el => el.Department_Id == _DepartmentId.value && el.status == 1), (_role: RoleModel) => {
				this.allRoles.push(_role);
				this.unassignedRoles.push(_role);
			});

			each(this.rolesSubject.value, (roleId: number) => {
				const role = find(this.allRoles, (_role: RoleModel) => {
					return _role.id === roleId;
				});

				if (role) {
					this.assignedRoles.push(role);
					remove(this.unassignedRoles, (el) => el.Department_Id === role.Department_Id);
				}
			});
		});
		
	}

	assing() {
		
		if (this.departmentIdForAdding == 0 || this.roleIdForAdding == 0) {
			return;
		}
		const role = find(this.allRoles, (_role: RoleModel) => {
			_role.Department_Id === (+this.departmentIdForAdding);
			return _role.id === (+this.roleIdForAdding);
		});

		if (role) {
			const departmemt = {
				id: role.Department_Id,
				Department_Name: role.Department_Name
			}
			var isExistsDepartment: boolean = false;
			each(this.assignedDepartments.filter(el => el.id == departmemt.id), (_department: DepartmentModel) => {
				isExistsDepartment = true;
			});

			if (isExistsDepartment == false) {
				this.assignedDepartments.push(departmemt as DepartmentModel);
			}
			remove(this.unassignedDepartments, (el) => el.id === departmemt.id);

			this.assignedRoles.push(role);
			remove(this.unassignedRoles, (el) => el.Department_Id === role.Department_Id);
			this.roleIdForAdding = 0;
			this.departmentIdForAdding = 0;
			this.update();
		}
		
	}
	permissions(role: RoleModel) {
		const dialogRef = this.dialog.open(UserPermissionDialogComponent, { data: role });
	}

	unassing(role: RoleModel) {
		
		const departmemt = {
			id: role.Department_Id,
			Department_Name: role.Department_Name
		}
		var isExistsDepartment: boolean = false;
		each(this.unassignedDepartments.filter(el => el.id == departmemt.id), (_department: DepartmentModel) => {
			isExistsDepartment = true;
		});

		if (isExistsDepartment == false) {
			this.unassignedDepartments.push(departmemt as DepartmentModel);
		}
		remove(this.assignedDepartments, (el) => el.id === departmemt.id);


		this.roleIdForAdding = 0;
		this.departmentIdForAdding = 0;

		// each(this.allRoles.filter(el => el.Department_Id == role.Department_Id), (_role: RoleModel) => {
		// 	this.unassignedRoles.push(_role);
		// });


		remove(this.assignedRoles, el => el.Department_Id === role.Department_Id);
		this.update();
		
	}

	update() {
		
		const _roles = [];
		each(this.assignedRoles, elem => _roles.push(elem.id));
		this.rolesSubject.next(_roles);
		
	}
}
