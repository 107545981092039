// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { AuthService } from '../_services';
// Actions
import {
    AllUserPermissionsLoaded,
    AllUserPermissionsRequested,
    UserPermissionActionTypes
} from '../_actions/user_permission.actions';
// Models
import { UserPermissionModel } from '../_models/user_permission.model';

@Injectable()
export class UserPermissionEffects {
    @Effect()
    loadAllUserPermissions$ = this.actions$
        .pipe(
            ofType<AllUserPermissionsRequested>(UserPermissionActionTypes.AllUserPermissionsRequested),
            mergeMap(() => this.auth.getAllUserPermissions()),
            map((result: UserPermissionModel[]) => {
                return  new AllUserPermissionsLoaded({
                    UserPermission: result
                });
            })
          );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllUserPermissionsRequested());
    });

    constructor(private actions$: Actions, private auth: AuthService) { }
}
