// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { MasterModule } from './master/master.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { ORImportModule } from './OR-Import/OR-import.module';
import { ORMasterModule } from './OR-Master/OR-master.module';
import { ORUploadModule } from './OR-Upload/OR-upload.module';
import { ReportReceivedModule } from './Report-Received/report-received.module';
import { ReportSummaryModule } from './Report-Summary/report-summary.module';

@NgModule({
	declarations: [MyPageComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		MasterModule,
		UserManagementModule,	
		ORImportModule,
		ORMasterModule,
		ORUploadModule,
		ReportReceivedModule,
		ReportSummaryModule
	],
	providers: []
})
export class PagesModule {
}
