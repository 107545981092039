import { BaseModel } from '../../_base/crud';
export class Health_Import_OR extends BaseModel {
    Policy_Type_Id:string;
    Achivement_For_Id: string;
    Insurer_Id: string;
    Policy_From: string;
    Policy_To: string;
    Excel_Id: string;
    Sheet_Name: string;
    Payout_Month:string;
    Payout_Year:string;
    Health_OR_Excel_Heading: Health_OR_Excel_Heading[];
}

export class Health_OR_Save extends BaseModel {
    CRow_Version:string;
    Achivement_For: string;
}

export class Health_OR_Excel_Heading extends BaseModel {
    Row_Id: number;
    Exl_Heading: string;
}
