import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { RoleModel, RolesDataSource, RoleDeleted, RolesPageRequested, AuthService } from '../../../../../core/auth';
import { AppState } from '../../../../../core/reducers';
import { QueryParamsModel } from '../../../../../core/_base/crud';
import { RoleEditDialogComponent } from '../role-edit/role-edit.dialog.component';
import { RolePermissionModel } from '../../../../../core/auth/_models/role_permission.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-roles-list',
	templateUrl: './roles-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesListComponent implements OnInit, OnDestroy {
	RolePermissionMdl: RolePermissionModel[];
	dataSource: RolesDataSource;
	displayedColumns = ['select', 'actions', 'Department_Name', 'Role_Name', 'status'];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	filterStatus = '';
	selection = new SelectionModel<RoleModel>(true, []);
	ActiveSelection = '';
	InActiveSelection = '';
	rolesResult: RoleModel[] = [];
	private subscriptions: Subscription[] = [];

	constructor(
		private store: Store<AppState>,
		public dialog: MatDialog,
		private Svc_Auth: AuthService,
		private translate: TranslateService,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private spinner: NgxSpinnerService) { }

	ngOnInit() {
		
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadRolesList())
		)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);
		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadRolesList();
			})
		)
			.subscribe();
		this.subscriptions.push(searchSubscription);
		// Init DataSource
		this.dataSource = new RolesDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.rolesResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		this.loadRolesList();
		this.Load_Permission_List();
		
	}

	Load_Permission_List() {
		this.spinner.show();
		this.Svc_Auth.getAllRolePermissions().subscribe(_allPermission => {
			if (!_allPermission) {
				return;
			}
			else {
				this.RolePermissionMdl = [];
				this.RolePermissionMdl = _allPermission;
			}
			this.spinner.hide();
		});
		
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	loadRolesList() {
		
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		this.store.dispatch(new RolesPageRequested({ page: queryParams }));
		this.selection.clear();
		
	}

	filterConfiguration(): any {
		
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		if (this.filterStatus && this.filterStatus.length > 0) {
			filter.status = +this.filterStatus;
		}
		filter.Role_Name = searchText;
		filter.Department_Name = searchText;
		
		return filter;
	}

	Add_Edit_Role() {
		
		const newRole = new RoleModel();
		newRole.clear(); // Set all defaults fields
		this.AddEditRole(newRole, false);
		
	}

	AddEditRole(role: RoleModel, IsReadOnly) {
		
		let saveMessageTranslateParam = 'COMMON.COMMON.';
		let notsaveMessageTranslateParam = 'COMMON.COMMON.';
		saveMessageTranslateParam += role.Raw_Version > 0 ? 'UPDATE_RECORD.SUCCESS_MESSAGE' : 'NEW_RECORD.SUCCESS_MESSAGE';
		notsaveMessageTranslateParam += role.Raw_Version > 0 ? 'UPDATE_RECORD.NOT_SAVED_MESSAGE' : 'NEW_RECORD.NOT_SAVED_MESSAGE';
		const _saveMessage = this.translate.instant(saveMessageTranslateParam.toString(), { name: 'Role' });
		const _NotsaveMessage = this.translate.instant(notsaveMessageTranslateParam.toString(), { name: 'Role' });
		const dialogRef = this.dialog.open(RoleEditDialogComponent, { data: { role, IsReadOnly: IsReadOnly, AllPermissionList: this.RolePermissionMdl } });
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res > 0) {
				this.layoutUtilsService.showActionNotification("success", _saveMessage);
				this.loadRolesList();
			}
			else if (res == -1) {
				this.layoutUtilsService.showActionNotification("warn", "Record already exists hence it were not save");
				this.loadRolesList();
			  }
			else if (res == 0) {
				this.layoutUtilsService.showActionNotification("warn", _NotsaveMessage);
				this.loadRolesList();
			}
			else if (res == -9) {
				this.layoutUtilsService.showActionNotification("primary", this.translate.instant('COMMON.COMMON.UPDATE_RECORD.USER_SAVED_MESSAGE', { name: 'Role' }));
				this.loadRolesList();
			}
			else if (res == -10) {
				this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
				this.loadRolesList();
			}
			else
				return;
		});
		
	}

	Update_Status_Single(_role: RoleModel, _Action_For: string) {
		
		var _title: string;
		var _description: string;
		var _waitDesciption: string;
		var _SuccessMessage: string;
		var _Not_Saved_Message: string;
		var _User_Saved_Message: string;
		var _Btn_Color: string;

		if (_Action_For == 'Active') {
			_Btn_Color = 'success';
			_title = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.TITLE', { name: 'Role' });
			_description = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.DESCRIPTION', { name: 'role' });
			_waitDesciption = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Role' });
			_SuccessMessage = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Role' });
			_Not_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Role' });
			_User_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Role' });
		}
		else if (_Action_For == 'InActive') {
			_Btn_Color = 'warn';
			_title = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.TITLE', { name: 'Role' });
			_description = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.DESCRIPTION', { name: 'role' });
			_waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Role' });
			_SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Role' });
			_Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Role' });
			_User_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Role' });
		}
		const dialogRef = this.layoutUtilsService.RoleActiveInActiveSingleElement(_title, _description, _waitDesciption, _role, 'Role', _Action_For, _Btn_Color);
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res > 0) {
				this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
				this.loadRolesList();
			}
			else if (res == 0) {
				this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
				this.loadRolesList();
				return;
			}
			else if (res == -9) {
				this.layoutUtilsService.showActionNotification("primary", _User_Saved_Message);
				return;
			}
			else if (res == -10) {
				this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
				return;
			}
			else
				return;
		});
		
	}

	Update_Status_Multy(_Action_For: string) {
		
		var _title: string;
		var _waitDesciption: string;
		var _SuccessMessage: string;
		var _Not_Saved_Message: string;
		var _Not_All_Saved_Message: string;
		var _Btn_Color: string;
		const _statuses = [{ value: 1, text: 'Active' }, { value: 0, text: 'InActive' }];
		const _messages = [];
		if (_Action_For == 'Active') {
			this.selection.selected.forEach(elem => {
				if (elem.status == 0) {
					_messages.push({
						text: `${elem.Department_Name} - ${elem.Role_Name}`,
						Raw_Version: elem.Raw_Version.toString(),
						status: elem.status,
						statusTitle: this.getItemStatusString(elem.status),
						statusCssClass: this.getItemCssClassByStatus(elem.status)
					});
				}
			});
		}
		else if (_Action_For == 'InActive') {
			this.selection.selected.forEach(elem => {
				if (elem.status == 1) {
					_messages.push({
						text: `${elem.Department_Name} - ${elem.Role_Name}`,
						Raw_Version: elem.Raw_Version.toString(),
						status: elem.status,
						statusTitle: this.getItemStatusString(elem.status),
						statusCssClass: this.getItemCssClassByStatus(elem.status)
					});
				}
			});
		}
		if (_Action_For == 'Active') {
			_Btn_Color = 'success';
			_title = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.TITLE', { name: 'Roles' });
			_SuccessMessage = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.SUCCESS_MESSAGE', { name: 'Roles' });
			_Not_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.NOT_SAVED_MESSAGE', { name: 'Roles' });
			_Not_All_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.NOT_ALL_SAVED_MESSAGE', { name: 'Roles' });
			_waitDesciption = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.WAIT_DESCRIPTION', { name: 'Roles' });
		}
		else if (_Action_For == 'InActive') {
			_Btn_Color = 'warn';
			_title = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.TITLE', { name: 'Roles' });
			_SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.SUCCESS_MESSAGE', { name: 'Roles' });
			_Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_SAVED_MESSAGE', { name: 'Roles' });
			_Not_All_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_ALL_SAVED_MESSAGE', { name: 'Roles' });
			_waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.WAIT_DESCRIPTION', { name: 'Roles' });
		}
		const dialogRef = this.layoutUtilsService.RoleActiveInActiveMultyElement(_title, _waitDesciption, _statuses, _messages, 'Role', _Action_For, _Btn_Color);
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				if (res.Rtn_Result == 0) {
					this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
					this.loadRolesList();
					return;
				}
				else if (res.Rtn_Result == -10) {
					this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
					return;
				}
				else if (res.Rtn_Result == res.Selected_Row) {
					this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
					this.selection.clear();
					this.loadRolesList();
				}
				else if (res.Rtn_Result != res.Selected_Row) {
					this.layoutUtilsService.showActionNotification("primary", _Not_All_Saved_Message.replace('{{number}}', res.Rtn_Result));
					this.loadRolesList();
				}
			}
			else
				return;
		});
		
	}

	isAllSelected(): boolean {
		this.ActiveSelection = '';
		this.InActiveSelection = '';
		if (this.selection.selected.length > 0) {
			this.selection.selected.forEach(elem => {
				if (elem.status == 0)
					this.ActiveSelection = 'Active';
				else if (elem.status == 1)
					this.InActiveSelection = 'InActive';
			});
		}

		const numSelected = this.selection.selected.length;
		const numRows = this.rolesResult.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.ActiveSelection = '';
		this.InActiveSelection = '';
		if (this.selection.selected.length === this.rolesResult.length) {
			this.selection.clear();
		} else {
			this.rolesResult.forEach(row => this.selection.select(row));
		}
	}

	getItemCssClassByStatus(status: number = 0): string {
		switch (status) {
			case 0:
				return 'danger';
			case 1:
				return 'success';
		}
		return '';
	}

	getItemStatusString(status: number = 0): string {
		switch (status) {
			case 0:
				return 'InActive';
			case 1:
				return 'Active';
		}
		return '';
	}
}
