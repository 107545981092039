import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Subscription, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { UserPermissionModel, RoleModel, selectAllRolesAssigned, currentUser, Logout } from '../../../../../core/auth';
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { UserModel } from '../../../../../core/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { each, find, remove, isInteger, isNumber, toInteger } from 'lodash';
import { DepartmentModel } from '../../../../../core/master';
import { UsersService } from '../../../../../core/auth/_services/user.service';
import { SubheaderService } from '../../../../../core/_base/layout';
import { UserPermissionDialogComponent } from '../_subs/user-permission/user-permission.component';
import { NgbTimepickerConfig, NgbTimeAdapter, NgbTimepickerI18n, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { RolePermissionModel } from '../../../../../core/auth/_models/role_permission.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-user-edit-dialog',
	templateUrl: './user-edit.dialog.component.html',
	styleUrls: ['./user-edit.dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})

export class UserEditDialogComponent implements OnInit, OnDestroy {
	User_FG: FormGroup;
	Address_FG: FormGroup;
	allPermissions: RolePermissionModel[] = [];
	Auth_FG: FormGroup;
	User_Id: string;
	time = { hour: 13, minute: 30 };
	allUserRoles: Observable<RoleModel[]>;
	All_Roles: RoleModel[];
	allRoles: RoleModel[] = [];
	unassignedRoles: RoleModel[] = [];
	assignedRoles: RoleModel[] = [];
	assignedRolesPermission: number[] = [];
	roleIdForAdding: number;
	unassignedDepartments: DepartmentModel[] = [];
	departmentIdForAdding: number;
	selectedTab = 0;
	hasFormErrors = false;
	private componentSubscriptions: Subscription;
	IsReadOnly: boolean;

	AreaMdl: any[] = [];
	CityMdl: any[] = [];

	public areaCtrl: FormControl = new FormControl();
	public areaFilterCtrl: FormControl = new FormControl();
	public filteredAreas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	public cityCtrl: FormControl = new FormControl();
	public cityFilterCtrl: FormControl = new FormControl();
	public filteredCitys: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	private _onDestroy = new Subject<void>();

	viewLoading = false;
	user: UserModel;
	DepartmentModel: any;
	Genders = [
		{ id: 1, GenderName: 'Male' },
		{ id: 2, GenderName: 'Female' }
	];
	is_Login_With_OTP = [
		{ id: true, value: 'Yes' },
		{ id: false, value: 'No' }
	];
	is_On_Role = [
		{ id: true, value: 'Yes' },
		{ id: false, value: 'No' }
	];
	MaritalStatus = [
		{ id: 1, StatusName: 'Single' },
		{ id: 2, StatusName: 'Married' },
		{ id: 3, StatusName: 'Engaged' },
		{ id: 4, StatusName: 'Divorced' },
		{ id: 5, StatusName: 'Separated' }
	];
	Working_Days = [
		{ id: 1, DayName: 'Monday' },
		{ id: 2, DayName: 'Tuesday' },
		{ id: 3, DayName: 'Wednesday' },
		{ id: 4, DayName: 'Thursday' },
		{ id: 5, DayName: 'Friday' },
		{ id: 6, DayName: 'Saturday' },
		{ id: 7, DayName: 'Sunday' }
	];
	constructor(public dialogRef: MatDialogRef<UserEditDialogComponent>,
		private User_FB: FormBuilder,
		private Address_FB: FormBuilder,
		private Auth_FB: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private translate: TranslateService,
		public dialog: MatDialog,
		private Srv_User: UsersService,
		private store: Store<AppState>,
		private spinner: NgxSpinnerService) {
		this.user = new UserModel();
		this.IsReadOnly = data.IsReadOnly;
		this.AreaMdl = data.AreaMdl;
		this.CityMdl = data.CityMdl;
		this.allPermissions = data.All_Role_Wise_Permission_For_User;
		this.All_Roles = data.All_Roles;
		this.areaCtrl.setValue(this.AreaMdl);
		this.filteredAreas.next(this.AreaMdl.slice(0, 50));
		this.areaFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterAreas();
			});

		this.cityCtrl.setValue(this.CityMdl);
		this.filteredCitys.next(this.CityMdl.slice(0, 50));
		this.cityFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterCitys();
			});
	}
	private filterAreas() {
		if (!this.AreaMdl) {
			return;
		}
		let search = this.areaFilterCtrl.value;
		if (!search) {
			//this.filteredAreas.next(this.AreaMdl.slice(0, 50));
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredAreas.next(
			this.AreaMdl.filter(area => area.value.toLowerCase().indexOf(search) > -1).slice(0, 50)
		);
	}

	private filterCitys() {
		if (!this.CityMdl) {
			return;
		}
		let search = this.cityFilterCtrl.value;
		if (!search) {
			//this.filteredCitys.next(this.CityMdl.slice(0, 50));
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredCitys.next(
			this.CityMdl.filter(city => city.value.toLowerCase().indexOf(search) > -1).slice(0, 50)
		);
	}

	createForm() {
		this.User_FG = this.User_FB.group({
			User_Code: [this.user.First_Name, Validators.required],
			First_Name: [this.user.First_Name, Validators.required],
			Last_Name: [this.user.First_Name, Validators.required],
			DOB: [this.user.First_Name, Validators.required],
			DOJ: [this.user.First_Name, Validators.required],
			Gender: [this.user.First_Name, Validators.required]
		});
		this.Address_FG = this.Address_FB.group({
			Personal_Mobile: [this.user.First_Name, Validators.required],
			Personal_Mail: [this.user.First_Name, Validators.required]
		});
		this.Auth_FG = this.Auth_FB.group({
			Password: [this.user.First_Name, Validators.required],
			Is_Login_With_OTP: [this.user.First_Name, Validators.required],
			In_Time: [this.user.First_Name, Validators.required],
			Out_Time: [this.user.First_Name, Validators.required],
			Is_On_Role: [this.user.First_Name, Validators.required]
		});
	}
	initLoginForm() {
		// demo message to show
		// if (!this.authNoticeService.onNoticeChanged$.getValue()) {
		// 	const initialNotice = `Use account
		// 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
		// 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
		// 	this.authNoticeService.setNotice(initialNotice, 'info');
		// }

		this.User_FG = this.User_FB.group({
			User_Code: [null, Validators.required],
			First_Name: [null, Validators.required],
			Last_Name: [null, Validators.required],
			DOB: [null, Validators.required],
			DOJ: [null, Validators.required],
			Gender_Id: [null, Validators.required]
		});

		this.Address_FG = this.Address_FB.group({
			Personal_Mobile: [null, Validators.required],
			Personal_Mail: [null, Validators.required]
		});

		this.Auth_FG = this.Auth_FB.group({
			Is_Login_With_OTP: [null, Validators.required],
			In_Time: [null, Validators.required],
			Out_Time: [null, Validators.required],
			Is_On_Role: [null, Validators.required],
			Working_Days: [null, Validators.required],
			Password: [null, Validators.compose([
				Validators.required,
				Validators.pattern('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{0,}'),
				Validators.minLength(6),
				Validators.maxLength(20)
			])
			]
		});
	}

	ngOnInit() {
		
		this.initLoginForm();
		const id = this.data.user.id;
		if (id && id > 0) {
			this.spinner.show();
			this.Srv_User.GetUserDetailsById(id).subscribe(result => {
				if (result["response"][0]) {
					this.user = result["response"][0];
					this.Set_Area_City();
					//this.allUserRoles = this.store.pipe(select(selectAllRolesAssigned));					
					// this.allUserRoles.subscribe((res: RoleModel[]) => {
					// 	each(res, (_role: RoleModel) => {
					// 		this.allRoles.push(_role);
					// 	});
					this.allRoles = this.All_Roles;

					each(this.user.AssignedRoles as any, (UserRoleModel: any) => {
						const role = find(this.user.AssignedRoles.filter(el => el.status == 1), (_role: RoleModel) => {
							_role.Department_Id === UserRoleModel.Department_Id;
							return _role.id === UserRoleModel.id;
						});

						if (role) {
							this.assignedRoles.push(role);
						}
					});
					// });
					this.Set_Department_In_List();
				}
				this.spinner.hide();
				this.viewLoading = false;
			});
		} else {
			this.user = new UserModel();
			this.user.clear();
			//  this.allUserRoles = this.store.pipe(select(selectAllRolesAssigned));
			// 		this.allUserRoles.subscribe((res: RoleModel[]) => {
			// 			each(res, (_role: RoleModel) => {
			// 				this.allRoles.push(_role);
			// 			});
			this.allRoles = this.All_Roles;
			each(this.user.AssignedRoles as any, (UserRoleModel: any) => {
				const role = find(this.user.AssignedRoles.filter(el => el.status == 1), (_role: RoleModel) => {
					_role.Department_Id === UserRoleModel.Department_Id;
					return _role.id === UserRoleModel.id;
				});

				if (role) {
					this.assignedRoles.push(role);
				}
			});
			// });
			this.Set_Department_In_List();
		}
		
	}

	Set_Area_City() {
		
		this.areaCtrl.setValue(this.user.AreaMdl);
		this.filteredAreas.next(this.user.AreaMdl.slice(0, 50));
		this.areaFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterAreas();
			});
		this.areaCtrl.setValue(this.user.Area_Id);

		//---------------------

		this.cityCtrl.setValue(this.user.CityMdl);
		this.filteredCitys.next(this.user.CityMdl.slice(0, 50));
		this.cityFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterCitys();
			});
		this.cityCtrl.setValue(this.user.City_Town_Id);
		
	}

	City_Set_On_Edit(_CityId) {
		
		const distinct_City = this.CityMdl.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == _CityId) == i
		);
		this.filteredCitys.next(distinct_City.slice(0, 50));
		this.cityCtrl.setValue(distinct_City[0].id);
		
	}

	Area_Set_On_Edit(_AreaId) {
		
		const distinct_Area = this.AreaMdl.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == _AreaId) == i
		);
		this.filteredAreas.next(distinct_Area.slice(0, 50));
		this.areaCtrl.setValue(distinct_Area[0].id);
		
	}

	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	Set_Department_In_List() {
		
		each(this.allRoles, (_role: RoleModel) => {
			this.unassignedRoles.push(_role);
		});

		each(this.assignedRoles, (_role: RoleModel) => {
			remove(this.unassignedRoles, el => el.Department_Id === _role.Department_Id);
		});

		const distinctThings = this.unassignedRoles.filter(
			(thing, i, arr) => arr.findIndex(t => t.Department_Id === thing.Department_Id) === i
		);
		this.unassignedDepartments = distinctThings as any;
		this.departmentIdForAdding = 0;
		
	}
	on_Department_Change(_DepartmentId) {
		
		this.unassignedRoles = [];
		each(this.allRoles.filter(el => el.Department_Id == _DepartmentId.value), (_role: RoleModel) => {
			this.unassignedRoles.push(_role);
		});
		
	}
	on_Area_Change(_AreaId) {
		
		const Area = this.AreaMdl.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == _AreaId.value) == i
		);

		const distinctThings = this.CityMdl.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == Area[0].City_Town_Id) == i
		);
		this.filteredCitys.next(distinctThings.slice(0, 50));
		this.cityCtrl.setValue(distinctThings[0].id);
		
	}
	on_City_Change(_CityId) {
		
		const City = this.CityMdl.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == _CityId.value) == i
		);

		this.filteredAreas.next(
			this.AreaMdl.filter(area => area.City_Town_Id == City[0].id).slice(0, 50)
		);
		this.areaCtrl.setValue(0);
		
	}
	assing() {
		
		if (this.roleIdForAdding === 0) {
			return;
		}

		const role = find(this.allRoles, (_role: RoleModel) => {
			return _role.id === (+this.roleIdForAdding);
		});

		if (role) {
			this.assignedRoles.push(role);
			remove(this.unassignedRoles, (el) => el.Department_Id === role.Department_Id);
			this.roleIdForAdding = 0;
			this.updateRoles();
		}
		
	}
	unassing(role: RoleModel) {
		
		this.roleIdForAdding = 0;
		each(this.allRoles.filter(el => el.Department_Id == role.Department_Id), (_role: RoleModel) => {
			this.unassignedRoles.push(_role);
		});
		remove(this.assignedRoles, el => el.id === role.id);
		this.updateRoles();
		
	}

	updateRoles() {
		
		const _roles = [];
		each(this.assignedRoles, elem => _roles.push(elem.id));
		this.Set_Department_In_List();
		
	}
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.Address_FG.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	onSubmit() {
		
		this.hasFormErrors = false;

		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id

		// if(this.user.CRaw_Version>0)
		// {
		// 	this.User_FG=valid
		// }
		const User_Controls = this.User_FG.controls;
		if (this.User_FG.invalid) {
			Object.keys(User_Controls).forEach(controlName =>
				User_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const Address_Controls = this.Address_FG.controls;
		if (this.Address_FG.invalid) {
			Object.keys(Address_Controls).forEach(controlName =>
				Address_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 1;
			return;
		}

		const Auth_Controls = this.Auth_FG.controls;
		if (this.Auth_FG.invalid) {
			Object.keys(Auth_Controls).forEach(controlName =>
				Auth_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 3;
			return;
		}

		const editedUser = this.user;
		editedUser.User_Id = this.User_Id;
		// editedUser.Area_Id = this.areaCtrl.value;
		// editedUser.City_Town_Id = this.cityCtrl.value;
		editedUser.AssignedRoles = this.assignedRoles;
		if (editedUser.id > 0) {
			this.updateUser(editedUser);
			return;
		}
		this.createUser(editedUser);
		
	}

	updateUser(_user: UserModel) {
		
		this.viewLoading = true;
		this.spinner.show();
		this.Srv_User.updateUser(_user).subscribe(res => {
			this.viewLoading = false;
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
	}
	createUser(_user: UserModel) {
		
		this.viewLoading = true;
		this.spinner.show();
		this.Srv_User.createUser(_user).subscribe(res => {
			this.viewLoading = false;
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
	}

	getTitle(): string {
		if (this.data.user.CRaw_Version > 0) {
			if (this.IsReadOnly == true) {
				this.User_FG.controls['User_Code'].disable();
				this.User_FG.controls['First_Name'].disable();
				this.User_FG.controls['Last_Name'].disable();
				this.User_FG.controls['DOB'].disable();
				this.User_FG.controls['DOJ'].disable();

				this.Address_FG.controls['Personal_Mobile'].disable();
				this.Address_FG.controls['Personal_Mail'].disable();

				this.Auth_FG.controls['Password'].disable();
				this.Auth_FG.controls['In_Time'].disable();
				this.Auth_FG.controls['Out_Time'].disable();

				return `View user '${this.data.user.UserFullName}'`;
			}
			else if (this.IsReadOnly == false) {
				this.User_FG.controls['User_Code'].enable();
				this.User_FG.controls['First_Name'].enable();
				this.User_FG.controls['Last_Name'].enable();
				this.User_FG.controls['DOB'].enable();
				this.User_FG.controls['DOJ'].enable();

				this.Address_FG.controls['Personal_Mobile'].enable();
				this.Address_FG.controls['Personal_Mail'].enable();

				this.Auth_FG.controls['Password'].enable();
				this.Auth_FG.controls['In_Time'].enable();
				this.Auth_FG.controls['Out_Time'].enable();

				return `Edit user '${this.data.user.UserFullName}'`;
			}
		}
		return 'New user';
	}
	permissions(role: RoleModel, IsReadOnly: boolean) {
		
		const dialogRef = this.dialog.open(UserPermissionDialogComponent, { data: { role, IsReadOnly: this.IsReadOnly, All_Role_Wise_Permission_For_User: this.allPermissions } });
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				each(res.RolePermissionData, (_assignedRolesPermission: number) => {
					this.assignedRolesPermission.push(_assignedRolesPermission);
				});
				each(res, (_role: RoleModel) => {
					remove(this.assignedRoles, (el) => el.id === _role.id);
					this.assignedRoles.push(_role);
				});
			}
		});
		
	}

	getWaitDesciption() {
		if (this.user.CRaw_Version > 0) {
			return this.translate.instant('COMMON.COMMON.UPDATE_RECORD.WAIT_DESCRIPTION', { name: 'User' });
		}
		return this.translate.instant('COMMON.COMMON.NEW_RECORD.WAIT_DESCRIPTION', { name: 'User' });
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
