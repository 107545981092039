import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AuthNoticeService, AuthService, Login, currentUser, Logout, UserModel } from '../../../../../core/auth';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { AppState } from '../../../../../core/reducers';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'kt-login-pin',
  templateUrl: './login-pin.component.html',
  styleUrls: ['./login-pin.component.scss']
})
export class LoginPinComponent implements OnInit {
  Ntc_Login_Pin: boolean = true;
  CcurrentUser:any;
  // user$: Observable<UserModel>;
  OTP: string;
  authv:string;
  User_Id: string;
  loading = false;
  private returnUrl: any;
  private unsubscribe: Subject<any>;
  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private authNoticeService: AuthNoticeService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '40px',
      'height': '40px',
      'font-size': '18px'
    }
  };
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
    });
    
    // console.log(this.returnUrl);
    // const User_Id = localStorage.getItem(environment.User_Id);
		// if (!User_Id)
		// 	this.store.dispatch(new Logout());
		// else
		// 	this.User_Id = User_Id
  }
  On_Login_Pin_Change(OTP) {
    this.OTP = OTP;
    this.Ntc_Login_Pin = false;
  }
  Back_To_Login() {
    this.authNoticeService.setNotice(null);
    this.store.dispatch(new Logout());
  }
  Resend() {
    this.authNoticeService.setNotice(null);    
    const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
    this.loading = true;
    this.auth
      .Resend_OTP(this.User_Id)
      .pipe(
        tap(user => {
          if (user) {
            this.router.navigateByUrl('/auth/login-pin');
            this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.SUCCESS_RESEND_OTP'), 'success');
          } else {
            this.store.dispatch(new Login({ authToken: user.accessToken }));
            this.router.navigateByUrl(this.returnUrl);
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }
  submit() {    
    this.authNoticeService.setNotice(null);
    if (this.OTP.length < 6) {
      this.Ntc_Login_Pin = true;
      this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.MIN_LOGIN_PIN'), 'danger');
    }
    const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
    this.loading = true;
    this.auth
      .OTP_Authentication(this.User_Id, this.OTP)
      .pipe(
        tap(user => {
          if (user) {
            this.authv=user.accessToken;
            this.store.dispatch(new Login({ authToken: user.accessToken }));
            this.router.navigateByUrl('/dashboard'); // Main page
          } else {
            this.Ntc_Login_Pin = true;
            this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN_OTP'), 'danger');
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }
}
