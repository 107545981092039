export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					alignment: 'left',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD'
				},
				{
					title: 'App Master',
					root: true,
					alignment: 'left',
					toggle: 'click',
					permission: 'accessToAppMasterMenu',
					submenu: [
						{
							title: 'Master',
							bullet: 'dot',
							icon: 'flaticon-business',
							permission: 'accessToAppMasterMenu',
							submenu: [
								{
									title: 'Department',
									page: '/master/department',
									permission: 'accessToDepartmentPage',
								},
							]
						},
					]
				},
				{
					title: 'OR PROCESS',
					root: true,
					alignment: 'left',
					toggle: 'click',
					permission: 'accessToORProcessMenu',
					submenu: [
						{
							title: 'OR Process',
							bullet: 'dot',
							icon: 'flaticon-user',
							permission: 'accessToORProcessMenu',
							submenu: [
								{
									title: 'OR Master',
									page: '/ORProcessM/ORMaster',
									permission: 'accessToORMasterSubMenu'
								},
								{
									title: 'OR File Upload',
									page: '/ORProcessU/ORUpload',
									permission: 'accessToORUploadSubMenu'
								},
								{
									title: 'OR Import',
									page: '/ORProcessI/ORImport',
									permission: 'accessToORImportSubMenu'
								}
							]
						},
					]
				},
				{
					title: 'OR REPORTS',
					root: true,
					alignment: 'left',
					toggle: 'click',
					permission: 'accessToORReportMenu',
					submenu: [
						{
							title: 'OR Reports',
							bullet: 'dot',
							icon: 'flaticon-user',
							permission: 'accessToORReportMenu',
							submenu: [
								{
									title: 'OR Received',
									page: '/ORReportReceived/ReportReceived',
									permission: 'accessToORReceivedSubMenu'
								},
								{
									title: 'OR Pending',
									page: '/ORReportPending/ReportPending',
									permission: 'accessToORPendingSubMenu'
								},
								{
									title: 'OR Upcoming',
									page: '/ORReportUpcoming/ReportUpcoming',
									permission: 'accessToORUpcomingSubMenu'
								},
								{
									title: 'OR Summary',
									page: '/ORReportSummary/ReportSummary',
									permission: 'accessToORSummarySubMenu'
								},
								{
									title: 'OR Payout',
									page: '/ORReportPayout/ReportPayout',
									permission: 'accessToORPayoutSubMenu'
								}
							]
						},
					]
				},
				{
					title: 'Management',
					root: true,
					alignment: 'left',
					toggle: 'click',
					permission: 'accessToUserMenu',
					submenu: [
						{
							title: 'User Management',
							bullet: 'dot',
							icon: 'flaticon-user',
							permission: 'accessToUserMenu',
							submenu: [
								{
									title: 'Users',
									page: '/user-management/users',
									permission: 'accessToUserPage'
								},
								{
									title: 'Roles',
									page: '/user-management/roles',
									permission: 'accessToRolePage'
								}
							]
						},
					]
				},
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{
					permission: 'accessToAppMasterMenu',
					section: 'App Master'
				},
				{
					title: 'Master',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					root: true,
					permission: 'accessToAppMasterMenu',
					submenu: [
						{
							title: 'Department',
							page: '/master/department',
							permission: 'accessToDepartmentPage',
						},
					]
				},
				{
					permission: 'accessToORProcessMenu',
					section: 'OR PROCESS'
				},
				{
					title: 'OR Process',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					permission: 'accessToORProcessMenu',
					submenu: [
						{
							title: 'OR Master',
							page: '/ORProcessM/ORMaster',
							permission: 'accessToORMasterSubMenu'
						},
						{
							title: 'OR File Upload',
							page: '/ORProcessU/ORUpload',
							permission: 'accessToORUploadSubMenu'
						},
						{
							title: 'OR Import',
							page: '/ORProcessI/ORImport',
							permission: 'accessToORImportSubMenu'
						}
					]
				},
				{
					permission: 'accessToORReportMenu',
					section: 'OR REPORTS'
				},
				{
					title: 'OR Reports',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					permission: 'accessToORReportMenu',
					submenu: [
						{
							title: 'OR Received',
							page: '/ORReportReceived/ReportReceived',
							permission: 'accessToORReceivedSubMenu'
						},
						{
							title: 'OR Pending',
							page: '/ORReportPending/ReportPending',
							permission: 'accessToORPendingSubMenu'
						},
						{
							title: 'OR Upcoming',
							page: '/ORReportUpcoming/ReportUpcoming',
							permission: 'accessToORUpcomingSubMenu'
						},
						{
							title: 'OR Summary',
							page: '/ORReportSummary/ReportSummary',
							permission: 'accessToORSummarySubMenu'
						},
						{
							title: 'OR Payout',
							page: '/ORReportPayout/ReportPayout',
							permission: 'accessToORPayoutSubMenu'
						}
					]
				},
				{
					permission: 'accessToUserMenu',
					section: 'Management'
				},
				{
					title: 'User Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					permission: 'accessToUserMenu',
					submenu: [
						{
							title: 'Users',
							page: '/user-management/users',
							permission: 'accessToUserPage'
						},
						{
							title: 'Roles',
							page: '/user-management/roles',
							permission: 'accessToRolePage'
						}
					]
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
