import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription, of, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, selectAllUserPermissions, selectAllDepartments, AuthService } from '../../../../../core/auth';
import { TypesUtilsService, QueryResultsModel } from '../../../../../core/_base/crud';
import { RoleModel, selectRolesActionLoading } from '../../../../../core/auth';
import { Router } from '@angular/router';
// import { RoleActionToggleLoading } from '../../../../../core/master/_actions/role.actions';
import { TranslateService } from '@ngx-translate/core';
// import { RolesService } from '../../../../../core/master/_services/roles.service';
import { each, find, some } from 'lodash';
import { DepartmentModel, selectDepartmentById, selectDepartmentsInStore } from '../../../../../core/master';
import { RolePermissionModel } from '../../../../../core/auth/_models/role_permission.model';
import { environment } from '../../../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-role-edit-dialog',
	templateUrl: './role-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})

// export class SelectOverviewExample {
// 	foods = [
// 	  {value: 'steak-0', viewValue: 'Steak'},
// 	  {value: 'pizza-1', viewValue: 'Pizza'},
// 	  {value: 'tacos-2', viewValue: 'Tacos'}
// 	];
//   }

export class RoleEditDialogComponent implements OnInit, OnDestroy {
	// showActionLoadingDistpatcher = new RoleActionToggleLoading({ isLoading: true });
	// hideActionLoadingDistpatcher = new RoleActionToggleLoading({ isLoading: false });
	viewLoading = false;
	loadingAfterSubmit = false;
	role: RoleModel;
	DepartmentModel: any;
	setRole: RoleModel;
	User_Id: string;
	roleForm: FormGroup;
	hasFormErrors = false;
	private componentSubscriptions: Subscription;
	IsReadOnly: boolean;
	allDepartments: Observable<DepartmentModel[]>;

	allPermissions: RolePermissionModel[] = [];
	DeptWisePermissions: RolePermissionModel[] = [];
	rolePermissions: RolePermissionModel[] = [];



	constructor(public dialogRef: MatDialogRef<RoleEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private translate: TranslateService,
		private Srv_Role: AuthService,
		private router: Router,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private spinner: NgxSpinnerService) {
		this.IsReadOnly = data.IsReadOnly;
		this.allPermissions = data.AllPermissionList;
	}
	ngOnInit() {
		
		this.role = this.data.role;
		this.setRole = new RoleModel();
		this.setRole.RolePermissionData = this.role.RolePermissionData;
		this.setRole.Role_Name = this.role.Role_Name;
		if (this.role.Department_Id != undefined) {
			this.setRole.Department_Id = this.role.Department_Id.toString();
			this.loadPermissions(this.setRole.Department_Id);
		}
		this.allDepartments = this.store.pipe(select(selectAllDepartments));
		this.allDepartments.subscribe(_allDepartments => {
			if (!_allDepartments) {
				return;
			}
			else {
				this.DepartmentModel = [];
				this.DepartmentModel = _allDepartments;
			}
		});
		
		//  });
	}
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	loadPermissions(_DepartmentId) {
		
		if (!this.allPermissions) {
			return;
		}
		this.rolePermissions = [];
		this.DeptWisePermissions = [];
		each(this.allPermissions.filter(el => el.Department_Id == _DepartmentId), (_RolePermissions: RolePermissionModel) => {
			this.DeptWisePermissions.push(_RolePermissions);
		});

		if (!this.DeptWisePermissions) {
			return;
		}

		const mainPermissions = this.DeptWisePermissions.filter(el => !el.parentId);
		mainPermissions.forEach((element: RolePermissionModel) => {
			const hasUserPermission = this.setRole.RolePermissionData.some(t => t === element.id);
			const rootPermission = new RolePermissionModel();
			rootPermission.clear();
			rootPermission.isSelected = hasUserPermission;
			rootPermission._children = [];
			rootPermission.id = element.id;
			rootPermission.level = element.level;
			rootPermission.parentId = element.parentId;
			rootPermission.title = element.title;
			const children = this.DeptWisePermissions.filter(el => el.parentId && el.parentId === element.id);
			children.forEach(child => {
				const hasUserChildPermission = this.setRole.RolePermissionData.some(t => t === child.id);
				const childPermission = new RolePermissionModel();
				childPermission.clear();
				childPermission.isSelected = hasUserChildPermission;
				childPermission._children = [];
				childPermission.id = child.id;
				childPermission.level = child.level;
				childPermission.parentId = child.parentId;
				childPermission.title = child.title;
				rootPermission._children.push(childPermission);
			});
			this.rolePermissions.push(rootPermission);
		});
		
	}
	preparePermissionIds(): number[] {
		
		const result = [];
		each(this.rolePermissions, (_root: RolePermissionModel) => {
			if (_root.isSelected) {
				if (_root.id == 3) {
					result.push(1);
					result.push(2);
				}
				else if (_root.id == 11) {
					result.push(9);
					result.push(10);
				}
				else if (_root.id == 19) {
					result.push(17);
					result.push(18);
				}
				else if (_root.id == 27) {
					result.push(25);
					result.push(26);
				}
				else if (_root.id == 35) {
					result.push(33);
					result.push(34);
				}
				else if (_root.id == 43) {
					result.push(41);
					result.push(42);
				}
				else if (_root.id == 51) {
					result.push(49);
					result.push(50);
				}
				else if (_root.id == 59) {
					result.push(57);
					result.push(58);
				}
				else if (_root.id == 67) {
					result.push(65);
					result.push(66);
				}
				else if (_root.id == 75) {
					result.push(73);
					result.push(74);
				}
				else if (_root.id == 81) {
					result.push(79);
					result.push(80);
				}
				else if (_root.id == 87) {
					result.push(85);
					result.push(86);
				}
				else if (_root.id == 93) {
					result.push(91);
					result.push(92);
				}
				else if (_root.id == 98) {
					result.push(96);
					result.push(97);
				}
				else if (_root.id == 103) {
					result.push(101);
					result.push(102);
				}
				else if (_root.id == 108) {
					result.push(106);
					result.push(107);
				}
				else if (_root.id == 113) {
					result.push(111);
					result.push(112);
				}
				else if (_root.id == 118) {
					result.push(116);
					result.push(117);
				}
				else if (_root.id == 123) {
					result.push(121);
					result.push(122);
				}
				else if (_root.id == 128) {
					result.push(126);
					result.push(127);
				}
				else if (_root.id == 133) {
					result.push(131);
					result.push(132);
				}
				else if (_root.id == 138) {
					result.push(136);
					result.push(137);
				}
				else if (_root.id == 143) {
					result.push(141);
					result.push(142);
				}
				else if (_root.id == 148) {
					result.push(146);
					result.push(147);
				}
				else if (_root.id == 153) {
					result.push(151);
					result.push(152);
				}
				else if (_root.id == 158) {
					result.push(156);
					result.push(157);
				}
				else if (_root.id == 163) {
					result.push(161);
					result.push(162);
				}
				result.push(_root.id);
				each(_root._children, (_child: RolePermissionModel) => {
					if (_child.isSelected) {
						result.push(_child.id);
					}
				});
			}
		});
		
		return result;
	}
	isSelectedChanged($event, permission: RolePermissionModel) {
		
		if (permission._children.length === 0 && permission.isSelected) {
			const _root = find(this.rolePermissions, (item: RolePermissionModel) => item.id === permission.parentId);
			if (_root && !_root.isSelected) {
				_root.isSelected = true;
			}
			return;
		}
		if (permission._children.length === 0 && !permission.isSelected) {
			const _root = find(this.rolePermissions, (item: RolePermissionModel) => item.id === permission.parentId);
			if (_root && _root.isSelected) {
				if (!some(_root._children, (item: RolePermissionModel) => item.isSelected === true)) {
					_root.isSelected = false;
				}
			}
			return;
		}
		if (permission._children.length > 0 && permission.isSelected) {
			each(permission._children, (item: RolePermissionModel) => item.isSelected = true);
			return;
		}
		if (permission._children.length > 0 && !permission.isSelected) {
			each(permission._children, (item: RolePermissionModel) => {
				item.isSelected = false;
			});
			return;
		}
		
	}
	getTitle(): string {
		if (this.role.Raw_Version > 0) {
			if (this.IsReadOnly == true) {
				return `View role '${this.role.Role_Name}'`;
			}
			else if (this.IsReadOnly == false) {
				return `Edit role '${this.role.Role_Name}'`;
			}
		}
		return 'New role';
	}
	getWaitDesciption() {
		if (this.role.Raw_Version > 0) {
			return this.translate.instant('COMMON.COMMON.UPDATE_RECORD.WAIT_DESCRIPTION', { name: 'Role' });
		}
		return this.translate.instant('COMMON.COMMON.NEW_RECORD.WAIT_DESCRIPTION', { name: 'Role' });
	}
	prepareRole(): RoleModel {
		
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		const _role = new RoleModel();
		_role.id = this.role.id;
		_role.RolePermissionData = this.preparePermissionIds();
		_role.Raw_Version = this.role.Raw_Version;
		_role.Role_Name = this.setRole.Role_Name;
		_role.Department_Id = this.setRole.Department_Id;
		_role.status = this.role.status;
		_role.User_Id = this.User_Id;
		
		return _role;
	}
	onSubmit() {
		
		this.hasFormErrors = false;
		this.loadingAfterSubmit = false;
		const editedRole = this.prepareRole();
		if (editedRole.Department_Id == undefined || editedRole.Role_Name == "" || editedRole.RolePermissionData.length == 0) {
			this.hasFormErrors = true;
			return;
		}
		if (editedRole.Raw_Version > 0) {
			this.updateRole(editedRole);
		} else {
			this.createRole(editedRole);
		}
		
	}
	updateRole(_role: RoleModel) {
		
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		// this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.Srv_Role.updateRole(_role).subscribe(res => {
			this.viewLoading = false;
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		// return this.hideActionLoadingDistpatcher;
	}
	createRole(_role: RoleModel) {
		
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		// this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.Srv_Role.createRole(_role).subscribe(res => {
			this.viewLoading = false;
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		// return this.hideActionLoadingDistpatcher;
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
