import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, 
		 Routes } from '@angular/router';
import { FormsModule, 
		 ReactiveFormsModule, 
		 FormControl} from '@angular/forms';
import { HttpClientModule, 
		 HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
import { HttpUtilsService, 
		 TypesUtilsService, 
		 InterceptService, 
		 LayoutUtilsService } from '../../../core/_base/crud';
import { ModuleGuard } from '../../../core/auth';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
const moment = _rollupMoment || _moment;
import { ActionNotificationComponent, 
		 ActiveInActiveSingleEntityDialogComponent, 
		 ActiveInActiveMultyEntityDialogComponent, 
		 FetchEntityDialogComponent, 
		 UpdateStatusDialogComponent } from '../../partials/content/crud';
		 import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	DateAdapter,
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS
} from '@angular/material';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { _SmeMasterReducer,AllInsurerReducerSme,AllPolicy_TypeReducerSme, SmeMasterEffects } from '../../../core/sme';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SmeUpdateService } from '../../../core/sme/_services/sme-update.service';
import { Input_Percentage } from './InputPercent.directive';
import { ORUploadComponent } from './OR-upload.component';
import { ORUploadMasterComponent } from './OR-upload-master/OR-upload-master.component';
import { HealthORUploadEditComponent } from './health-OR-upload-edit/health-OR-upload-edit.component';
import { HealthORUploadListComponent } from './health-OR-upload-list/health-OR-upload-list.component';
import { MotorORUploadEditComponent } from './motor-OR-upload-edit/motor-OR-upload-edit.component';
import { MotorORUploadListComponent } from './motor-OR-upload-list/motor-OR-upload-list.component';
import { SmeORUploadEditComponent } from './sme-OR-upload-edit/sme-OR-upload-edit.component';
import { SmeORUploadListComponent } from './sme-OR-upload-list/sme-OR-upload-list.component';
import { NumberOnlyDirective } from './NumberOnly.Directive';
import { MotorUpdateService } from '../../../core/motor/_services/motor-update.service';
import { HealthUpdateService } from '../../../core/health/_services/health-update.service';
import { _HealthMasterReducer } from '../../../core/health/_reducers/health-master.reducers';
import { _MotorMasterReducer } from '../../../core/motor/_reducers/motor-master.reducers';
import { HealthMasterEffects } from '../../../core/health/_effects/health-master.effects';
import { MotorMasterEffects } from '../../../core/motor/_effects/motor-master.effects';
import { NgxSpinnerModule } from 'ngx-spinner';

// tslint:disable-next-line:class-name
export const MY_FORMATS = {
	parse: {
	  dateInput: 'LL',
	},
	display: {
	  dateInput: 'DD-MMM-YYYY',
	  monthYearLabel: 'MMMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMM YYYY',
	},
  };

const routes: Routes = [
	{
		path: '',
		component: ORUploadComponent,		
		children: [
			{
				path: '',
				redirectTo: 'ORUpload',
				pathMatch: 'full'
			},
			{
				canActivate: [ModuleGuard],
				data: { moduleName: 'accessToORUploadSubMenu' },
				path: 'ORUpload',
				component: ORUploadMasterComponent
			},
		]
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		NgxSpinnerModule,
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		NgbProgressbarModule,		
		NgxMatSelectSearchModule
	],
	providers: [
		ModuleGuard,
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		MotorUpdateService,
		SmeUpdateService,
		HealthUpdateService,
		TypesUtilsService,
		LayoutUtilsService,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	],
	entryComponents: [
		ActionNotificationComponent,
		ActiveInActiveSingleEntityDialogComponent,
		ActiveInActiveMultyEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		ORUploadMasterComponent,
		HealthORUploadEditComponent,
		HealthORUploadListComponent,
		MotorORUploadEditComponent,
		MotorORUploadListComponent,
		SmeORUploadEditComponent,
		SmeORUploadListComponent
	],
	declarations: [
		ORUploadComponent,
		Input_Percentage,
		NumberOnlyDirective,
		ORUploadMasterComponent,
		HealthORUploadEditComponent,
		HealthORUploadListComponent,
		MotorORUploadEditComponent,
		MotorORUploadListComponent,
		SmeORUploadEditComponent,
		SmeORUploadListComponent
	]
})
export class ORUploadModule {	
	constructor(private dateAdapter: DateAdapter<Date>) {
		var date = new FormControl(moment());
	}
}
