import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[Input_Percentage]'
})
export class Input_Percentage {
    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) { }

    @Input() latestInputValue: number;
    @HostListener('keypress', ['$event']) onKeyPress(event) {
        if (event.target.value >= 100) {
            event.target.value = 100;
            event.preventDefault();
            return false;
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp(event) {
        if (event.target.value >= 100) {
            event.target.value = 100;
            event.preventDefault();
            return false;
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;
        if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
            return false;
            // Allow: Ctrl+V)
        }
        console.log(this.el.nativeElement.value);
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }
}