import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService } from '../../../core/_base/crud';
import { ModuleGuard } from '../../../core/auth';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
const moment = _rollupMoment || _moment;
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatSelectModule, MatMenuModule, MatProgressBarModule, MatButtonModule, MatCheckboxModule, MatDialogModule, MatTabsModule, MatNativeDateModule, MatCardModule, MatRadioModule, MatIconModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule, MAT_DIALOG_DEFAULT_OPTIONS, MatSnackBarModule, MatTooltipModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReportSummaryComponent } from './report-summary.component';
import { SummaryMasterComponent } from './summary-master/summary-master.component';
import { MotorSummaryComponent } from './motor-summary/motor-summary.component';
import { MotorReportService } from '../../../core/motor/_services/motor-report.service';
import { HealthSummaryComponent } from './health-summary/health-summary.component';
import { HealthReportService } from '../../../core/health/_services/health-report.service';
import { SmeSummaryComponent } from './sme-summary/sme-summary.component';
import { SmeReportService } from '../../../core/sme/_services/sme-report.service';
import { MotorSummaryDlgComponent } from './motor-summary-dlg/motor-summary-dlg.component';
import { HealthSummaryDlgComponent } from './health-summary-dlg/health-summary-dlg.component';
import { SmeSummaryDlgComponent } from './sme-summary-dlg/sme-summary-dlg.component';
import { NgxSpinnerModule } from 'ngx-spinner';

// tslint:disable-next-line:class-name
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};



const routes: Routes = [
    {
        path: '',
        component: ReportSummaryComponent,        
        children: [
            {
                path: '',
                redirectTo: 'ReportSummary',
                pathMatch: 'full'
            },
            {
                canActivate: [ModuleGuard],
                data: { moduleName: 'accessToORSummarySubMenu' },
                path: 'ReportSummary',
                component: SummaryMasterComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        NgxSpinnerModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgbProgressbarModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        MotorReportService,
        HealthReportService,
        SmeReportService,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    entryComponents: [
        MotorSummaryDlgComponent,
        HealthSummaryDlgComponent,
        SmeSummaryDlgComponent
	],
    declarations: [
        ReportSummaryComponent,
        SummaryMasterComponent,
        MotorSummaryComponent,
        HealthSummaryComponent,
        SmeSummaryComponent,
        MotorSummaryDlgComponent,
        HealthSummaryDlgComponent,
        SmeSummaryDlgComponent
    ]
})
export class ReportSummaryModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        var date = new FormControl(moment());
    }
}