import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { UserModel, UserStatusModel } from '../_models/user.model';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { UserAddress } from '../_models/address.model';
import { CityModel } from '../../master/_models/city.model';

const API_DEPARTMENTS_URL = 'api/users';

@Injectable()
export class UsersService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	
	loadUsersPage(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		return this.http.get<QueryResultsModel[]>(Base_Url + 'User/Get_All_Users_Grid_Data').pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res["response"], queryParams, ['status']);
				return of(result);
			})
		);
	}

	GetUserDetailsById(Users_Id: number) {		
		return this.http.get<UserModel>(Base_Url + 'User/Get_User_Address_By_Users_Id/' + Users_Id);
	}

	GetUserDetailsByUserId(Users_Id: number) {		
		return this.http.get<UserModel>(Base_Url + 'User/Get_User_Address_By_User_Id/' + Users_Id);
	}
	createUser(user: UserModel) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'User/Save_User', user);
	}

	getAllMasterForAddress(): Observable<any[]> {
		return this.http.get<any[]>(Base_Url + 'User/Get_User_Master_List')		
	}

	updateUser(user: UserModel) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'User/Update_User', user);
	}
	
	updateStatusUser(userStatus: UserStatusModel[]) {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.post(Base_Url + 'User/Update_Status_User', userStatus);
	}
}
