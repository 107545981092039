// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { HealthMasterState,InsurerState,Policy_TypeState } from '../_reducers/health-master.reducers';
import * as fromHealthMaster from '../_reducers/health-master.reducers';

import { Health_OR_Master } from '../_models/health-master.model';

export const selectHealthMasterState = createFeatureSelector<HealthMasterState>('_HealthMaster');
export const AllInsurerStateHealth = createFeatureSelector<InsurerState>('AllInsurerHealth');
export const AllPolicy_TypeStateHealth = createFeatureSelector<Policy_TypeState>('AllPolicy_Type_Health');

export const selectHealthMasterById = (_HealthMasterId: number) => createSelector(
    selectHealthMasterState,
    _HealthMasterState => _HealthMasterState.entities[_HealthMasterId]
);

export const selectHealthMasterPageLoading = createSelector(
    selectHealthMasterState,
    _HealthMasterState => _HealthMasterState.listLoading
);

export const selectHealthMasterActionLoading = createSelector(
    selectHealthMasterState,
    _HealthMasterState => _HealthMasterState.actionsloading
);

export const selectHealthMasterShowInitWaitingMessage = createSelector(
    selectHealthMasterState,
    _HealthMasterState => _HealthMasterState.showInitWaitingMessage
);

export const selectAllInsurerHealth = createSelector(
    AllInsurerStateHealth,    
    fromHealthMaster.selectAll
);

export const selectAllPolicy_Type = createSelector(
    AllPolicy_TypeStateHealth,    
    fromHealthMaster.selectAll
);

export const selectAllHealthMaster = createSelector(
    selectHealthMasterState,
    fromHealthMaster.selectAll
);

export const selectHealthMasterInStore = createSelector(
    selectHealthMasterState,
    _HealthMasterState => {
        const items: Health_OR_Master[] = [];
        each(_HealthMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Health_OR_Master[] = httpExtension.sortArray(items, _HealthMasterState.lastQuery.sortField, _HealthMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, _HealthMasterState.totalCount, '');
    }
);
