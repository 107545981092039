import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-OR-master-master',
  templateUrl: './OR-master-master.component.html'
})
export class ORMasterMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
