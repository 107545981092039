// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
import { Sme_OR_Master } from '../_models/sme-master.model';
// Models


export enum SmeMasterActionTypes {
    SmeMasterPageRequested = '[SmeMaster List Page] SmeMaster Page Requested',        
    SmeMasterPageLoaded = '[SmeMaster API] SmeMaster Page Loaded',
    SmeMasterPageToggleLoading = '[SmeMaster] SmeMaster Page Toggle Loading',
    SmeMasterActionToggleLoading = '[SmeMaster] SmeMaster Action Toggle Loading',
    AllInsurerRequested = '[Init] All Insurer SmeMaster Requested',
    AllInsurerLoaded = '[Init] All Insurer SmeMaster Loaded',
    AllPolicy_TypeRequested = '[Init] All Policy Type SmeMaster Requested',
    AllPolicy_TypeLoaded = '[Init] All Policy Type SmeMaster Loaded'
}

export class SmeMasterPageRequested implements Action {
    readonly type = SmeMasterActionTypes.SmeMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class SmeMasterPageLoaded implements Action {
    readonly type = SmeMasterActionTypes.SmeMasterPageLoaded;
    constructor(public payload: { _SmeMaster: Sme_OR_Master[], totalCount: number, page: QueryParamsModel }) { }
}

export class SmeMasterPageToggleLoading implements Action {
    readonly type = SmeMasterActionTypes.SmeMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class SmeMasterActionToggleLoading implements Action {
    readonly type = SmeMasterActionTypes.SmeMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllInsurerRequested implements Action {
    readonly type = SmeMasterActionTypes.AllInsurerRequested;
}

export class AllInsurerLoaded implements Action {
    readonly type = SmeMasterActionTypes.AllInsurerLoaded;
    constructor(public payload: { _SmeMaster: Sme_OR_Master[] }) { }
}

export class AllPolicy_TypeRequested implements Action {
    readonly type = SmeMasterActionTypes.AllPolicy_TypeRequested;
}

export class AllPolicy_TypeLoaded implements Action {
    readonly type = SmeMasterActionTypes.AllPolicy_TypeLoaded;
    constructor(public payload: { _SmeMaster: Sme_OR_Master[] }) { }
}

export type SmeMasterActions = AllInsurerRequested
| AllInsurerLoaded
| AllPolicy_TypeRequested
| AllPolicy_TypeLoaded
| SmeMasterPageRequested
| SmeMasterPageLoaded
| SmeMasterPageToggleLoading
| SmeMasterActionToggleLoading;
