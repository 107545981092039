import { BaseModel } from '../../_base/crud';
export class SmeORUploadMdl extends BaseModel {	
	Insurer_Id: number;
	User_Id: string;
	Master_Data: Sme_OR_Upload_Mdl[];
	Removed_achivement_Data: Sme_OR_Upload_Mdl[];
}

export class Sme_OR_Upload_Mdl extends BaseModel {
	id: number;	
	Insurer_Id: number;
	Insurer: string;	
	OR_Month_Id: number;
	OR_Month: string;	
	OR_Year: string;
    File_Location:any;
	status: number;
	User_Id: string;
	CRaw_Version: number;

	clear() {
		this.id = undefined;
		this.File_Location=''		
		this.Insurer_Id = undefined;
		this.Insurer = '';
		this.OR_Month_Id = 0;
		this.OR_Month = '';
		this.OR_Year = '';
		this.status = 1;		
		this.User_Id = undefined;
		this.CRaw_Version = 0;
	}
}

export class Sme_OR_Upload_Status_Mdl  extends BaseModel {	
	Status: number;
	Insurer_Id: number;
	User_Id: string;
	clear() {
		this.Status=0;
		this.Insurer_Id=0;		
		this.User_Id=undefined;
	}
}