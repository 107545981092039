// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
// Lodash
import { filter, some, find, each } from 'lodash';
// Environment
import { environment } from '../../../../environments/environment';
// CRUD
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
// Models
import { UserModel } from '../_models/user.model';
import { UserPermissionModel } from '../_models/user_permission.model';
import { RoleModel, RoleStatusModel } from '../_models/role.model';
import { Base_Url } from '../../Constant/Constants';
import { DepartmentModel } from '../../master';
import { DepartmentPermissionModel } from '../_models/department_permission.model';
import { RolePermissionModel } from '../_models/role_permission.model';
import { Logout } from '../_actions/auth.actions';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';

const API_USERS_URL = 'api/users';
const API_PERMISSION_URL = 'api/TempUserPermissionTbl';
const API_ROLES_URL = 'api/roles';

@Injectable()
export class AuthService {
    Mdl_Dialogue = UserModel[2];
    User_Id:string;
    constructor(private http: HttpClient,
        private store: Store<AppState>,
        private httpUtils: HttpUtilsService) { }

    // Authentication/Authorization
    login(User_Id: string, Password: string): Observable<UserModel> {
        if (!User_Id || !Password) {
            return of(null);
        }

            return this.http.get<UserModel[]>(Base_Url + 'User/Users_Authentication/' + User_Id + '/' + Password).pipe(
            map((result: UserModel[]) => {
                if (result == null || result == undefined || result.length <= 0) {
                    return null;
                }


                const user = find(result, (item: UserModel) => {
                    return (item.User_Id === User_Id);
                });

                if (!user) {
                    return null;
                }

                user.Password = undefined;
                return user;
            })
        );
    }

    OTP_Authentication(User_Id: string, OTP: string): Observable<UserModel> {
        if (!User_Id || !OTP) {
            return of(null);
        }        
            return this.http.get<UserModel[]>(Base_Url + 'User/OTP_Authentication/' + User_Id + '/' + OTP).pipe(
            map((result: UserModel[]) => {
                if (result == null || result == undefined || result.length <= 0) {
                    return null;
                }
                const user = find(result, (item: UserModel) => {
                    return (item.User_Id === User_Id);
                });
                if (!user) {
                    return null;
                }
                user.OTP = undefined;
                return user;
            })
        );
    }

    Resend_OTP(User_Id: string): Observable<UserModel> {        
        if (!User_Id) {
            return of(null);
        }
        return this.http.get<UserModel[]>(Base_Url + 'User/Resend_OTP/' + User_Id).pipe(
            map((result: UserModel[]) => {
                if (result == null || result == undefined || result.length <= 0) {
                    return null;
                }
                const user = find(result, (item: UserModel) => {
                    return (item.User_Id === User_Id);
                });
                if (!user) {
                    return null;
                }
                user.OTP = undefined;
                return user;
            })
        );
    }

    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        return this.getAllUsers().pipe(
            mergeMap((response: UserModel[]) => {
                const result = this.httpUtils.baseFilter(response, queryParams, ['status']);
                return of(result);
            })
        );
    }

    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.getAllUsers().pipe(
            map((users: UserModel[]) => {
                if (some(users, (user: UserModel) => some(user.roles, (_roleId: number) => _roleId === roleId))) {
                    return true;
                }

                return false;
            })
        );
    }

    getAllUsers(): Observable<UserModel[]> {
        //return this.http.get<UserModel[]>(API_USERS_URL);
        return this.http.get<UserModel[]>(Base_Url + 'User/Get_All_Users_Grid_Data');
    }  
    
    Forgot_Password(User_Id, email): Observable<any> {
        this.Mdl_Dialogue = {
            User_Id: User_Id,
            email: email
        }
        return this.http.post<UserModel[]>(Base_Url + 'User/Forgot_Password', this.Mdl_Dialogue).pipe(
            map((result: UserModel[]) => {
                if (result == null || result == undefined || result.length <= 0) {
                    return null;
                }

                const user = find(result, (item: UserModel) => {
                    return (item.User_Id === this.Mdl_Dialogue.User_Id);
                });

                if (!user) {
                    return null;
                }

                user.Password = undefined;
                return user;
            }),
            catchError(this.handleError('forgot-Password', []))
        );
    }

    Reset_Password(User_Id, OTP, Password): Observable<any> {
        this.Mdl_Dialogue = {
            User_Id: User_Id,
            OTP: OTP,
            Password: Password
        }
        return this.http.post<UserModel[]>(Base_Url + 'User/Reset_Password', this.Mdl_Dialogue).pipe(
            map((result: UserModel[]) => {
                if (result == null || result == undefined || result.length <= 0) {
                    return null;
                }
                const user = find(result, (item: UserModel) => {
                    return (item.Error === "0" || item.Error === "-1" || item.Error === "-2");
                });

                if (!user) {
                    return null;
                }

                // user.Password = undefined;
                return user;
            }),
            catchError(this.handleError('Reset-Password', []))
        );
    }

    getUserByToken(): Observable<UserModel> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        if (!userToken) {
            return of(null);
        }
        // return this.http.get<UserModel[]>(API_USERS_URL);
        return this.http.get<UserModel[]>(Base_Url + 'User/Token_Authentication/' + userToken).pipe(
            map((result: UserModel[]) => {
                if (result == null || result["response"] == undefined || result["response"].length <= 0) {
                    return null;
                }
                const user = find(result["response"], (item: UserModel) => {
                    return (item.accessToken === userToken.toString());
                });

                if (!user) {
                    return null;
                }
                user.Password = undefined;
                return user;
            })
        );
    }

    Get_Department() {
        return this.http.get<DepartmentModel[]>(Base_Url + 'Department/Get_Department');
    }

    createRole(role: RoleModel) {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post(Base_Url + 'Role/Save_Role', role);
    }

    updateRole(role: RoleModel) {
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.post(Base_Url + 'Role/Update_Role', role);
    }

    updateStatusRole(roleStatus: RoleStatusModel[]) {
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.post(Base_Url + 'Role/Update_Status_Role', roleStatus);
    }
    
    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
        return this.http.get<RoleModel[]>(Base_Url + 'Role/Get_All_Roles').pipe(
            mergeMap(res => {
                const result = this.httpUtils.baseFilter(res["response"], queryParams, ['status']);
                return of(result);
            })
        );
    }

    //---------------------------------------------------------------------------------------------------------------------------------

    register(user: UserModel): Observable<any> {
        user.roles = [2]; // Manager
        user.accessToken = 'access-token-' + Math.random();
        user.refreshToken = 'access-token-' + Math.random();
        user.pic = './assets/media/users/default.jpg';

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<UserModel>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: UserModel) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    

    // Users

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        const httpHeaders = new HttpHeaders();
        // Note: Add headers if needed (tokens/bearer)
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<UserModel>(API_USERS_URL, user, { headers: httpHeaders });
    }


    getUserById(userId: number): Observable<UserModel> {
        if (!userId) {
            return of(null);
        }
        return this.http.get<UserModel>(API_USERS_URL + `/${userId}`);
    }

    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${API_USERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: UserModel): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_USERS_URL, _user, { headers: httpHeaders }).pipe(
            catchError(err => {
                return of(null);
            })
        );
    }

    // Department Permissions
    getAllDepartmentPermissions(): Observable<DepartmentPermissionModel[]> {
        return this.http.get<DepartmentPermissionModel[]>(Base_Url + 'Department/Get_Permission');
    }

    // Role Permissions
    getAllRolePermissions(): Observable<RolePermissionModel[]> {
        return this.http.get<RolePermissionModel[]>(Base_Url + 'Role/Get_Permission');
    }

    getAllRole(): Observable<RolePermissionModel[]> {
        return this.http.get<RolePermissionModel[]>(Base_Url + 'User/Get_All_Roles');
    }

    get_Role_Wise_Permission_For_User(): Observable<RolePermissionModel[]> {
        return this.http.get<RolePermissionModel[]>(Base_Url + 'User/Get_Role_Wise_Permission');
    }

    // Permissions
    getAllUserPermissions(): Observable<UserPermissionModel[]> {        
        const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
        return this.http.get<UserPermissionModel[]>(Base_Url + 'User/Get_All_User_Permission/' + User_Id);
    }

    getRolePermissions(roleId: number): Observable<UserPermissionModel[]> {
        const allRolesRequest = this.http.get<UserPermissionModel[]>(API_PERMISSION_URL);
        const roleRequest = roleId ? this.getRoleById(roleId) : of(null);
        return forkJoin(allRolesRequest, roleRequest).pipe(
            map(res => {
                const _allPermissions: UserPermissionModel[] = res[0];
                const _role: RoleModel = res[1];
                if (!_allPermissions || _allPermissions.length === 0) {
                    return [];
                }

                const _rolePermission = _role ? _role.RolePermissionData : [];
                const result: UserPermissionModel[] = this.getRolePermissionsTree(_allPermissions, _rolePermission);
                return result;
            })
        );
    }

    private getRolePermissionsTree(_allPermission: UserPermissionModel[] = [], _rolePermissionIds: number[] = []): UserPermissionModel[] {
        const result: UserPermissionModel[] = [];
        const _root: UserPermissionModel[] = filter(_allPermission, (item: UserPermissionModel) => !item.parentId);
        each(_root, (_rootItem: UserPermissionModel) => {
            _rootItem._children = [];
            _rootItem._children = this.collectChildrenPermission(_allPermission, _rootItem.id, _rolePermissionIds);
            _rootItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _rootItem.id));
            result.push(_rootItem);
        });
        return result;
    }

    private collectChildrenPermission(_allPermission: UserPermissionModel[] = [],
        _parentId: number, _rolePermissionIds: number[] = []): UserPermissionModel[] {
        const result: UserPermissionModel[] = [];
        const _children: UserPermissionModel[] = filter(_allPermission, (item: UserPermissionModel) => item.parentId === _parentId);
        if (_children.length === 0) {
            return result;
        }

        each(_children, (_childItem: UserPermissionModel) => {
            _childItem._children = [];
            _childItem._children = this.collectChildrenPermission(_allPermission, _childItem.id, _rolePermissionIds);
            _childItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _childItem.id));
            result.push(_childItem);
        });
        return result;
    }

    // Roles
    getAllRoles(): Observable<RoleModel[]> {
        // return this.http.get<RoleModel[]>(Base_Url + 'Role/Get_All_Active_Roles');
        const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
        return this.http.get<RoleModel[]>(Base_Url + 'User/Get_All_User_Assigned_Roles/' + User_Id).pipe(
            map((result: RoleModel[]) => {
                if (result == null || result["response"] == undefined || result["response"].length <= 0) {
                    return null;
                }
                else {
                    return result["response"];
                }
            })
        );
    }

    getRoleById(roleId: number): Observable<RoleModel> {
        return this.http.get<RoleModel>(API_ROLES_URL + `/${roleId}`);
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
