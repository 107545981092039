import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription, of, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, selectAllUserPermissions } from '../../../../../core/auth';
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { DepartmentModel, selectDepartmentsActionLoading } from '../../../../../core/master';
import { Router } from '@angular/router';
import { DepartmentActionToggleLoading } from '../../../../../core/master/_actions/department.actions';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentsService } from '../../../../../core/master/_services/departments.service';
import { each, find, some } from 'lodash';
import { DepartmentPermissionModel } from '../../../../../core/auth/_models/department_permission.model';
import { environment } from '../../../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-department-edit-dialog',
	templateUrl: './department-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class DepartmentEditDialogComponent implements OnInit, OnDestroy {
	showActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: false });
	department: DepartmentModel;
	setDepartment: DepartmentModel;
	User_Id: string;
	departmentForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	private componentSubscriptions: Subscription;
	IsReadOnly: boolean;
	allPermissions: DepartmentPermissionModel[] = [];
	dprmtPermissions: DepartmentPermissionModel[] = [];
	constructor(public dialogRef: MatDialogRef<DepartmentEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private translate: TranslateService,
		private Srv_Department: DepartmentsService,
		private router: Router,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private spinner: NgxSpinnerService) {
		this.IsReadOnly = data.IsReadOnly;
		this.allPermissions = data.AllPermissionList;
	}
	ngOnInit() {
		
		this.store.pipe(select(selectDepartmentsActionLoading)).subscribe(res => this.viewLoading = res);
		this.department = this.data.department;
		this.setDepartment = new DepartmentModel();
		this.setDepartment.UserPermissionData = this.department.UserPermissionData;
		this.setDepartment.Department_Name = this.department.Department_Name;
		this.loadPermissions();
		
	}
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	loadPermissions() {
		
		if (!this.allPermissions) {
			return;
		}
		const mainPermissions = this.allPermissions.filter(el => !el.parentId);
		mainPermissions.forEach((element: DepartmentPermissionModel) => {
			const hasUserPermission = this.setDepartment.UserPermissionData.some(t => t === element.id);
			const rootPermission = new DepartmentPermissionModel();
			rootPermission.clear();
			rootPermission.isSelected = hasUserPermission;
			rootPermission._children = [];
			rootPermission.id = element.id;
			rootPermission.level = element.level;
			rootPermission.parentId = element.parentId;
			rootPermission.title = element.title;
			const children = this.allPermissions.filter(el => el.parentId && el.parentId === element.id);
			children.forEach(child => {
				const hasUserChildPermission = this.setDepartment.UserPermissionData.some(t => t === child.id);
				const childPermission = new DepartmentPermissionModel();
				childPermission.clear();
				childPermission.isSelected = hasUserChildPermission;
				childPermission._children = [];
				childPermission.id = child.id;
				childPermission.level = child.level;
				childPermission.parentId = child.parentId;
				childPermission.title = child.title;
				rootPermission._children.push(childPermission);
			});
			this.dprmtPermissions.push(rootPermission);
		});
		
	}
	preparePermissionIds(): number[] {
		
		const result = [];
		each(this.dprmtPermissions, (_root: DepartmentPermissionModel) => {
			if (_root.isSelected) {
				if (_root.id == 3) {
					result.push(1);
					result.push(2);
				}
				else if (_root.id == 11) {
					result.push(9);
					result.push(10);
				}
				else if (_root.id == 19) {
					result.push(17);
					result.push(18);
				}
				else if (_root.id == 27) {
					result.push(25);
					result.push(26);
				}
				else if (_root.id == 35) {
					result.push(33);
					result.push(34);
				}
				else if (_root.id == 43) {
					result.push(41);
					result.push(42);
				}
				else if (_root.id == 51) {
					result.push(49);
					result.push(50);
				}
				else if (_root.id == 59) {
					result.push(57);
					result.push(58);
				}
				else if (_root.id == 67) {
					result.push(65);
					result.push(66);
				}
				else if (_root.id == 75) {
					result.push(73);
					result.push(74);
				}
				else if (_root.id == 81) {
					result.push(79);
					result.push(80);
				}
				else if (_root.id == 87) {
					result.push(85);
					result.push(86);
				}
				else if (_root.id == 93) {
					result.push(91);
					result.push(92);
				}
				else if (_root.id == 98) {
					result.push(96);
					result.push(97);
				}
				else if (_root.id == 103) {
					result.push(101);
					result.push(102);
				}
				else if (_root.id == 108) {
					result.push(106);
					result.push(107);
				}
				else if (_root.id == 113) {
					result.push(111);
					result.push(112);
				}
				else if (_root.id == 118) {
					result.push(116);
					result.push(117);
				}
				else if (_root.id == 123) {
					result.push(121);
					result.push(122);
				}
				else if (_root.id == 128) {
					result.push(126);
					result.push(127);
				}
				else if (_root.id == 133) {
					result.push(131);
					result.push(132);
				}
				else if (_root.id == 138) {
					result.push(136);
					result.push(137);
				}
				else if (_root.id == 143) {
					result.push(141);
					result.push(142);
				}
				else if (_root.id == 148) {
					result.push(146);
					result.push(147);
				}
				else if (_root.id == 153) {
					result.push(151);
					result.push(152);
				}
				else if (_root.id == 158) {
					result.push(156);
					result.push(157);
				}
				else if (_root.id == 163) {
					result.push(161);
					result.push(162);
				}
				result.push(_root.id);
				each(_root._children, (_child: DepartmentPermissionModel) => {
					if (_child.isSelected) {
						result.push(_child.id);
					}
				});
			}
		});
		
		return result;
	}
	isSelectedChanged($event, permission: DepartmentPermissionModel) {
		
		if (permission._children.length === 0 && permission.isSelected) {
			const _root = find(this.dprmtPermissions, (item: DepartmentPermissionModel) => item.id === permission.parentId);
			if (_root && !_root.isSelected) {
				_root.isSelected = true;
			}
			return;
		}
		if (permission._children.length === 0 && !permission.isSelected) {
			const _root = find(this.dprmtPermissions, (item: DepartmentPermissionModel) => item.id === permission.parentId);
			if (_root && _root.isSelected) {
				if (!some(_root._children, (item: DepartmentPermissionModel) => item.isSelected === true)) {
					_root.isSelected = false;
				}
			}
			return;
		}
		if (permission._children.length > 0 && permission.isSelected) {
			each(permission._children, (item: DepartmentPermissionModel) => item.isSelected = true);
			return;
		}
		if (permission._children.length > 0 && !permission.isSelected) {
			each(permission._children, (item: DepartmentPermissionModel) => {
				item.isSelected = false;
			});
			return;
		}
		
	}
	getTitle(): string {
		if (this.department.Raw_Version > 0) {
			if (this.IsReadOnly == true) {
				return `View department '${this.department.Department_Name}'`;
			}
			else if (this.IsReadOnly == false) {
				return `Edit department '${this.department.Department_Name}'`;
			}
		}
		return 'New department';
	}
	getWaitDesciption() {
		if (this.department.Raw_Version > 0) {
			return this.translate.instant('COMMON.COMMON.UPDATE_RECORD.WAIT_DESCRIPTION', { name: 'Department' });
		}
		return this.translate.instant('COMMON.COMMON.NEW_RECORD.WAIT_DESCRIPTION', { name: 'Department' });
	}
	prepareDepartment(): DepartmentModel {
		
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		const _department = new DepartmentModel();
		_department.id = this.department.id;
		_department.UserPermissionData = this.preparePermissionIds();
		_department.Raw_Version = this.department.Raw_Version;
		_department.Department_Name = this.setDepartment.Department_Name;
		_department.status = this.department.status;
		_department.User_Id = this.User_Id;
		
		return _department;
	}
	onSubmit() {
		
		this.hasFormErrors = false;
		const editedDepartment = this.prepareDepartment();
		if (editedDepartment.Department_Name == "" || editedDepartment.UserPermissionData.length == 0) {
			this.hasFormErrors = true;
			return;
		}
		if (editedDepartment.Raw_Version > 0) {
			this.updateDepartment(editedDepartment);
		} else {
			this.createDepartment(editedDepartment);
		}
		
	}
	updateDepartment(_department: DepartmentModel) {
		
		this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.Srv_Department.updateDepartment(_department).subscribe(res => {
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		return this.hideActionLoadingDistpatcher;
	}
	createDepartment(_department: DepartmentModel) {
		
		this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.Srv_Department.createDepartment(_department).subscribe(res => {
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		return this.hideActionLoadingDistpatcher;
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
