import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    RouterModule,
    Routes
} from '@angular/router';
import {
    FormsModule,
    ReactiveFormsModule,
    FormControl
} from '@angular/forms';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
import {
    HttpUtilsService,
    TypesUtilsService,
    InterceptService,
    LayoutUtilsService
} from '../../../core/_base/crud';
import { ModuleGuard } from '../../../core/auth';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
const moment = _rollupMoment || _moment;
import {
    ActionNotificationComponent,
    ActiveInActiveSingleEntityDialogComponent,
    ActiveInActiveMultyEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../partials/content/crud';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    MatNativeDateModule,
    MatCardModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatSnackBarModule,
    MatTooltipModule,
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS
} from '@angular/material';
import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { _SmeMasterReducer, AllInsurerReducerSme, AllPolicy_TypeReducerSme, SmeMasterEffects } from '../../../core/sme';
import { SmeMasterService } from '../../../core/sme/_services/sme-master.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SmeUpdateService } from '../../../core/sme/_services/sme-update.service';
import { SmeReceivedService } from '../../../core/sme/_services/sme-received.service';
import { ORImportMasterComponent } from './OR-import-master/OR-import-master.component';
import { ORImportComponent } from './OR-import.component';
import { HealthORImportComponent } from './health-OR-import/health-OR-import.component';
import { MotorORImportComponent } from './motor-OR-import/motor-OR-import.component';
import { SmeORImportComponent } from './sme-OR-import/sme-OR-import.component';
import { _MotorMasterReducer } from '../../../core/motor/_reducers/motor-master.reducers';
import { MotorMasterEffects } from '../../../core/motor/_effects/motor-master.effects';
import { _HealthMasterReducer } from '../../../core/health/_reducers/health-master.reducers';
import { HealthMasterEffects } from '../../../core/health/_effects/health-master.effects';
import { MotorReceivedService } from '../../../core/motor/_services/motor-received.service';
import { HealthReceivedService } from '../../../core/health/_services/health-received.service';
import { NgxSpinnerModule } from 'ngx-spinner';

// tslint:disable-next-line:class-name
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};

const routes: Routes = [
    {
        path: '',
        component: ORImportComponent,
        
        children: [
            {
                path: '',
                redirectTo: 'ORImport',
                pathMatch: 'full'
            },
            {
                canActivate: [ModuleGuard],
                data: { moduleName: 'accessToORImportSubMenu' },
                path: 'ORImport',
                component: ORImportMasterComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        NgxSpinnerModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgbProgressbarModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        MotorReceivedService,
        HealthReceivedService,
        SmeReceivedService,
        TypesUtilsService,
        LayoutUtilsService,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    entryComponents: [
        ActionNotificationComponent,
        ActiveInActiveSingleEntityDialogComponent,
        ActiveInActiveMultyEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        HealthORImportComponent,
        MotorORImportComponent,
        SmeORImportComponent
    ],
    declarations: [
        ORImportComponent,
        ORImportMasterComponent,
        HealthORImportComponent,
        MotorORImportComponent,
        SmeORImportComponent
    ]
})
export class ORImportModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        var date = new FormControl(moment());
    }
}
