import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatOption, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { merge, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Health_OR_Master, selectAllPolicy_Type, selectAllInsurerHealth } from '../../../../core/health';
import { FormGroup, FormBuilder, Validators } from '../../../../../../node_modules/@angular/forms';
import { each } from 'lodash';
import { HealthReportService } from '../../../../core/health/_services/health-report.service';
import { HealthReportMdl } from '../../../../core/health/_models/health-report.model';
import * as xlsx from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-health-received',
  templateUrl: './health-received.component.html'
})
export class HealthReceivedComponent implements OnInit, AfterViewInit {
  Is_Data = false;
  Tot_Premium: any = 0;
  Tot_Comm_Amount_Regular: any = 0;
  Tot_TDS_Amount_Regular: any = 0;
  Tot_Net_Amount_Regular: any = 0;
  Tot_Comm_Amount_Rewards: any = 0;
  Tot_TDS_Amount_Rewards: any = 0;
  Tot_Net_Amount_Rewards: any = 0;
  curMonth: any = new Date().getMonth();
  curYear: any = new Date().getFullYear();

  FminDate = new Date(this.curYear - 10, 0, 1);
  FmaxDate = new Date(this.curYear, this.curMonth, 0);

  TminDate = new Date(this.curYear - 10, 0, 1);
  TmaxDate = new Date(this.curYear, this.curMonth, 0);

  Health_Received_Report: HealthReportMdl;
  private subscriptions: Subscription[] = [];
  Report_Data: any;
  dataSource = new MatTableDataSource();
  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('sort', { static: false }) sort: MatSort;
  Report_FG: FormGroup;
  displayedColumns = ['Full_Customer_Name', 'Policy_Type', 'Insurer', 'Policy_Number', 'Policy_Start_Date', 'Policy_End_Date', 'Pay_Discount', 'Premium', 'Comm_Amount_Regular', 'TDS_Amount_Regular', 'Net_Amount_Regular', 'Is_Regular_Received', 'Comm_Amount_Rewards', 'TDS_Amount_Rewards', 'Net_Amount_Rewards', 'Is_Rewards_Received', 'Cust_Status'];
  _INS_Policy_Type: any;
  INS_Policy_Type = [
    { id: 1, value: 'New' },
    { id: 2, value: 'Renewal' },
    { id: 3, value: 'Rollover' }
  ];
  Received = [
    { id: 1, value: 'Regular OR' },
    { id: 2, value: 'Rewards OR' },
    { id: 3, value: 'Both' },
    { id: 4, value: 'Any' }
  ];
  _Policy_Type: any;
  All_Health_Policy_Type: Health_OR_Master[] = [];
  All_Health_Insurer: Health_OR_Master[] = [];
  constructor(private store: Store<AppState>, private Srv_Report_Received: HealthReportService, private Report_FB: FormBuilder,
    private spinner: NgxSpinnerService) {
    this.store.pipe(select(selectAllInsurerHealth)).subscribe(res => this.All_Health_Insurer = res);
    this.store.pipe(select(selectAllPolicy_Type)).subscribe(res => this.All_Health_Policy_Type = res);
    this.Health_Received_Report = new HealthReportMdl;
  }
  setDataSource() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
  ngAfterViewInit() {
    this.setDataSource();
  }
  handleFromDateChange(event) {
    if (event.value != null) {
      var FMonth: any = new Date(event.value).getMonth();
      var FYear: any = new Date(event.value).getFullYear();
      var FDay: any = new Date(event.value).getDate();
      this.TminDate = new Date(FYear, FMonth, FDay);
    }
    else {
      this.Report_FG.controls['Policy_Date_To'].setValue('');
      this.curMonth = new Date().getMonth();
      this.curYear = new Date().getFullYear();

      this.FminDate = new Date(this.curYear - 10, 0, 1);
      this.FmaxDate = new Date(this.curYear, this.curMonth, 0);

      this.TminDate = new Date(this.curYear - 10, 0, 1);
      this.TmaxDate = new Date(this.curYear, this.curMonth, 0);
    }
  }
  handleToDateChange(event) {
    if (event.value != null) {
      var TMonth: any = new Date(event.value).getMonth();
      var TYear: any = new Date(event.value).getFullYear();
      var TDay: any = new Date(event.value).getDate();
      this.FmaxDate = new Date(TYear, TMonth, TDay);
    }
    else {
      this.Report_FG.controls['Policy_Date_From'].setValue('');
      this.curMonth = new Date().getMonth();
      this.curYear = new Date().getFullYear();

      this.FminDate = new Date(this.curYear - 10, 0, 1);
      this.FmaxDate = new Date(this.curYear, this.curMonth, 0);

      this.TminDate = new Date(this.curYear - 10, 0, 1);
      this.TmaxDate = new Date(this.curYear, this.curMonth, 0);
    }
  }
  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.Report_FG.controls.Insurer_Id.value.length == this.All_Health_Insurer.length)
      this.allSelected.select();

  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.Report_FG.controls.Insurer_Id
        .patchValue([...this.All_Health_Insurer.map(item => item.id), 0]);
    } else {
      this.Report_FG.controls.Insurer_Id.patchValue([]);
    }
  }

  ngOnInit() {
    this.Create_Form_Validation();
  }
  Create_Form_Validation() {
    this.Report_FG = this.Report_FB.group({
      Insurer_Id: [null, Validators.required],
      Policy_Date_From: [null, Validators.required],
      Policy_Date_To: [null, Validators.required],
      INS_Policy_Type_Id: [null, Validators.required],
      Policy_Type_Id: [null, Validators.required],
      Received_Id: [null, Validators.required]
    });
  }
  Export_To_Excel() {
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(this.Report_Data);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Received OR');
    xlsx.writeFile(wb, 'Health_Received_Report.xlsx');
    
  }

  Get_Report() {
    
    const controls = this.Report_FG.controls;
    if (controls.Policy_Date_To.value >= controls.Policy_Date_From.value) {
      var Insurer_Id: any = '';
      for (let k = 0; k < controls.Insurer_Id.value.length; k++) {
        each(this.All_Health_Insurer.filter(el => el.id == controls.Insurer_Id.value[k]), (Insurer_Ids: any) => {
          Insurer_Id = Insurer_Id + ',' + Insurer_Ids.id;
        });
      }
      Insurer_Id = Insurer_Id.replace(",", "");

      var Policy_Type_Id: any = '';
      for (let k = 0; k < controls.Policy_Type_Id.value.length; k++) {
        each(this.All_Health_Policy_Type.filter(el => el.id == controls.Policy_Type_Id.value[k]), (Policy_Type_Ids: any) => {
          Policy_Type_Id = Policy_Type_Id + ',' + Policy_Type_Ids.id;
        });
      }
      Policy_Type_Id = Policy_Type_Id.replace(",", "");

      var INS_Policy_Type_Id: any = '';
      for (let k = 0; k < controls.INS_Policy_Type_Id.value.length; k++) {
        each(this.INS_Policy_Type.filter(el => el.id == controls.INS_Policy_Type_Id.value[k]), (INS_Policy_Type_Ids: any) => {
          INS_Policy_Type_Id = INS_Policy_Type_Id + ',' + INS_Policy_Type_Ids.id;
        });
      }
      INS_Policy_Type_Id = INS_Policy_Type_Id.replace(",", "");

      this.Health_Received_Report.INS_Policy_Type_Id = INS_Policy_Type_Id;
      this.Health_Received_Report.Policy_Type_Id = Policy_Type_Id;
      this.Health_Received_Report.Insurer_Id = Insurer_Id;
      this.Health_Received_Report.Policy_Date_From = controls.Policy_Date_From.value;
      this.Health_Received_Report.Policy_Date_To = controls.Policy_Date_To.value;
      this.Health_Received_Report.Received = controls.Received_Id.value;

      this.spinner.show();
      this.Srv_Report_Received.ReceivedHealthReport(this.Health_Received_Report).subscribe((result: any) => {
        if (result.length > 0) {
          this.Is_Data = true;
          this.Report_Data = result;
          this.dataSource.data = this.Report_Data;
          this.setDataSource();

          var T_Premium: any = 0;
          var T_Comm_Amount_Regular: any = 0;
          var T_TDS_Amount_Regular: any = 0;
          var T_Net_Amount_Regular: any = 0;
          var T_Comm_Amount_Rewards: any = 0;
          var T_TDS_Amount_Rewards: any = 0;
          var T_Net_Amount_Rewards: any = 0;

          this.Report_Data.forEach(key => {
            T_Premium += parseFloat(key.Premium);
            T_Comm_Amount_Regular += parseFloat(key.FComm_Amount_Regular);
            T_TDS_Amount_Regular += parseFloat(key.FTDS_Amount_Regular);
            T_Net_Amount_Regular += parseFloat(key.Net_Amount_Regular);
            T_Comm_Amount_Rewards += parseFloat(key.FComm_Amount_Rewards);
            T_TDS_Amount_Rewards += parseFloat(key.FTDS_Amount_Rewards);
            T_Net_Amount_Rewards += parseFloat(key.Net_Amount_Rewards);
          });
          this.Tot_Premium = parseFloat(T_Premium).toFixed(2);
          this.Tot_Comm_Amount_Regular = parseFloat(T_Comm_Amount_Regular).toFixed(2);
          this.Tot_TDS_Amount_Regular = parseFloat(T_TDS_Amount_Regular).toFixed(2);
          this.Tot_Net_Amount_Regular = parseFloat(T_Net_Amount_Regular).toFixed(2);
          this.Tot_Comm_Amount_Rewards = parseFloat(T_Comm_Amount_Rewards).toFixed(2);
          this.Tot_TDS_Amount_Rewards = parseFloat(T_TDS_Amount_Rewards).toFixed(2);
          this.Tot_Net_Amount_Rewards = parseFloat(T_Net_Amount_Rewards).toFixed(2);
        }
        else {
          this.Is_Data = false;
        }
        this.spinner.hide();
      });
    }
    else {
      return;
    }
    
  }
}
