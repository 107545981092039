import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription, of, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout } from '../../../../core/auth';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { each, find, some } from 'lodash';

import { environment } from '../../../../../environments/environment';
import { Motor_OR_Master, selectMotorMasterActionLoading, selectAllInsurerMotor, selectAllInsurance } from '../../../../core/motor';
import { MotorMasterActionToggleLoading } from '../../../../core/motor/_actions/motor-master.actions';
import { MotorMasterService } from '../../../../core/motor/_services/motor-master.service';
import { MotorMasterConditionComponent } from '../motor-master-condition/motor-master-condition.component';
import { MotorORMaster, Motor_MAchivements } from '../../../../core/motor/_models/motor-master.model';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-motor-master-edit',
	templateUrl: './motor-master-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class MotorMasterEditComponent implements OnInit, OnDestroy {
	Is_New: any = 1;
	Edit_Row_No: any = 0;
	Rewards: any = [];
	displayedColumns = ['actions', 'Effect_Date', 'INS_Policy_Type', 'Insurance', 'OR_On', 'Apply_Capping', 'Capping'];
	dataSource: MatTableDataSource<Motor_OR_Master>;
	Is_History: any;
	_INS_Policy_Type: any;
	_Insurance: any;
	hasExists = false;
	_rewards_Data: Motor_OR_Master[] = [];
	Removed_Rewards_Data: Motor_OR_Master[] = [];
	Motor_MAchivements: Motor_MAchivements[] = [];
	MotorORMaster: MotorORMaster;
	public insurerCtrl: FormControl = new FormControl();
	public testControl: FormControl = new FormControl();
	public insurerFilterCtrl: FormControl = new FormControl();
	public filteredInsurers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	private _onDestroy = new Subject<void>();

	User_FG: FormGroup;
	showActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new MotorMasterActionToggleLoading({ isLoading: false });
	_NewRewards: number;
	Insurer_Id: number;
	AllInsurerMotor: Motor_OR_Master[] = [];
	AllInsurance: Motor_OR_Master[] = [];
	User_Id: string;
	hasFormErrors = false;
	viewLoading = false;
	private componentSubscriptions: Subscription;
	IsReadOnly: boolean;

	INS_Policy_Type = [
		{ id: 1, value: 'New' },
		{ id: 2, value: 'Renewal' },
		{ id: 3, value: 'Rollover' }
	];

	Insurance = [
		{ id: 1, value: 'TW' },
		{ id: 2, value: 'Car' },
		{ id: 3, value: 'GCV' },
		{ id: 4, value: 'PCV' },
		{ id: 5, value: 'Miscellaneous' }
	];

	OR_On = [
		{ id: 1, value: 'Comprehensive' },
		{ id: 2, value: 'Stand Alone Own Damage' },
		{ id: 3, value: 'Stand Alone Third Party' }
	];

	Apply_Capping = [
		{ id: 1, value: 'Yes' },
		{ id: 0, value: 'No' }
	];
	Edit_Reward: boolean = false;
	DataSourceIndex: any;

	constructor(public dialogRef: MatDialogRef<MotorMasterEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private User_FB: FormBuilder,
		private translate: TranslateService,
		private SrvMaster: MotorMasterService,
		private router: Router,
		private Srv_Master: MotorMasterService,
		private store: Store<AppState>,
		public dialog: MatDialog,
		public datepipe: DatePipe,
		private typesUtilsService: TypesUtilsService,
		private spinner: NgxSpinnerService) {
		this.Motor_MAchivements = [];
		this.Insurer_Id = data.Insurer_Id;
		this.IsReadOnly = data.IsReadOnly;
		this.Is_History = data.Is_History;
		this.MotorORMaster = new MotorORMaster();
		this.store.pipe(select(selectAllInsurerMotor)).subscribe(res => this.AllInsurerMotor = res);
		this.store.pipe(select(selectAllInsurance)).subscribe(res => this.AllInsurance = res);
		this.insurerCtrl.setValue(this.AllInsurerMotor);
		this.testControl.setValue(0);
		this.filteredInsurers.next(this.AllInsurerMotor.slice(0, 500));
		this.insurerFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterInsurers();
			});
	}

	ngOnInit() {
		
		this.Create_Form_Validation();
		this.dataSource = new MatTableDataSource();
		// this.store.pipe(select(selectMotorMasterActionLoading)).subscribe(res => this.viewLoading = res);
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		if (this.Is_History == 0) {
			if (this.Insurer_Id == 0) {
				this.New_Reward();
			}
			else {
				this.spinner.show();
				this.Srv_Master.Get_Edit_Data(this.Insurer_Id, this.User_Id).subscribe(result => {
					if (result["response"].length > 0) {
						this._rewards_Data = result["response"];
						this.insurerCtrl.setValue(this._rewards_Data[0].Insurer_Id);
						this.testControl.setValue(0);
						each(result["response"], (_rewardsData: Motor_OR_Master) => {
							this.dataSource = new MatTableDataSource(this._rewards_Data);
						});
					}
					this.spinner.hide();
				});
			}
		}
		else if (this.Is_History == 1) {
			this.spinner.show();
			this.Srv_Master.Get_History_Data(this.Insurer_Id).subscribe(result => {
				if (result["response"].length > 0) {
					this._rewards_Data = result["response"];
					this.insurerCtrl.setValue(this._rewards_Data[0].Insurer_Id);
					this.testControl.setValue(0);
					each(result["response"], (_rewardsData: Motor_OR_Master) => {
						this.dataSource = new MatTableDataSource(this._rewards_Data);
					});
				}
				this.spinner.hide();
			});
		}
		
	}
	Cancel_Condition() {
		this._NewRewards = undefined;
	}
	New_Reward() {
		
		this._INS_Policy_Type = [];
		this._Insurance = [];
		this.Is_New = 1;
		this.Edit_Reward = false;
		this.DataSourceIndex = null;
		this.User_FG.controls['Effect_Date'].setValue('');
		this.User_FG.controls['OR_On_Id'].setValue(0);
		this.User_FG.controls['Capping_Id'].setValue(0);
		this.User_FG.controls['Capping'].setValue('0');
		this._NewRewards = 1;
		
	}

	Create_Condition() {
		
		this.hasFormErrors = false;
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id

		const User_Controls = this.User_FG.controls;
		if (this.User_FG.invalid) {
			Object.keys(User_Controls).forEach(controlName =>
				User_Controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}
		const controls = this.User_FG.controls;

		const Temp_OR_On = this.OR_On.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.OR_On_Id.value) == i
		);
		const Temp_Capping = this.Apply_Capping.filter(
			(thing, i, arr) => arr.findIndex(t => t.id == controls.Capping_Id.value) == i
		);

		var Insurance_Name: any = '';
		for (let k = 0; k < this._Insurance.length; k++) {
			each(this.AllInsurance.filter(el => el.id == this._Insurance[k]), (Insurance_Nme: any) => {
				Insurance_Name = Insurance_Name + ', ' + Insurance_Nme.Insurance;
			});
		}

		Insurance_Name = Insurance_Name.replace(", ", "");

		var INS_Policy_Type_Name: any = '';
		for (let k = 0; k < this._INS_Policy_Type.length; k++) {
			each(this.INS_Policy_Type.filter(el => el.id == this._INS_Policy_Type[k]), (INS_Policy_Type_Nme: any) => {
				INS_Policy_Type_Name = INS_Policy_Type_Name + ', ' + INS_Policy_Type_Nme.value;
			});
		}

		INS_Policy_Type_Name = INS_Policy_Type_Name.replace(", ", "");
		if (controls.Capping.value > 100) {
			this.User_FG.controls['Capping'].setValue(100);
		}
		this.Rewards = {
			id: 0,
			Insurer_Id: this.insurerCtrl.value,
			Effect_Date: this.datepipe.transform(controls.Effect_Date.value, 'yyyy-MM-ddT00:00:00'),
			INS_Policy_Type: INS_Policy_Type_Name,
			Insurance: Insurance_Name,
			OR_On_Id: Temp_OR_On[0].id,
			OR_On: Temp_OR_On[0].value,
			Capping_Id: Temp_Capping[0].id,
			Capping: controls.Capping.value,
			Is_Deleted: true
		};

		var IsExists1 = 0;
		var IsExists2 = 0;
		if (this.Is_New == 0) {
			for (let i = 0; i < this._rewards_Data.length; i++) {
				if (this.Edit_Row_No != i && this._rewards_Data[i]["Effect_Date"] == this.Rewards["Effect_Date"] && this._rewards_Data[i]["Insurer_Id"] == this.Rewards["Insurer_Id"] && this._rewards_Data[i]["OR_On_Id"] == this.Rewards["OR_On_Id"]) {
					for (let j = 0; j < this._rewards_Data[i].Insurance_Id.length; j++) {
						for (let k = 0; k < this._Insurance.length; k++) {
							if (this._rewards_Data[i].Insurance_Id[j] == this._Insurance[k]) {
								IsExists1 = 1;
							}
						}
					}
					for (let j = 0; j < this._rewards_Data[i].INS_Policy_Type_Id.length; j++) {
						for (let k = 0; k < this._INS_Policy_Type.length; k++) {
							if (this._rewards_Data[i].INS_Policy_Type_Id[j] == this._INS_Policy_Type[k]) {
								IsExists2 = 1;
							}
						}
					}
				}
			}
		}
		else if (this.Is_New == 1) {
			for (let i = 0; i < this._rewards_Data.length; i++) {
				if (this._rewards_Data[i]["Effect_Date"] == this.Rewards["Effect_Date"] && this._rewards_Data[i]["Insurer_Id"] == this.Rewards["Insurer_Id"] && this._rewards_Data[i]["OR_On_Id"] == this.Rewards["OR_On_Id"]) {
					for (let j = 0; j < this._rewards_Data[i].Insurance_Id.length; j++) {
						for (let k = 0; k < this._Insurance.length; k++) {
							if (this._rewards_Data[i].Insurance_Id[j] == this._Insurance[k]) {
								IsExists1 = 1;
							}
						}
					}

					for (let j = 0; j < this._rewards_Data[i].INS_Policy_Type_Id.length; j++) {
						for (let k = 0; k < this._INS_Policy_Type.length; k++) {
							if (this._rewards_Data[i].INS_Policy_Type_Id[j] == this._INS_Policy_Type[k]) {
								IsExists2 = 1;
							}
						}
					}
				}
			}
		}

		if (IsExists1 == 1 && IsExists2 == 1) {
			this.hasExists = true;
			this.spinner.hide();
			return;
		}
		// if (IsExists1 == 1) {
		// 	this.hasExists = true;
		// 	return false;
		// }

		if (this.hasExists == false && this.Edit_Reward == false) {
			this._rewards_Data.push(this.Rewards as any);
			this._rewards_Data[this._rewards_Data.length - 1].INS_Policy_Type_Id = this._INS_Policy_Type;
			this._rewards_Data[this._rewards_Data.length - 1].Insurance_Id = this._Insurance;
		}
		else if (this.hasExists == false) {
			this._rewards_Data[this.DataSourceIndex]["Effect_Date"] = this.Rewards["Effect_Date"];
			this._rewards_Data[this.DataSourceIndex]["INS_Policy_Type"] = this.Rewards["INS_Policy_Type"];
			this._rewards_Data[this.DataSourceIndex]["Insurance"] = this.Rewards["Insurance"];
			this._rewards_Data[this.DataSourceIndex]["OR_On_Id"] = this.Rewards["OR_On_Id"];
			this._rewards_Data[this.DataSourceIndex]["OR_On"] = this.Rewards["OR_On"];
			this._rewards_Data[this.DataSourceIndex]["Capping_Id"] = this.Rewards["Capping_Id"];
			this._rewards_Data[this.DataSourceIndex]["Capping"] = this.Rewards["Capping"];
			this._rewards_Data[this.DataSourceIndex].INS_Policy_Type_Id = this._INS_Policy_Type;
			this._rewards_Data[this.DataSourceIndex].Insurance_Id = this._Insurance;
		}
		this.dataSource = new MatTableDataSource(this._rewards_Data);
		this._NewRewards = undefined;
		
	}
	private filterInsurers() {
		if (!this.AllInsurerMotor) {
			return;
		}
		let search = this.insurerFilterCtrl.value;
		if (!search) {
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredInsurers.next(
			this.AllInsurerMotor.filter(insurer => insurer.Insurer.toLowerCase().indexOf(search) > -1).slice(0, 500)
		);
	}
	// applyFilter(filterValue: string) {
	// 	filterValue = filterValue.trim(); // Remove whitespace
	// 	filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
	// }

	Create_Form_Validation() {
		
		this.User_FG = this.User_FB.group({
			Effect_Date: [null, Validators.required],
			INS_Policy_Type_Id: [null, Validators.required],
			Insurance_Id: [null, Validators.required],
			OR_On_Id: [null, Validators.required],
			Capping_Id: [null, Validators.required],
			Capping: [null, Validators.required]
		});
		
	}
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
	getTitle(): string {
		// if (this._rewards_Data.CRaw_Version > 0) {
		if (this.IsReadOnly == true && this.Is_History == 0) {
			this.User_FG.controls['Effect_Date'].disable();
			this.User_FG.controls['Capping'].disable();
			return 'View Rewards';
		}
		// 	else if (this.IsReadOnly == false) {
		// 		return `Edit Master '${this._rewards_Data.Insurer}'`;
		// 	}
		// }
		else if (this.Is_History == 0) {
			this.User_FG.controls['Effect_Date'].enable();
			this.User_FG.controls['Capping'].enable();
			return 'Edit Rewards';
		}
		else if (this.Is_History == 1) {
			return 'History Rewards';
		}

		return 'New Rewards';
	}
	getWaitDesciption() {
		// if (this._rewards_Data.CRaw_Version > 0) {
		// 	return this.translate.instant('COMMON.COMMON.UPDATE_RECORD.WAIT_DESCRIPTION', { name: 'Master' });
		// }
		this.spinner.hide();
		return this.translate.instant('COMMON.COMMON.NEW_RECORD.WAIT_DESCRIPTION', { name: 'Master' });
	}
	onSubmit() {
		
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id

		this.MotorORMaster.User_Id = this.User_Id;
		if (this.Insurer_Id == 0) {
			this.MotorORMaster.Insurer_Id = this._rewards_Data[0].Insurer_Id;
		}
		else {
			this.MotorORMaster.Insurer_Id = this.Insurer_Id;
		}

		this.MotorORMaster.Master_Data = this._rewards_Data;
		this.MotorORMaster.Removed_Rewards = this.Removed_Rewards_Data;

		this.updateMaster(this.MotorORMaster);
		
	}
	updateMaster(_Master: MotorORMaster) {
		
		this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.SrvMaster.updateMaster(_Master).subscribe(res => {
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		return this.hideActionLoadingDistpatcher;
	}
	createMaster(_Master: Motor_OR_Master) {
		
		this.store.dispatch(this.showActionLoadingDistpatcher);
		this.spinner.show();
		this.SrvMaster.createMaster(_Master).subscribe(res => {
			this.dialogRef.close(res);
			this.spinner.hide();
		});
		
		return this.hideActionLoadingDistpatcher;
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
		this.hasExists = false;
	}
	EditReward(Sel_rewards_Data: Motor_OR_Master, index) {
		
		this._INS_Policy_Type = [];
		this._Insurance = [];
		this.Is_New = 0;
		this.Edit_Row_No = index;
		this._INS_Policy_Type = Sel_rewards_Data.INS_Policy_Type_Id;
		this._Insurance = Sel_rewards_Data.Insurance_Id;
		this.Edit_Reward = true;
		this.DataSourceIndex = index;
		this.User_FG.controls['Effect_Date'].setValue(Sel_rewards_Data.Effect_Date);
		this.User_FG.controls['OR_On_Id'].setValue(Sel_rewards_Data.OR_On_Id);
		this.User_FG.controls['Capping_Id'].setValue(Sel_rewards_Data.Capping_Id);
		this.User_FG.controls['Capping'].setValue(Sel_rewards_Data.Capping);
		this._NewRewards = 1;
		
	}
	AddEditAchivements(Sel_rewards_Data: Motor_OR_Master, index, IsReadOnly) {
		
		const dialogRef = this.dialog.open(MotorMasterConditionComponent, { data: { Sel_rewards_Data, IsReadOnly: IsReadOnly, Is_History: this.Is_History } });
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this._rewards_Data[index].Motor_MAchivements = res._achivement_Data;
				this._rewards_Data[index].Removed_MAchivements = res.Removed_achivement_Data;
			}
		});
		
	}

	unassing(Sel_rewards_Data: Motor_OR_Master, index) {
		
		this.Rewards = [];

		for (let i = 0; i < this._rewards_Data.length; i++) {
			if (index == i) {
				this.Rewards = {
					id: this._rewards_Data[index]["id"],
					CRaw_Version: this._rewards_Data[index]["CRaw_Version"],
				};
				this._rewards_Data.splice(index, 1);
			}
		}
		this.Removed_Rewards_Data.push(this.Rewards as any);
		this.dataSource = new MatTableDataSource(this._rewards_Data);
		
	}

	on_Capping_Change(_Capping_Id) {
		if (_Capping_Id.value == 0) {
			this.User_FG.controls['Capping'].setValue('0');
		}
	}
}