// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService, Logout } from '../../../../core/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';


const DEMO_PARAMS = {
	OTP: '',
  password: '',
  confirmpassword: ''
};

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
	hide=true;
	hide1=true;
	// Public params
  resetPasswordForm: FormGroup;
  uid: any;  
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.resetPasswordForm = this.fb.group({
			OTP: [DEMO_PARAMS.OTP, Validators.compose([
				Validators.required,				
				Validators.minLength(6),
				Validators.maxLength(6) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.password, Validators.compose([
				Validators.required,
				Validators.pattern('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{0,}'),
				Validators.minLength(6),
				Validators.maxLength(20) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
      ],
      confirmpassword: [DEMO_PARAMS.confirmpassword, Validators.compose([
				Validators.required,
				Validators.pattern('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{0,}'),
				Validators.minLength(6),
				Validators.maxLength(20) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.resetPasswordForm.controls;
		/** check form */
		if (this.resetPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		// const password = controls.password.value;
    this.activatedRoute.queryParams.pipe(filter(params => params.uid)).subscribe(params => {this.uid = params.uid;});

		const authData = {
      User_Id: this.uid,
			OTP: controls.OTP.value,
      password: controls.password.value,
      confirmpassword:controls.confirmpassword.value
		};

		this.authService.Reset_Password(authData.User_Id, authData.OTP,authData.password).pipe(
			tap(response => {
				if (response) {
          if(response.Error == "0")
          {
            this.store.dispatch(new Logout());
					  this.authNoticeService.setNotice(this.translate.instant('AUTH.RESETPASSWORD.SUCCESS'), 'success');
            this.router.navigateByUrl('/auth/login');
          }
          else if(response.Error == "-1")
          {
					  this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {name: this.translate.instant('AUTH.INPUT.USERID')}), 'danger');
          }
          else if(response.Error == "-2")
          {
					  this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {name: this.translate.instant('AUTH.INPUT.OTP')}), 'danger');
          }
				} else {          
          this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.RESET_NOT_SUCCESS'), 'danger');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
