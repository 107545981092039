import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  templateUrl: './OR-master.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ORMasterComponent implements OnInit {  
  constructor() { }

  ngOnInit() {
  }

}
