// NGRX
import { Action } from '@ngrx/store';
// Models
import { UserPermissionModel } from '../_models/user_permission.model';

export enum UserPermissionActionTypes {
    AllUserPermissionsRequested = '[Init] All UserPermissions Requested',
    AllUserPermissionsLoaded = '[Init] All UserPermissions Loaded'
}

export class AllUserPermissionsRequested implements Action {
    readonly type = UserPermissionActionTypes.AllUserPermissionsRequested;
}

export class AllUserPermissionsLoaded implements Action {
    readonly type = UserPermissionActionTypes.AllUserPermissionsLoaded;
    constructor(public payload: { UserPermission: UserPermissionModel[] }) { }
}

export type UserPermissionActions = AllUserPermissionsRequested | AllUserPermissionsLoaded;
