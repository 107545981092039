// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { PermissionsState } from '../_reducers/user_permission.reducers';
import * as fromPermissions from '../_reducers/user_permission.reducers';

export const selectPermissionsState = createFeatureSelector<PermissionsState>('UserPermission');

export const selectUserPermissionById = (permissionId: number) => createSelector(
    selectPermissionsState,
    ps => ps.entities[permissionId]
);

export const selectAllUserPermissions = createSelector(
    selectPermissionsState,
    fromPermissions.selectAll
);

export const selectAllUserPermissionsIds = createSelector(
    selectPermissionsState,
    fromPermissions.selectIds
);

export const allUserPermissionsLoaded = createSelector(
    selectPermissionsState,
    ps  => ps._isAllPermissionsLoaded
);
