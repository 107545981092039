import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin, Observable, defer } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services

// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    DepartmentActionTypes,
    DepartmentsPageRequested,
    DepartmentsPageLoaded,
    AllDepartmentsRequested,
    AllDepartmentsLoaded,
    DepartmentsPageToggleLoading
} from '../_actions/department.actions';
import { of } from 'rxjs';
import { DepartmentsService } from '../_services/departments.service';
import { DepartmentModel } from '../_models/department.model';

@Injectable()
export class DepartmentEffects {
    showPageLoadingDistpatcher = new DepartmentsPageToggleLoading({ isLoading: true });

    @Effect()
    loadDepartmentsPage$ = this.actions$.pipe(
        ofType<DepartmentsPageRequested>(DepartmentActionTypes.DepartmentsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.departmentsService.loadDepartmentsPage(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new DepartmentsPageLoaded({
                departments: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    loadAllDepartments$ = this.actions$
        .pipe(
            ofType<AllDepartmentsRequested>(DepartmentActionTypes.AllDepartmentsRequested),
            mergeMap(() => this.departmentsService.getAllDepartments()),
            map((result: DepartmentModel[]) => {
                return  new AllDepartmentsLoaded({
                    departments: result
                });
            })
          );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllDepartmentsRequested());
    });
    constructor(private actions$: Actions, private departmentsService: DepartmentsService, private store: Store<AppState>) { }
}
