// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { MotorMasterState,InsurerState,InsuranceState } from '../_reducers/motor-master.reducers';
import * as fromMotorMaster from '../_reducers/motor-master.reducers';

import { Motor_OR_Master } from '../_models/motor-master.model';

export const selectMotorMasterState = createFeatureSelector<MotorMasterState>('_MotorMaster');
export const AllInsurerStateMotor = createFeatureSelector<InsurerState>('AllInsurerMotor');
export const AllInsuranceState = createFeatureSelector<InsuranceState>('AllInsurance');

export const selectMotorMasterById = (_MotorMasterId: number) => createSelector(
    selectMotorMasterState,
    _MotorMasterState => _MotorMasterState.entities[_MotorMasterId]
);

export const selectMotorMasterPageLoading = createSelector(
    selectMotorMasterState,
    _MotorMasterState => _MotorMasterState.listLoading
);

export const selectMotorMasterActionLoading = createSelector(
    selectMotorMasterState,
    _MotorMasterState => _MotorMasterState.actionsloading
);

export const selectMotorMasterShowInitWaitingMessage = createSelector(
    selectMotorMasterState,
    _MotorMasterState => _MotorMasterState.showInitWaitingMessage
);

export const selectAllInsurerMotor = createSelector(
    AllInsurerStateMotor,    
    fromMotorMaster.selectAll
);

export const selectAllInsurance = createSelector(
    AllInsuranceState,    
    fromMotorMaster.selectAll
);

export const selectAllMotorMaster = createSelector(
    selectMotorMasterState,
    fromMotorMaster.selectAll
);

export const selectMotorMasterInStore = createSelector(
    selectMotorMasterState,
    _MotorMasterState => {
        const items: Motor_OR_Master[] = [];
        each(_MotorMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Motor_OR_Master[] = httpExtension.sortArray(items, _MotorMasterState.lastQuery.sortField, _MotorMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, _MotorMasterState.totalCount, '');
    }
);
