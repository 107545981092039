// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SmeMasterActions, SmeMasterActionTypes } from '../_actions/sme-master.actions';
// Models
import { Sme_OR_Master } from '../_models/sme-master.model';
import { QueryParamsModel } from '../../_base/crud';

export interface SmeMasterState extends EntityState<Sme_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSmeMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface InsurerState extends EntityState<Sme_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSmeMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface Policy_TypeState extends EntityState<Sme_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSmeMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Sme_OR_Master> = createEntityAdapter<Sme_OR_Master>();

export const initialSmeMasterState: SmeMasterState = adapter.getInitialState({
    _SmeMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSmeMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialInsurerState: InsurerState = adapter.getInitialState({
    _SmeMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSmeMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialPolicy_TypeState: Policy_TypeState = adapter.getInitialState({
    _SmeMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSmeMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function AllInsurerReducerSme(state = initialInsurerState, action: SmeMasterActions): SmeMasterState {
    switch  (action.type) {
        case SmeMasterActionTypes.AllInsurerRequested:
            return {...state,
        };
        case SmeMasterActionTypes.AllInsurerLoaded:
            return adapter.addAll(action.payload._SmeMaster, {
                ...state,
            });
        default: return state;
    }
}

export function AllPolicy_TypeReducerSme(state = initialPolicy_TypeState, action: SmeMasterActions): SmeMasterState {
    switch  (action.type) {
        case SmeMasterActionTypes.AllPolicy_TypeRequested:
            return {...state,
        };
        case SmeMasterActionTypes.AllPolicy_TypeLoaded:
            return adapter.addAll(action.payload._SmeMaster, {
                ...state,
            });
        default: return state;
    }
}

export function _SmeMasterReducer(state = initialSmeMasterState, action: SmeMasterActions): SmeMasterState {
    switch  (action.type) {
        case SmeMasterActionTypes.SmeMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSmeMasterId: undefined
            };
        }
        case SmeMasterActionTypes.SmeMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        
        case SmeMasterActionTypes.SmeMasterPageLoaded: {
            return adapter.addMany(action.payload._SmeMaster, {
                ...initialSmeMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        default: return state;
    }
}

// export const getSmeMasterState = createFeatureSelector<Sme_OR_Master>('_SmeMaster');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
