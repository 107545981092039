import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { Health_OR_Upload_Mdl, HealthORUploadMdl, Health_OR_Upload_Status_Mdl } from '../_models/health-upload.model';
import { Health_Import_OR, Health_OR_Save } from '../_models/health-import.model';

@Injectable()
export class HealthReceivedService {
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

    Get_Excel_Files(Insurer_Id: number, Month_Id: number, Year: number): Observable<Health_OR_Upload_Mdl[]> {
        return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthReceived/Get_Excel_Files/' + Insurer_Id + '/' + Month_Id + '/' + Year)
    }

    Get_Excel_Sheet(Excel_Id: number): Observable<Health_OR_Upload_Mdl[]> {
        return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthReceived/Get_Excel_Sheet/' + Excel_Id)
    }

    Get_Excel_Sheet_Heading(Excel_Id: number, Sheet_Name:string): Observable<Health_OR_Upload_Mdl[]> {
        return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthReceived/Get_Excel_Sheet_Heading/' + Excel_Id+'/'+Sheet_Name)
    }

    Get_Excel_Sheet_Heading_Payout(Excel_Id: number, Sheet_Name:string): Observable<Health_OR_Upload_Mdl[]> {
        return this.http.get<Health_OR_Upload_Mdl[]>(Base_Url + 'HealthPayout/Get_Excel_Sheet_Heading/' + Excel_Id+'/'+Sheet_Name)
    }

    Get_Data_Payout(_Master: Health_Import_OR) {
        return this.http.post(Base_Url + 'HealthPayout/Get_Data', _Master)
    }

    Get_Data(_Master: Health_Import_OR) {
        return this.http.post(Base_Url + 'HealthReceived/Get_Data', _Master)
    }

    Save_OR_Receive(_Master: Health_OR_Save[]) {
        return this.http.post(Base_Url + 'HealthReceived/Save_Data', _Master)
    }
}