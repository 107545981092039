import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, 
		 Routes } from '@angular/router';
import { FormsModule, 
		 ReactiveFormsModule, 
		 FormControl} from '@angular/forms';
import { HttpClientModule, 
		 HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
import { HttpUtilsService, 
		 TypesUtilsService, 
		 InterceptService, 
		 LayoutUtilsService } from '../../../core/_base/crud';
import { ModuleGuard } from '../../../core/auth';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
const moment = _rollupMoment || _moment;
import { ActionNotificationComponent, 
		 ActiveInActiveSingleEntityDialogComponent, 
		 ActiveInActiveMultyEntityDialogComponent, 
		 FetchEntityDialogComponent, 
		 UpdateStatusDialogComponent } from '../../partials/content/crud';
		 import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	DateAdapter,
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS
} from '@angular/material';
import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SmeMasterService } from '../../../core/sme/_services/sme-master.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Input_Percentage } from './InputPercent.directive';
import { ORMasterComponent } from './OR-master.component';
import { ORMasterMasterComponent } from './OR-master-master/OR-master-master.component';
import { HealthMasterConditionComponent } from './health-master-condition/health-master-condition.component';
import { HealthMasterEditComponent } from './health-master-edit/health-master-edit.component';
import { HealthMasterListComponent } from './health-master-list/health-master-list.component';
import { MotorMasterConditionComponent } from './motor-master-condition/motor-master-condition.component';
import { MotorMasterEditComponent } from './motor-master-edit/motor-master-edit.component';
import { MotorMasterListComponent } from './motor-master-list/motor-master-list.component';
import { SmeMasterConditionComponent } from './sme-master-condition/sme-master-condition.component';
import { SmeMasterEditComponent } from './sme-master-edit/sme-master-edit.component';
import { SmeMasterListComponent } from './sme-master-list/sme-master-list.component';
import { NumberOnlyDirective } from './NumberOnly.Directive';
import { HealthMasterService } from '../../../core/health/_services/health-master.service';
import { MotorMasterService } from '../../../core/motor/_services/motor-master.service';
import { _SmeMasterReducer,AllInsurerReducerSme,AllPolicy_TypeReducerSme, SmeMasterEffects } from '../../../core/sme';
import { AllInsurerReducerMotor, MotorMasterEffects, _MotorMasterReducer,AllInsuranceReducer } from '../../../core/motor';
import { AllInsurerReducerHealth, AllPolicy_TypeReducerHealth, HealthMasterEffects, _HealthMasterReducer } from '../../../core/health';
import { NgxSpinnerModule } from 'ngx-spinner';

// tslint:disable-next-line:class-name
export const MY_FORMATS = {
	parse: {
	  dateInput: 'LL',
	},
	display: {
	  dateInput: 'DD-MMM-YYYY',
	  monthYearLabel: 'MMMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMM YYYY',
	},
  };

const routes: Routes = [
	{
		path: '',
		component: ORMasterComponent,		
		children: [
			{
				path: '',
				redirectTo: 'ORMaster',
				pathMatch: 'full'
			},
			{
				canActivate: [ModuleGuard],
				data: { moduleName: 'accessToORMasterSubMenu' },
				path: 'ORMaster',
				component: ORMasterMasterComponent
			},
			// {
			// 	path: 'ORUpload',
			// 	component: SmeORUploadListComponent
			// },			
			// {
			// 	path: 'ORReceived',
			// 	component: SmeORImportComponent
			// },			
		]
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		NgxSpinnerModule,
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		NgbProgressbarModule,
		StoreModule.forFeature('_SmeMaster', _SmeMasterReducer),
		StoreModule.forFeature('AllInsurerSme', AllInsurerReducerSme),
		StoreModule.forFeature('AllPolicy_Type_Sme', AllPolicy_TypeReducerSme),
		EffectsModule.forFeature([SmeMasterEffects]),

		StoreModule.forFeature('_MotorMaster', _MotorMasterReducer),
		StoreModule.forFeature('AllInsurerMotor', AllInsurerReducerMotor),
		StoreModule.forFeature('AllInsurance', AllInsuranceReducer),
		EffectsModule.forFeature([MotorMasterEffects]),

		StoreModule.forFeature('_HealthMaster', _HealthMasterReducer),
		StoreModule.forFeature('AllInsurerHealth', AllInsurerReducerHealth),
		StoreModule.forFeature('AllPolicy_Type_Health', AllPolicy_TypeReducerHealth),
		EffectsModule.forFeature([HealthMasterEffects]),
		NgxMatSelectSearchModule
	],
	providers: [
		ModuleGuard,
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		SmeMasterService,
		HealthMasterService,
		MotorMasterService,
		TypesUtilsService,
		LayoutUtilsService,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	],
	entryComponents: [
		ActionNotificationComponent,
		ActiveInActiveSingleEntityDialogComponent,
		ActiveInActiveMultyEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		HealthMasterConditionComponent,
		HealthMasterEditComponent,
		
		MotorMasterConditionComponent,
		MotorMasterEditComponent,
		
		SmeMasterConditionComponent,
		SmeMasterEditComponent,
		
	],
	declarations: [
		ORMasterComponent,
		Input_Percentage,
		NumberOnlyDirective,
		ORMasterMasterComponent,
		HealthMasterConditionComponent,
		HealthMasterEditComponent,
		HealthMasterListComponent,
		MotorMasterConditionComponent,
		MotorMasterEditComponent,
		MotorMasterListComponent,
		SmeMasterConditionComponent,
		SmeMasterEditComponent,
		SmeMasterListComponent
	]
})
export class ORMasterModule {	
	constructor(private dateAdapter: DateAdapter<Date>) {
		var date = new FormControl(moment());
	}
}
