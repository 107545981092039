import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ActionNotificationComponent,
	ActiveInActiveSingleEntityDialogComponent,
	ActiveInActiveMultyEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../../../views/partials/content/crud';

export enum MessageType {
	Create,
	Read,
	Update,
	Delete
}

@Injectable()
export class LayoutUtilsService {
	constructor(private snackBar: MatSnackBar,
		           private dialog: MatDialog) { }
	showActionNotification(
		_color:any,
		_message: string,
		_type: MessageType = MessageType.Create,
		_duration: number = 10000,
		_showCloseButton: boolean = true,
		_verticalPosition: 'top' | 'bottom' = 'bottom'
	) {
		const _data = {
			message: _message,
			snackBar: this.snackBar,
			showCloseButton: _showCloseButton,
			verticalPosition: _verticalPosition,
			type: _type,
			color:_color
		};
		return this.snackBar.openFromComponent(ActionNotificationComponent, {
			duration: _duration,
			data: _data,
			verticalPosition: _verticalPosition
		});
	}
	DepartmentActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	DepartmentActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	RoleActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	RoleActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	UserActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	UserActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	MotorMasterActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	MotorMasterActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	MotorUpdateActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	MotorUpdateActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	HealthUpdateActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	HealthUpdateActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	SmeUpdateActiveInActiveSingleElement(title: string = '', description: string = '', waitDesciption: string = '', Sel_data : any,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption ,Sel_data,Page_Name,Rec_Status,Back_Colour},
			width: '440px'
		});
	}

	SmeUpdateActiveInActiveMultyElement(title,waitDesciption: string = '', statuses, messages,Page_Name : string, Rec_Status: string,Back_Colour) {
		return this.dialog.open(ActiveInActiveMultyEntityDialogComponent, {
			data: { title,waitDesciption,statuses, messages,Page_Name,Rec_Status,Back_Colour },
			width: '480px'
		});
	}

	fetchElements(_data) {
		return this.dialog.open(FetchEntityDialogComponent, {
			data: _data,
			width: '400px'
		});
	}
	
	deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
		return this.dialog.open(ActiveInActiveSingleEntityDialogComponent, {
			data: { title, description, waitDesciption},
			width: '440px'
		});
	}
	updateStatusForEntities(title, statuses, messages) {
		return this.dialog.open(UpdateStatusDialogComponent, {
			data: { title, statuses, messages },
			width: '480px'
		});
	}
}
