// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { DepartmentModel } from '../_models/department.model';

export enum DepartmentActionTypes {
    DepartmentsPageRequested = '[Departments List Page] Departments Page Requested',        
    DepartmentsPageLoaded = '[Departments API] Departments Page Loaded',
    DepartmentsPageToggleLoading = '[Departments] Departments Page Toggle Loading',
    DepartmentActionToggleLoading = '[Departments] Departments Action Toggle Loading',
    AllDepartmentsRequested = '[Init] All Departments Requested',
    AllDepartmentsLoaded = '[Init] All Departments Loaded'
}

export class DepartmentsPageRequested implements Action {
    readonly type = DepartmentActionTypes.DepartmentsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class DepartmentsPageLoaded implements Action {
    readonly type = DepartmentActionTypes.DepartmentsPageLoaded;
    constructor(public payload: { departments: DepartmentModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class DepartmentsPageToggleLoading implements Action {
    readonly type = DepartmentActionTypes.DepartmentsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DepartmentActionToggleLoading implements Action {
    readonly type = DepartmentActionTypes.DepartmentActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllDepartmentsRequested implements Action {
    readonly type = DepartmentActionTypes.AllDepartmentsRequested;
}

export class AllDepartmentsLoaded implements Action {
    readonly type = DepartmentActionTypes.AllDepartmentsLoaded;
    constructor(public payload: { departments: DepartmentModel[] }) { }
}

export type DepartmentActions = AllDepartmentsRequested
| AllDepartmentsLoaded
| DepartmentsPageRequested
| DepartmentsPageLoaded
| DepartmentsPageToggleLoading
| DepartmentActionToggleLoading;
