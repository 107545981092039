import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Base_Url } from '../../Constant/Constants';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { Motor_OR_Upload_Mdl, MotorORUploadMdl, Motor_OR_Upload_Status_Mdl } from '../_models/motor-upload.model';
import { Motor_Import_OR, Motor_OR_Save } from '../_models/motor-import.model';

@Injectable()
export class MotorReceivedService {
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

    Get_Excel_Files(Insurer_Id: number, Month_Id: number, Year: number): Observable<Motor_OR_Upload_Mdl[]> {
        return this.http.get<Motor_OR_Upload_Mdl[]>(Base_Url + 'MotorReceived/Get_Excel_Files/' + Insurer_Id + '/' + Month_Id + '/' + Year)
    }

    Get_Excel_Sheet(Excel_Id: number): Observable<Motor_OR_Upload_Mdl[]> {
        return this.http.get<Motor_OR_Upload_Mdl[]>(Base_Url + 'MotorReceived/Get_Excel_Sheet/' + Excel_Id)
    }

    Get_Excel_Sheet_Heading(Excel_Id: number, Sheet_Name:string): Observable<Motor_OR_Upload_Mdl[]> {
        return this.http.get<Motor_OR_Upload_Mdl[]>(Base_Url + 'MotorReceived/Get_Excel_Sheet_Heading/' + Excel_Id+'/'+Sheet_Name)
    }

    Get_Excel_Sheet_Heading_Payout(Excel_Id: number, Sheet_Name:string): Observable<Motor_OR_Upload_Mdl[]> {
        return this.http.get<Motor_OR_Upload_Mdl[]>(Base_Url + 'MotorPayout/Get_Excel_Sheet_Heading/' + Excel_Id+'/'+Sheet_Name)
    }

    Get_Data(_Master: Motor_Import_OR) {
        return this.http.post(Base_Url + 'MotorReceived/Get_Data', _Master)
    }
    Get_Data_Payout(_Master: Motor_Import_OR) {
        return this.http.post(Base_Url + 'MotorPayout/Get_Data', _Master)
    }

    Save_OR_Receive(_Master: Motor_OR_Save[]) {
        return this.http.post(Base_Url + 'MotorReceived/Save_Data', _Master)
    }
}