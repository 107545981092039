import { Store, select } from '@ngrx/store';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppState } from '../../../../../core/reducers';
import { Subscription } from 'rxjs';
import { DepartmentActionToggleLoading } from '../../../../../core/master/_actions/department.actions';
import { DepartmentsService } from '../../../../../core/master/_services/departments.service';
import { AuthService, currentUser, Logout } from '../../../../../core/auth';
import { UsersService } from '../../../../../core/auth/_services/user.service';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { MotorMasterService } from '../../../../../core/motor/_services/motor-master.service';
import { MotorUpdateService } from '../../../../../core/motor/_services/motor-update.service';

@Component({
	selector: 'kt-active-inactive-single-entity-dialog',
	templateUrl: './active-inactive-single-entity-dialog.component.html'
})
export class ActiveInActiveSingleEntityDialogComponent implements OnInit {
	User_Id : string;
	showActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new DepartmentActionToggleLoading({ isLoading: false });
	viewLoading = false;
	componentSubscriptions: Subscription;
	constructor(
		private store: Store<AppState>,
		private Srv_Department: DepartmentsService,
		private Srv_Role: AuthService,
		private Srv_User: UsersService,
		private Srv_MMaster: MotorMasterService,
		private Srv_MUpdate: MotorUpdateService,
		public dialogRef: MatDialogRef<ActiveInActiveSingleEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.data = data;
	}
	ngOnInit() {
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	onYesClick(): void {
		const User_Id = localStorage.getItem(environment.User_Id);
		if (!User_Id)
			this.store.dispatch(new Logout());
		else
			this.User_Id = User_Id
		this.viewLoading = true;
		if (this.data.Page_Name == 'Department') {
			const _department = [];
			if (this.data.Rec_Status == 'InActive') {
				_department.push({
					Status: 0,
					Raw_Version: this.data.Sel_data.Raw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				_department.push({
					Status: 1,
					Raw_Version: this.data.Sel_data.Raw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_Department.updateStatusDepartment(_department).subscribe(res => {
					this.dialogRef.close(res);
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'Role') {
			const _role = [];
			if (this.data.Rec_Status == 'InActive') {
				_role.push({
					Status: 0,
					Raw_Version: this.data.Sel_data.Raw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				_role.push({
					Status: 1,
					Raw_Version: this.data.Sel_data.Raw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_Role.updateStatusRole(_role).subscribe(res => {
					this.dialogRef.close(res);
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'User') {
			const _user = [];
			if (this.data.Rec_Status == 'InActive') {
				_user.push({
					Status: 0,
					CRaw_Version: this.data.Sel_data.CRaw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				_user.push({
					Status: 1,
					CRaw_Version: this.data.Sel_data.CRaw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_User.updateStatusUser(_user).subscribe(res => {
					this.dialogRef.close(res);
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'MotorMaster') {
			const _mMaster = [];
			if (this.data.Rec_Status == 'InActive') {
				_mMaster.push({
					Status: 0,
					CRaw_Version: this.data.Sel_data.CRaw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				_mMaster.push({
					Status: 1,
					CRaw_Version: this.data.Sel_data.CRaw_Version.toString(),
					User_Id:this.User_Id
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_MMaster.updateStatusMaster(_mMaster).subscribe(res => {
					this.dialogRef.close(res);
			});
			this.hideActionLoadingDistpatcher;
		}
		else if (this.data.Page_Name == 'Uploaded Excel') {
			const _mMaster = [];
			if (this.data.Rec_Status == 'InActive') {
				_mMaster.push({
					Status: 0,
					Insurer_Id: this.data.Sel_data.id,
					User_Id:this.User_Id
				});
			}
			else if (this.data.Rec_Status == 'Active') {
				_mMaster.push({
					Status: 1,
					Insurer_Id: this.data.Sel_data.id,
					User_Id:this.User_Id
				});
			}
			this.store.dispatch(this.showActionLoadingDistpatcher);
			this.Srv_MUpdate.Delete_Motor_Upload_Data(_mMaster).subscribe(res => {
					this.dialogRef.close(res);
			});
			this.hideActionLoadingDistpatcher;
		}
		else
			this.dialogRef.close(true);
	}
}
