// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { DepartmentActions, DepartmentActionTypes } from '../_actions/department.actions';
// Models
import { DepartmentModel } from '../_models/department.model';
import { QueryParamsModel } from '../../_base/crud';

export interface DepartmentsState extends EntityState<DepartmentModel> {
    _isAllDepartmentsLoaded: boolean;
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDepartmentId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<DepartmentModel> = createEntityAdapter<DepartmentModel>();

export const initialDepartmentsState: DepartmentsState = adapter.getInitialState({
    _isAllDepartmentsLoaded: false,
    departmentForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDepartmentId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function departmentsReducer(state = initialDepartmentsState, action: DepartmentActions): DepartmentsState {
    switch  (action.type) {
        case DepartmentActionTypes.DepartmentsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDepartmentId: undefined
            };
        }
        case DepartmentActionTypes.DepartmentActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        
        case DepartmentActionTypes.DepartmentsPageLoaded: {
            return adapter.addMany(action.payload.departments, {
                ...initialDepartmentsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        case DepartmentActionTypes.AllDepartmentsRequested:
            return {...state,
                _isAllDepartmentsLoaded: false
        };
        case DepartmentActionTypes.AllDepartmentsLoaded:
            return adapter.addAll(action.payload.departments, {
                ...state,
                _isAllDepartmentsLoaded: true
            });

        default: return state;
    }
}

export const getDepartmentState = createFeatureSelector<DepartmentModel>('departments');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
