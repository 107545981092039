// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SmeMasterState,InsurerState,Policy_TypeState } from '../_reducers/sme-master.reducers';
import * as fromSmeMaster from '../_reducers/sme-master.reducers';

import { Sme_OR_Master } from '../_models/sme-master.model';

export const selectSmeMasterState = createFeatureSelector<SmeMasterState>('_SmeMaster');
export const AllInsurerStateSme = createFeatureSelector<InsurerState>('AllInsurerSme');
export const AllPolicy_TypeStateSme = createFeatureSelector<Policy_TypeState>('AllPolicy_Type_Sme');

export const selectSmeMasterById = (_SmeMasterId: number) => createSelector(
    selectSmeMasterState,
    _SmeMasterState => _SmeMasterState.entities[_SmeMasterId]
);

export const selectSmeMasterPageLoading = createSelector(
    selectSmeMasterState,
    _SmeMasterState => _SmeMasterState.listLoading
);

export const selectSmeMasterActionLoading = createSelector(
    selectSmeMasterState,
    _SmeMasterState => _SmeMasterState.actionsloading
);

export const selectSmeMasterShowInitWaitingMessage = createSelector(
    selectSmeMasterState,
    _SmeMasterState => _SmeMasterState.showInitWaitingMessage
);

export const selectAllInsurerSme = createSelector(
    AllInsurerStateSme,    
    fromSmeMaster.selectAll
);

export const selectAllPolicy_Type = createSelector(
    AllPolicy_TypeStateSme,    
    fromSmeMaster.selectAll
);

export const selectAllSmeMaster = createSelector(
    selectSmeMasterState,
    fromSmeMaster.selectAll
);

export const selectSmeMasterInStore = createSelector(
    selectSmeMasterState,
    _SmeMasterState => {
        const items: Sme_OR_Master[] = [];
        each(_SmeMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Sme_OR_Master[] = httpExtension.sortArray(items, _SmeMasterState.lastQuery.sortField, _SmeMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, _SmeMasterState.totalCount, '');
    }
);
