import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, QueryParamsModel } from '../../../../core/_base/crud';
import { AuthService } from '../../../../core/auth';
import { Health_OR_Master, HealthMasterDataSource, HealthMasterPageRequested } from '../../../../core/health';
import { HealthMasterEditComponent } from '../health-master-edit/health-master-edit.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-health-master-list',
	templateUrl: './health-master-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthMasterListComponent implements OnInit, OnDestroy {
	dataSource: HealthMasterDataSource;
	// displayedColumns = ['select', 'actions', 'Insurer', 'status'];
	displayedColumns = ['actions', 'Insurer'];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	filterStatus = '';
	selection = new SelectionModel<Health_OR_Master>(true, []);
	ActiveSelection = '';
	InActiveSelection = '';
	MasterResult: Health_OR_Master[] = [];
	private subscriptions: Subscription[] = [];
	componentSubscriptions: Subscription;

	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private Svc_Auth: AuthService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private spinner: NgxSpinnerService
	) { }

	ngOnInit() {
		
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadMasterList())
		)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadMasterList();
			})
		)
			.subscribe();
		this.subscriptions.push(searchSubscription);
		this.dataSource = new HealthMasterDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.MasterResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		this.loadMasterList();
		
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	loadMasterList() {
		
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		this.store.dispatch(new HealthMasterPageRequested({ page: queryParams }));
		this.selection.clear();
		
	}

	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		if (this.filterStatus && this.filterStatus.length > 0) {
			filter.status = +this.filterStatus;
		}
		filter.Insurer = searchText;
		return filter;
	}

	Add_Edit_Master() {
		
		const newMaster = new Health_OR_Master();
		newMaster.clear();
		this.AddEditMaster(0, false, 0);
		
	}

	AddEditMaster(Insurer_Id: number, IsReadOnly, Is_History) {
		
		let saveMessageTranslateParam = 'COMMON.COMMON.';
		let notsaveMessageTranslateParam = 'COMMON.COMMON.';
		saveMessageTranslateParam += Insurer_Id > 0 ? 'UPDATE_RECORD.SUCCESS_MESSAGE' : 'NEW_RECORD.SUCCESS_MESSAGE';
		notsaveMessageTranslateParam += Insurer_Id > 0 ? 'UPDATE_RECORD.NOT_SAVED_MESSAGE' : 'NEW_RECORD.NOT_SAVED_MESSAGE';
		const _saveMessage = this.translate.instant(saveMessageTranslateParam.toString(), { name: 'Master' });
		const _NotsaveMessage = this.translate.instant(notsaveMessageTranslateParam.toString(), { name: 'Master' });
		const dialogRef = this.dialog.open(HealthMasterEditComponent, { data: { Insurer_Id, IsReadOnly: IsReadOnly, Is_History: Is_History } });
		dialogRef.disableClose = true;
		dialogRef.afterClosed().subscribe(res => {
			if (res > 0) {
				this.layoutUtilsService.showActionNotification("success", _saveMessage);
				this.loadMasterList();
			}
			else if (res == 0) {
				this.layoutUtilsService.showActionNotification("warn", _NotsaveMessage);
				this.loadMasterList();
			}
			else if (res == -9) {
				this.layoutUtilsService.showActionNotification("primary", this.translate.instant('COMMON.COMMON.UPDATE_RECORD.USER_SAVED_MESSAGE', { name: 'Master' }));
				this.loadMasterList();
			}
			else if (res == -10) {
				this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
				this.loadMasterList();
			}
			else
				return;
		});
		
	}

	// Update_Status_Single(Master: Health_OR_Master, _Action_For: string) {
	// 	var _title: string;
	// 	var _description: string;
	// 	var _waitDesciption: string;
	// 	var _SuccessMessage: string;
	// 	var _Not_Saved_Message: string;
	// 	var _User_Saved_Message: string;
	// 	var _Btn_Color: string;

	// 	if (_Action_For == 'Active') {
	// 		_Btn_Color = 'success';
	// 		_title = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.TITLE', { name: 'Master' });
	// 		_description = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.DESCRIPTION', { name: 'Master' });
	// 		_waitDesciption = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Master' });
	// 		_SuccessMessage = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Master' });
	// 		_Not_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Master' });
	// 		_User_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Master' });
	// 	}
	// 	else if (_Action_For == 'InActive') {
	// 		_Btn_Color = 'warn';
	// 		_title = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.TITLE', { name: 'Master' });
	// 		_description = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.DESCRIPTION', { name: 'Master' });
	// 		_waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.WAIT_DESCRIPTION', { name: 'Master' });
	// 		_SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.SUCCESS_MESSAGE', { name: 'Master' });
	// 		_Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.NOT_SAVED_MESSAGE', { name: 'Master' });
	// 		_User_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_SINGLE.USER_SAVED_MESSAGE', { name: 'Master' });
	// 	}
	// 	const dialogRef = this.layoutUtilsService.HealthMasterActiveInActiveSingleElement(_title, _description, _waitDesciption, Master, 'HealthMaster', _Action_For, _Btn_Color);
	// 	dialogRef.disableClose = true;
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (res > 0) {
	// 			this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
	// 			this.loadMasterList();
	// 		}
	// 		else if (res == 0) {
	// 			this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
	// 			this.loadMasterList();
	// 			return;
	// 		}
	// 		else if (res == -9) {
	// 			this.layoutUtilsService.showActionNotification("primary", _User_Saved_Message);
	// 			return;
	// 		}
	// 		else if (res == -10) {
	// 			this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
	// 			return;
	// 		}
	// 		else
	// 			return;
	// 	});
	// }

	// Update_Status_Multy(_Action_For: string) {
	// 	var _title: string;
	// 	var _waitDesciption: string;
	// 	var _SuccessMessage: string;
	// 	var _Not_Saved_Message: string;
	// 	var _Not_All_Saved_Message: string;
	// 	var _Btn_Color: string;
	// 	const _statuses = [{ value: 1, text: 'Active' }, { value: 0, text: 'InActive' }];
	// 	const _messages = [];
	// 	if (_Action_For == 'Active') {
	// 		this.selection.selected.forEach(elem => {
	// 			if (elem.status == 0) {
	// 				_messages.push({
	// 					text: `${elem.Insurer}`,
	// 					CRaw_Version: elem.CRaw_Version.toString(),
	// 					status: elem.status,
	// 					statusTitle: this.getItemStatusString(elem.status),
	// 					statusCssClass: this.getItemCssClassByStatus(elem.status)
	// 				});
	// 			}
	// 		});
	// 	}
	// 	else if (_Action_For == 'InActive') {
	// 		this.selection.selected.forEach(elem => {
	// 			if (elem.status == 1) {
	// 				_messages.push({
	// 					text: `${elem.Insurer}`,
	// 					CRaw_Version: elem.CRaw_Version.toString(),
	// 					status: elem.status,
	// 					statusTitle: this.getItemStatusString(elem.status),
	// 					statusCssClass: this.getItemCssClassByStatus(elem.status)
	// 				});
	// 			}
	// 		});
	// 	}
	// 	if (_Action_For == 'Active') {
	// 		_Btn_Color = 'success';
	// 		_title = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.TITLE', { name: 'Master' });
	// 		_SuccessMessage = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.SUCCESS_MESSAGE', { name: 'Master' });
	// 		_Not_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.NOT_SAVED_MESSAGE', { name: 'Master' });
	// 		_Not_All_Saved_Message = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.NOT_ALL_SAVED_MESSAGE', { name: 'Master' });
	// 		_waitDesciption = this.translate.instant('COMMON.COMMON.ACTIVE_MULTY.WAIT_DESCRIPTION', { name: 'Master' });
	// 	}
	// 	else if (_Action_For == 'InActive') {
	// 		_Btn_Color = 'warn';
	// 		_title = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.TITLE', { name: 'Master' });
	// 		_SuccessMessage = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.SUCCESS_MESSAGE', { name: 'Master' });
	// 		_Not_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_SAVED_MESSAGE', { name: 'Master' });
	// 		_Not_All_Saved_Message = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.NOT_ALL_SAVED_MESSAGE', { name: 'Master' });
	// 		_waitDesciption = this.translate.instant('COMMON.COMMON.INACTIVE_MULTY.WAIT_DESCRIPTION', { name: 'Master' });
	// 	}
	// 	const dialogRef = this.layoutUtilsService.HealthMasterActiveInActiveMultyElement(_title, _waitDesciption, _statuses, _messages, 'HealthMaster', _Action_For, _Btn_Color);
	// 	dialogRef.disableClose = true;
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (res) {
	// 			if (res.Rtn_Result == 0) {
	// 				this.layoutUtilsService.showActionNotification("warn", _Not_Saved_Message);
	// 				this.loadMasterList();
	// 				return;
	// 			}
	// 			else if (res.Rtn_Result == -10) {
	// 				this.layoutUtilsService.showActionNotification("warn", this.translate.instant('COMMON.COMMON.ERROR_MESSAGE'));
	// 				return;
	// 			}
	// 			else if (res.Rtn_Result == res.Selected_Row) {
	// 				this.layoutUtilsService.showActionNotification("success", _SuccessMessage);
	// 				this.selection.clear();
	// 				this.loadMasterList();
	// 			}
	// 			else if (res.Rtn_Result != res.Selected_Row) {
	// 				this.layoutUtilsService.showActionNotification("primary", _Not_All_Saved_Message.replace('{{number}}', res.Rtn_Result));
	// 				this.loadMasterList();
	// 			}
	// 		}
	// 		else
	// 			return;
	// 	});
	// }

	// isAllSelected(): boolean {
	// 	this.ActiveSelection = '';
	// 	this.InActiveSelection = '';
	// 	if (this.selection.selected.length > 0) {
	// 		this.selection.selected.forEach(elem => {
	// 			if (elem.status == 0)
	// 				this.ActiveSelection = 'Active';
	// 			else if (elem.status == 1)
	// 				this.InActiveSelection = 'InActive';
	// 		});
	// 	}

	// 	const numSelected = this.selection.selected.length;
	// 	const numRows = this.MasterResult.length;
	// 	return numSelected === numRows;
	// }

	// masterToggle() {
	// 	this.ActiveSelection = '';
	// 	this.InActiveSelection = '';
	// 	if (this.selection.selected.length === this.MasterResult.length) {
	// 		this.selection.clear();
	// 	} else {
	// 		this.MasterResult.forEach(row => this.selection.select(row));
	// 	}
	// }

	// getItemCssClassByStatus(status: number = 0): string {
	// 	switch (status) {
	// 		case 0:
	// 			return 'danger';
	// 		case 1:
	// 			return 'success';
	// 	}
	// 	return '';
	// }

	// getItemStatusString(status: number = 0): string {
	// 	switch (status) {
	// 		case 0:
	// 			return 'InActive';
	// 		case 1:
	// 			return 'Active';
	// 	}
	// 	return '';
	// }
}
