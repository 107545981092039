// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { MotorMasterActions, MotorMasterActionTypes } from '../_actions/motor-master.actions';
// Models
import { Motor_OR_Master } from '../_models/motor-master.model';
import { QueryParamsModel } from '../../_base/crud';

export interface MotorMasterState extends EntityState<Motor_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMotorMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface InsurerState extends EntityState<Motor_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMotorMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export interface InsuranceState extends EntityState<Motor_OR_Master> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMotorMasterId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Motor_OR_Master> = createEntityAdapter<Motor_OR_Master>();

export const initialMotorMasterState: MotorMasterState = adapter.getInitialState({
    _MotorMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMotorMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialInsurerState: InsurerState = adapter.getInitialState({
    _MotorMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMotorMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export const initialInsuranceState: InsuranceState = adapter.getInitialState({
    _MotorMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMotorMasterId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function AllInsurerReducerMotor(state = initialInsurerState, action: MotorMasterActions): MotorMasterState {
    switch  (action.type) {
        case MotorMasterActionTypes.AllInsurerRequested:
            return {...state,
        };
        case MotorMasterActionTypes.AllInsurerLoaded:
            return adapter.addAll(action.payload._MotorMaster, {
                ...state,
            });
        default: return state;
    }
}

export function AllInsuranceReducer(state = initialInsuranceState, action: MotorMasterActions): MotorMasterState {
    switch  (action.type) {
        case MotorMasterActionTypes.AllInsuranceRequested:
            return {...state,
        };
        case MotorMasterActionTypes.AllInsuranceLoaded:
            return adapter.addAll(action.payload._MotorMaster, {
                ...state,
            });
        default: return state;
    }
}

export function _MotorMasterReducer(state = initialMotorMasterState, action: MotorMasterActions): MotorMasterState {
    switch  (action.type) {
        case MotorMasterActionTypes.MotorMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMotorMasterId: undefined
            };
        }
        case MotorMasterActionTypes.MotorMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        
        case MotorMasterActionTypes.MotorMasterPageLoaded: {
            return adapter.addMany(action.payload._MotorMaster, {
                ...initialMotorMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        default: return state;
    }
}

// export const getMotorMasterState = createFeatureSelector<Motor_OR_Master>('_MotorMaster');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
