import { BaseModel } from '../../_base/crud';

export class RoleModel extends BaseModel {
	id: number;
    IsReadOnly: boolean;
	Is_Not_Removable: boolean;
	Is_Not_Active: boolean;    
	Department_Id: any;
	Department_Name: string;
    Role_Name: string;
    RolePermissionData: number[];
	status: number;	
	User_Id: string;
	Raw_Version: number;	

	clear() {
		this.IsReadOnly=false;
		this.Is_Not_Removable=false;
		this.Is_Not_Active=false;
		this.Role_Name = '';
		this.Department_Name = '';
        this.RolePermissionData = [];
		this.status = 1;
		this.id=undefined;
		this.Department_Id=undefined;
		this.User_Id=undefined;
		this.Raw_Version=0;
    }
}

export class RoleStatusModel  extends BaseModel {	
	Status: number;
	Raw_Version: number;
	User_Id: string;
	clear() {
		this.Status=0;
		this.Raw_Version=0;		
		this.User_Id=undefined;
	}
}
