// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
import { Health_OR_Master } from '../_models/health-master.model';
// Models


export enum HealthMasterActionTypes {
    HealthMasterPageRequested = '[HealthMaster List Page] HealthMaster Page Requested',        
    HealthMasterPageLoaded = '[HealthMaster API] HealthMaster Page Loaded',
    HealthMasterPageToggleLoading = '[HealthMaster] HealthMaster Page Toggle Loading',
    HealthMasterActionToggleLoading = '[HealthMaster] HealthMaster Action Toggle Loading',
    AllInsurerRequested = '[Init] All Insurer HealthMaster Requested',
    AllInsurerLoaded = '[Init] All Insurer HealthMaster Loaded',
    AllPolicy_TypeRequested = '[Init] All Policy Type HealthMaster Requested',
    AllPolicy_TypeLoaded = '[Init] All Policy Type HealthMaster Loaded'
}

export class HealthMasterPageRequested implements Action {
    readonly type = HealthMasterActionTypes.HealthMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class HealthMasterPageLoaded implements Action {
    readonly type = HealthMasterActionTypes.HealthMasterPageLoaded;
    constructor(public payload: { _HealthMaster: Health_OR_Master[], totalCount: number, page: QueryParamsModel }) { }
}

export class HealthMasterPageToggleLoading implements Action {
    readonly type = HealthMasterActionTypes.HealthMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class HealthMasterActionToggleLoading implements Action {
    readonly type = HealthMasterActionTypes.HealthMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllInsurerRequested implements Action {
    readonly type = HealthMasterActionTypes.AllInsurerRequested;
}

export class AllInsurerLoaded implements Action {
    readonly type = HealthMasterActionTypes.AllInsurerLoaded;
    constructor(public payload: { _HealthMaster: Health_OR_Master[] }) { }
}

export class AllPolicy_TypeRequested implements Action {
    readonly type = HealthMasterActionTypes.AllPolicy_TypeRequested;
}

export class AllPolicy_TypeLoaded implements Action {
    readonly type = HealthMasterActionTypes.AllPolicy_TypeLoaded;
    constructor(public payload: { _HealthMaster: Health_OR_Master[] }) { }
}

export type HealthMasterActions = AllInsurerRequested
| AllInsurerLoaded
| AllPolicy_TypeRequested
| AllPolicy_TypeLoaded
| HealthMasterPageRequested
| HealthMasterPageLoaded
| HealthMasterPageToggleLoading
| HealthMasterActionToggleLoading;
